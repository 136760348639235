import React, { useEffect, useState, useRef } from "react";
import ClickAwayListenerHelper from "../../Utils/ClickAwayListenerHelper";
import * as BsIcons from "react-icons/bs";
import InvoiceSummaryTable from "./InvoiceSummaryTable";
import AdvInvoiceSummaryTable from "./AdvInvoiceSummaryTable";
import DetailedInvSummTable from "./DetailedInvSummTable";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import SwipeableViews from "react-swipeable-views";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Typography,
  Checkbox,
  ListItemText,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import PropTypes from "prop-types";
import CustomerInformation from "./FolioUpdateOptions/CustomerInformation";
import BookingInformation from "./FolioUpdateOptions/BookingInformation";
import UpgradeRoom from "./FolioUpdateOptions/UpgradeRoom";
import * as AiIcons from "react-icons/ai";
import "react-responsive-modal/styles.css";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import LuggageIcon from "@mui/icons-material/Luggage";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api/index";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Currencies from "../../Utils/Currencies.json";
import { useHistory } from "react-router-dom";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import InvoiceDoc from "./InvoiceDoc";
import InvoiceDocWOPos from "./InvoiceDocWOPos";
import DetailedInvDoc from "./DetailedInvDoc";
import PrintResCard from "./PrintResCard";
import PrintEmptyResCard from "./PrintEmptyResCard";
import FolioLogTable from "./FolioLogTable";
import OutlinedInput from "@mui/material/OutlinedInput";
import AdvInvoiceDoc from "./AdvInvoiceDoc";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import StatusMap from "../../Utils/StatusMap.json";
import * as IoIcons from "react-icons/io";
import AddIcon from "@mui/icons-material/Add";
import {
  getAllGuestUsers,
  getSelectedGuest,
  getIshaGuestConfig,
  getAllPromocodesPriceList,
  getAllPromocodes,
  checkPromoCodeQuickBook,
} from "../Promocodes/apis/PromocodeApi";
import GuestInformation from "./FolioUpdateOptions/GuestInformation";
import EditAdultChildrenInfo from "./FolioUpdateOptions/EditAdultChildrenInfo";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "../../Utils/DefaultRange";
import { getUserId } from "../BookingDashboard/apis/BrandingModalApi";
import DirectBillFolioDoc from "./DirectBillFolioDoc";
// import Checkbox from "@mui/material/Checkbox";
import MockInvoiceTable from "./MockInvoiceTable";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ModifyCheckin from "./FolioUpdateOptions/ModifyCheckin";
import ModifyCheckout from "./FolioUpdateOptions/ModifyCheckout";
import SplitRoomsss from "./FolioUpdateOptions/SplitRoomsss";
import LockBookings from "./FolioUpdateOptions/LockBookings";
import UnlockRoom from "./FolioUpdateOptions/UnlockRoom";
import AssignRoom from "./FolioUpdateOptions/AssignRoom";
import UnAssignRoom from "./FolioUpdateOptions/UnAssignRoom";
import {
  addNewBookingNote,
  addNewCustomerNote,
  deleteTheBookingNotes,
  deleteTheCustomerNotes,
  getAllBookingNotes,
  getAllCustomerNotes,
  removeNewDoNotRentStatus,
  upadateNotes,
} from "../CalendarPage/api/BookingAndCustomerNotesApi";
import {
  baseUrl,
  ENV,
  springBaseUrl,
} from "../CalendarPage/constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import { SecondaryButton } from "../../Utils/ButtonHelper";
import EditBooking from "./FolioUpdateOptions/EditBooking";
import AddRoomToBooking from "./FolioUpdateOptions/AddRoomToBooking";
import { getHotelAddons } from "../HotelAddons/HotelAddonsAPI";
import "./CurrencyModal.css";
import {
  addSegmentAndSubSegment,
  updateComplimentaryBooking,
} from "../CalendarPage/api/ReservationApi";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import CopyReservation from "./FolioUpdateOptions/CopyReservation";
import { getAllCurrentConfig } from "./FolioUpdateOptions/EditAdultChildrenApi";
import CustomInvoiceTables from "./CustomInvoice/CustomInvoiceTables";
import CustomInvoiceDoc from "./CustomInvoice/CustomInvoiceDoc";
import SearchModal from "../CalendarPage/Views/SearchModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import TaxInvoicePage from "./CustomInvoice/TaxInvoice/TaxInvoicePage";
import Invoice from "./CustomInvoice/TaxInvoice/Invoice";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import PrintVehicleResCard from "./PrintVehicleResCard";
import {
  checkIshaAccess,
  checkIshaUSAccess,
} from "../CalendarPage/constants/Calendar-constants";
import PrintEmptyVehicleResCard from "./PrintEmptyVehicleResCard";
import PrintInvoiceLoader from "./FolioUpdateOptions/PrintInvoiceLoader";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TransferReservation from "./FolioUpdateOptions/TransferReservation/TransferReservation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Style for room breakup data
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  maxHeight: 750,
  height: "fit-content",
};
// styling for the IOS Switch

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  borderRadius: 20,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#2ECA45"
            : "linear-gradient(-150deg, #1ab394, #1a91ae)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// tabs in the right drawer
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function InvoiceWrapper({
  onClickCustEditBtn,
  onClickAddServiceBtn,
  bookingId,
  data,
  invoiceData,
  updateInvoice,
  currency,
  loggedUserEmail,
  summaryData,
  updateSummaryData,
  customPgData,
  otaBookingInfoData,
  updateOtaBookingInfo,
  updateHotelPgData,
  handleTrueWrapperMockLoader,
  handleFalseWrapperMockLoader,
  handleTrueSummaryMockLoader,
  handleFalseSummaryMockLoader,
  customFolioConfig,
  hotelLogo,
  fetchBookingData,
  isGrid,
  handleBase64Img,
  updateCurrency,
  hotelType,
  roomBreakupData,
  updateRoomBreakupData,
  roomIdMapping,
  groupHotelEmail,
  customFolios,
  handleGetAllCustomFolios,
  checked,
  handleFolioType,
  customFolioSummary,
  getUpdatedData,
  isMasterUser,
  igst,
  dbigst,
  utgst,
  isNightAuditOn,
  selectedLanguage,
  folioConfigs,
  discountTaxExemptInvoiceData,
  discountTaxExemptSummaryData,
  discountTaxExemptAdvancedInvoiceData,
  updateDiscountTaxExemptAdvancedInvoiceData,
  updateDiscountTaxExemptInvoiceData,
  updateDiscountTaxExemptSummaryData,
  taxExemptAccessControl,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  //HotelInfo for GST Number-----------------
  const [hotelTax, setHotelTax] = useState();
  const getPropertyDetails = () => {
    handleSpringDataRequest("core/api/v1/pc/get-property-detail").then((res) =>
      setHotelTax(res?.taxIdNumber)
    );
  };
  useEffect(() => {
    getPropertyDetails();
  }, []);

  //-----------IGST--------------
  const customerState =
    invoiceData?.invoice?.customer_address?.customer_address_state.toUpperCase();
  const propertyState = data?.accessControl?.hotel?.hotel_state.toUpperCase();

  //-------------new Invoice Folio------------------
  const [folioConfig, setfolioConfig] = useState({});
  const gettingFolioConfig = () => {
    handleSpringDataRequest(`core/api/v1/folio/get-folio-config`)
      .then((data) => setfolioConfig(data))
      .catch((err) => setfolioConfig({}));
  };
  useEffect(() => {
    gettingFolioConfig();
  }, []);

  //Amplitude check on View Folio page
  const amplitudeEventProperties = {
    hotel_id: data?.hotelId,
    environment: ENV,
    email: data?.accessControl?.email,
    role: data?.accessControl?.ADMINRIGHTS,
  };

  const [hotelemail, sethotelemail] = useState(
    data?.accessControl?.hotel?.hotel_email
  );
  /* useEffect(() => {
    amplitude
      .getInstance()
      .logEvent("FOLIO PAGE LOADED", amplitudeEventProperties);
  }, []); */

  // getting the hotel id based on the props

  const envType = process.env.REACT_APP_ENV;

  const hotelId = data.hotelId;
  const hotelName = data.hotelName;
  const hotelCurr = data?.hotelCurrency;

  const [userEmail, setUserEmail] = useState(null);
  const [copyPromocodeData, setcopyPromocodeData] = useState([]);

  const [showCompanyModal, setshowCompanyModal] = useState(false);
  const [searchableValue, setsearchableValue] = useState("");
  const [allCompanyDetails, setallCompanyDetails] = useState([]);
  const [subUser, setsubUser] = useState(data?.accessControl?.ADMINRIGHTS);

  const getCompanyDetails = () => {
    handleSpringDataRequest(`core/api/v1/guest-company-details/get-all`)
      .then((res) => setallCompanyDetails(res))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const hideShowCompanyModal = () => {
    setshowCompanyModal(false);
  };
  useEffect(() => {
    setUserEmail(invoiceData?.invoice.customer_email);
  }, [invoiceData]);

  const [doNotRent, setDoNotRent] = useState(null);

  const getDoNotRentStatus = (email) => {
    handleSpringDataRequest(`core/api/v1/do-not-rent/get-all?emailId=${email}`)
      .then((res) => {
        setDoNotRent(res[0]);
      })
      .catch((err) => {
        console.log("The err is : ", err);
      });
  };

  const [compBooking, setCompBooking] = useState(null);
  const [requestName, setRequestName] = useState("");
  const [requestContact, setRequestContact] = useState("");
  const [requestDept, setRequestDept] = useState("");
  const [requestApprover, setRequestApprover] = useState("");
  const handleGetComplimentaryBooking = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/get-complimentary-info/?bookingId=${bookingId}`
    )
      .then((res) => {
        if (res != null) {
          setCompBooking(res);
        }
      })
      .catch((err) =>
        toast.error(`${t("error in getting complimentary information")}`)
      );
  };

  useEffect(() => {
    if (compBooking != null) {
      setRequestName(compBooking?.compRequestName);
      setRequestContact(compBooking?.compRequestContact);
      setRequestDept(compBooking?.compRequestDepartment);
      setRequestApprover(compBooking?.compRequestApprove);
    }
  }, [compBooking]);
  useEffect(() => {
    userEmail && getDoNotRentStatus(userEmail);
  }, [userEmail]);

  useEffect(() => {
    bookingId && handleGetComplimentaryBooking();
  }, [bookingId]);
  // getting the hotel currency based on the props
  // const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // tabs in the right drawer
  const [value, setValue] = useState(0);

  // console.log("data", data);

  const [openBookingNotes, setOpenBookingNotes] = useState(null);
  const [openCustomerNotes, setOpenCustomerNotes] = useState(null);
  const [UserIdImages, setUserIdImages] = useState();
  const [selectedUserEmail, setselectedUserEmail] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangetoCOllectInfo = (event) => {
    const {
      target: { value },
    } = event;
    if (value[0] === "ALL") {
      const data = allGuestUsers?.map((e) => e.email);
      setselectedUserEmail(data);
    } else {
      setselectedUserEmail(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    // // storing the api methods
    // const [invoiceData, setInvoiceData] = useState(null);
  };

  const [mostMajorSource, setMostMajorSource] = useState(null);
  const [mostSecondarySource, setMostSecondarySource] = useState(null);

  const [majorSources, setMajorSources] = useState(null);
  const [initsegment, setInitSegment] = useState(invoiceData?.invoice?.segment);
  const [initsubSegment, setInitSubSegment] = useState(
    invoiceData?.invoice?.booking_source_displayname
  );

  const [segment, setSegment] = useState(invoiceData?.invoice?.segment);
  const [subSegment, setSubSegment] = useState(
    invoiceData?.invoice?.booking_source_displayname
  );
  const [subSegmentArr, setSubSegmentArr] = useState(null);
  // right drawer
  const [state, setState] = useState({
    right: false,
  });

  // guest right drawer
  const [gueststate, setguestState] = useState({
    right: false,
  });

  // adult children right drawer
  const [adultChidrenState, setadultChidrenState] = useState({
    right: false,
  });

  // checkIn right drawer
  const [checkInState, setcheckInState] = useState({
    right: false,
  });

  // copy reservation right drawer
  const [copyReservationDrawerState, setcopyReservationDrawerState] = useState({
    right: false,
  });

  // checkOut right drawer
  const [checkOutState, setcheckOutState] = useState({
    right: false,
  });

  // upgrade room right drawer
  const [upgradeRoomState, setupgradeRoomState] = useState({
    right: false,
  });

  // split room right drawer
  const [splitRoomState, setSplitRoomState] = useState({
    right: false,
  });

  // split room right drawer
  const [AddRoomToBookingState, setAddRoomToBookingState] = useState({
    right: false,
  });

  // Lock booking  right drawer
  const [lockBookingState, setLockBookingState] = useState({
    right: false,
  });

  // Edit booking  right drawer
  const [editBookingState, setEditBookingState] = useState({
    right: false,
  });
  // unLock booking  right drawer
  const [unlockBookingState, setunLockBookingState] = useState({
    right: false,
  });

  // assign room right drawer
  const [assignRoomState, setassignRoomState] = useState({
    right: false,
  });

  // unassign room  right drawer
  const [unassignRoomState, setunassignRoomState] = useState({
    right: false,
  });

  //Setting resId for any mapping
  const [selectedResId, setSelectedResId] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const handleRoomIdChange = (roomId) => {
    setSelectedRoomId(roomId);
    roomIdMapping.map((resData, index) => {
      if (roomId == resData?.roomId) {
        setSelectedResId(resData?.resId);
      }
    });
  };

  useEffect(() => {
    console.log("");
  }, [selectedResId]);
  useEffect(() => {
    if (roomIdMapping?.length > 0) {
      // setSelectedRoomId(roomIdMapping[0]?.roomId);
      // setSelectedResId(roomIdMapping[0]?.resId);
    }
  }, [roomIdMapping]);

  const [isEditable, setisEditable] = useState(true);

  const [hotelConvertedCurrency, sethotelConvertedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );

  const [currencyRate, setcurrencyRate] = useState(1);
  const [selectedCurrency, setselectedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );

  useEffect(() => {
    const currency = sessionStorage.getItem("convertedHotelCurrency");
    if (
      currency !== null &&
      currency !== undefined &&
      currency !== "" &&
      hotelCurrency !== null &&
      hotelCurrency !== undefined &&
      hotelCurrency !== ""
    ) {
      if (currency === hotelCurrency) {
        setisEditable(true);
      } else {
        setisEditable(false);
      }
    }
  }, [sessionStorage.getItem("convertedHotelCurrency"), hotelCurrency]);

  const [currencyLoader, setcurrencyLoader] = useState(false);
  const getConvertedvalue = async () => {
    setcurrencyLoader(true);
    const data = await axios.get(
      `${
        process.env.REACT_APP_SPRING_API_URL
      }web-api/currency-convert-value?from=${hotelCurrency}&to=${
        sessionStorage.getItem("convertedHotelCurrency") === null
          ? selectedCurrency
          : sessionStorage.getItem("convertedHotelCurrency")
      }`
    );
    sessionStorage.setItem("currencyRate", data.data);
    setcurrencyRate(data.data);
    setcurrencyLoader(false);
  };

  // const Card = ({ id, name, description, symbol }) => {
  //   return (
  //     <div
  //       className="cardess"
  //       onClick={() => {
  //         sessionStorage.setItem("defaultRate", 0);
  //         sethotelConvertedCurrency(description);
  //         sessionStorage.setItem("convertedHotelCurrencySymbol", symbol);
  //         sessionStorage.setItem("convertedHotelCurrency", description);
  //         handleOpenCurrencyModalDefault();
  //         // updateCurrency();
  //         // handleCloseCurrencyModal();
  //       }}
  //     >
  //       <div className="card-bodyss">
  //         <h2>{name}</h2>
  //         <p>{description}</p>
  //       </div>
  //     </div>
  //   );
  // };
  const [hotelCurrency, sethotelCurrency] = useState("");

  useEffect(() => {
    sethotelCurrency(localStorage.getItem("hotelCurrency"));
  }, [localStorage.getItem("hotelCurrency")]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedRooms, setselectedRooms] = useState([]);
  const handleRate = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedRooms(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  /* Handling information drawer */

  const toggleDrawer = (anchor, open) => (event) => {
    setValue(0);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const [selectedUserResId, setselectedUserResId] = useState("");
  const [selectedGuestStatus, setselectedGuestStatus] = useState("");
  const toggleGuestDrawer =
    (anchor, open, email, status, selectedResId) => async (event) => {
      setselectedGuestStatus(status);
      setselectedUserResId(selectedResId);
      if (email === "test") {
        setselectedGuestInfo(null);
        setSelectedCustomerNotes(null);
      } else if (email !== undefined) {
        await getAllGuestNotes(email);
        await getAllSelectedUsersData(email);
        if (
          JSON.parse(
            data?.accessControl?.user_feature_map?.IshaExtraGuestDetails
          ).read === "YES" &&
          (checkIshaAccess(data?.emailId) || checkIshaUSAccess(data?.emailId))
        ) {
          await getIshaGuestConfigData(email);
        }
      }
      await getCurrentResConfig();
      //to render only when guest drawer opens
      // if (open) {
      //   const dataUser = await getUserId(email);
      //   setUserIdImages(dataUser);
      // }
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setguestState({ ...gueststate, [anchor]: open });
    };

  //TOGGLE EDIT ADULT CHILDREN
  const toggleAdultChildrenDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setadultChidrenState({ ...adultChidrenState, [anchor]: open });
  };

  //TOGGLE MODIFY CHECKIN
  const toggleCheckInDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setcheckInState({ ...checkInState, [anchor]: open });
  };

  //TOGGLE COpy Reservation Drawer
  const toggleCopyReservationDrawerState = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setcopyReservationDrawerState({
      ...copyReservationDrawerState,
      [anchor]: open,
    });
  };

  //TOGGLE MODIFY CHECKIN
  const toggleCheckOutDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setcheckOutState({ ...checkOutState, [anchor]: open });
  };

  //TOGGLE Upgrade room drawer
  const toggleUpgradeRoomDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setupgradeRoomState({ ...upgradeRoomState, [anchor]: open });
  };

  //TOGGLE split room drawer
  const toggleSplitRoomDrawer = (anchor, open) => async (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }
    setSplitRoomState({ ...splitRoomState, [anchor]: open });
  };

  //TOGGLE split room drawer
  const toggleAddRoomToBookingDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setAddRoomToBookingState({ ...AddRoomToBookingState, [anchor]: open });
  };

  //TOGGLE lock booking drawer
  const toggleLockBookingDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLockBookingState({ ...lockBookingState, [anchor]: open });
  };

  //TOGGLE edit booking drawer
  const toggleEditBookingDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setEditBookingState({ ...editBookingState, [anchor]: open });
  };

  //TOGGLE unlock booking drawer
  const toggleUnLockBookingDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setunLockBookingState({ ...unlockBookingState, [anchor]: open });
  };

  //TOGGLE assign room drawer
  const toggleAssignRoomDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setassignRoomState({ ...assignRoomState, [anchor]: open });
  };

  //TOGGLE unassign room drawer
  const toggleunAssignRoomDrawer = (anchor, open) => async (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setunassignRoomState({ ...unassignRoomState, [anchor]: open });
  };

  /* NOTES */

  /* Booking notes */

  // getting the booking notes data
  const [bookingNotes, setBookingNotes] = useState([]);
  // function getBookingNotes() {
  //   djangoHandleDataRequests(`invoice/getBookingNotes/?bookingID=${bookingId}`)
  //     .then((res) => setBookingNotes(res))
  //     .catch((err) => toast.error("Error fetching the booking notes."));
  // }

  //invoice Summary
  // const [invoiceSummary, setinvoiceSummary] = useState();
  // function getInvoiceSummaryData() {
  //   djangoHandleDataRequests(
  //     `invoice/getInvoiceSummaryData/?bookingID=${bookingId}&use_cache=${"false"}`
  //   )
  //     .then((res) => setinvoiceSummary(res))
  //     .catch((err) => toast.error("Error fetching the invoice Summary."));
  // }

  // handle adding booking notes
  const [addBookingNotes, setAddBookingNotes] = useState("");
  const [bookingNotesLoader, setBookingNotesLoader] = useState(false);
  const handleBookingNotes = (event) => {
    let val = event.target.value;
    if (val) {
      setAddBookingNotes(val);
    }
  };

  const handleAddBookingNotes = () => {
    setBookingNotesLoader(true);
    djangoHandleDataMutationRequest("POST", "invoice/recordBookingNote/", {
      hotelId: hotelId,
      bookingID: bookingId,
      note: addBookingNotes,
    })
      // .then((res) => toast.success("Note added successfully."))
      .then((res) => setAddBookingNotes(""))
      .then((res) => setBookingNotesLoader(false))
      // .then((res) => getBookingNotes())
      .catch((err) => toast.error(err));
  };

  // delete the booking note
  const [deleteBookingNoteLoader, setDeleteBookingNoteLoader] = useState(false);
  const handleDeleteBookingNote = (id) => {
    setDeleteBookingNoteLoader(true);
    djangoHandleDataRequests(
      `invoice/deleteBookingNote/?bookingID=${bookingId}&id=${id}`
    )
      // .then((res) => toast.success("Note deleted"))
      .then((res) => setDeleteBookingNoteLoader(false))
      // .then((res) => getBookingNotes())
      .catch((err) => toast.error(`${t("Error deleting the note.")}`));
  };

  /* Customer notes */

  // getting the customer notes data
  // const [customerNotes, setCustomerNotes] = useState([]);
  // function getCustomerNotes() {
  //   djangoHandleDataRequests(
  //     `invoice/getCustomerNotes/?cust_email=${invoiceData.invoice.customer_email}`
  //   )
  //     .then((res) => setCustomerNotes(res))
  //     .catch((err) => toast.error("Error fetching the customer notes."));
  // }

  // useEffect(() => {
  //   invoiceData && getCustomerNotes();
  // }, []);

  // handle adding customer notes
  const [addCustomerNotes, setAddCustomerNotes] = useState("");
  const [customerNotesLoader, setCustomerNotesLoader] = useState(false);
  const handleCustomerNotes = (event) => {
    let val = event.target.value;
    if (val) {
      setAddCustomerNotes(val);
    }
  };

  const handleAddCustomerNotes = () => {
    setCustomerNotesLoader(true);
    djangoHandleDataMutationRequest("POST", "invoice/recordCustomerNote/", {
      hotelId: hotelId,
      cust_email: invoiceData.invoice.customer_email,
      note: addCustomerNotes,
    })
      // .then((res) => toast.success("Note added successfully."))
      .then((res) => setAddCustomerNotes(""))
      .then((res) => setCustomerNotesLoader(false))
      // .then((res) => getCustomerNotes())
      .catch((err) => toast.error(err));
  };

  // delete the customer note
  const [deleteCustomerNoteLoader, setDeleteCustomerNoteLoader] =
    useState(false);
  const handleDeleteCustomerNote = (id) => {
    setDeleteCustomerNoteLoader(true);
    djangoHandleDataRequests(
      `invoice/deleteCustomerNote/?cust_email=${invoiceData?.invoice?.customer_email}&id=${id}`
    )
      // .then((res) => toast.success("Note deleted"))
      .then((res) => setDeleteCustomerNoteLoader(false))
      // .then((res) => getCustomerNotes())
      .catch((err) => toast.error(`${t("Error deleting the note.")}`));
  };

  // handle invoice header
  const [invoiceHeader, setInvoiceHeader] = useState("Folio");
  const handleInvoiceHeader = () => {
    if (
      customFolioConfig?.booking_invoice_header &&
      !customFolioConfig?.booking_invoice_header
        .toLowerCase()
        .includes("null", "n/a")
    ) {
      return setInvoiceHeader(customFolioConfig?.booking_invoice_header);
    } else {
      return setInvoiceHeader("Folio");
    }
  };

  useEffect(() => {
    handleInvoiceHeader();
  }, [customFolioConfig]);

  //Send link to collect guest info

  function handleSendLinkToCollectGuestInfo() {
    setMagicLinkLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/send-guest-info-updation-email",
      {
        bookingId: bookingId,
        emails: [],
      }
    )
      .then((res) => toast.success(`${t("Link sent successfully.")}`))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseCollectGuestInfo())
      .catch((err) => {
        toast.error(err);
        handleCloseCollectGuestInfo();
      })
      .catch((err) => setMagicLinkLoading(false));
  }

  // Check if the data is not null or includes n/a
  const checkInvoiceData = (data) => {
    if (data === null) {
      return "";
    } else if (String(data).toLowerCase().startsWith("null")) {
      return "";
    } else if (String(data).toLowerCase().startsWith("n/a")) {
      return "";
    } else {
      return String(data);
    }
  };

  // Getting the custom booking Id (prefic, suffix and the seq no.)
  const [customId, setCustomId] = useState(bookingId);
  const handleCustomBookingId = () => {
    return setCustomId(
      String(checkInvoiceData(customFolioConfig.cust_booking_invoice_prefix)) +
        String(checkInvoiceData(customFolioConfig?.cust_booking_seq_start)) +
        String(checkInvoiceData(customFolioConfig?.cust_booking_invoice_suffix))
    );
  };

  useEffect(() => {
    handleCustomBookingId();
  }, [customFolioConfig]);

  const list = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">
                  {invoiceHeader}#{bookingId}
                </div>
                {/* <div className="fId">Booking information</div> */}
                <div className="bookingSrc">{t("Customer information")}</div>
                {/* <div className="bookingSrc">
                  {invoiceData?.invoice.booking_source_displayname}
                </div> */}
              </div>
              {/* <div className="folioActionWrapper">
                <ClickAwayListenerHelper
                  clickAwayButtonName={
                    <Tooltip title="Folio actions">
                      <IconButton>
                        <BsIcons.BsThreeDotsVertical
                          size="15px"
                          color="black"
                        />
                      </IconButton>
                    </Tooltip>
                  }
                  btnClassName="folioActionBtns"
                  content={
                    <div className="folioActions">
                      {invoiceData.invoice.status === "CONFIRMED" && (
                        <div
                          className="item"
                          onClick={() => handleOpenHoldBooking()}
                        >
                          Hold booking
                        </div>
                      )}
                      {invoiceData.invoice.status === "CONFIRMED" && (
                        <div
                          className="item"
                          onClick={() => handleOpenCancelBooking()}
                        >
                          Cancel booking
                        </div>
                      )}
                      {invoiceData.invoice.status === "ADMIN_CONFIRMED" && (
                        <div
                          className="item"
                          onClick={() => handleOpenCancelBooking()}
                        >
                          Cancel booking
                        </div>
                      )}
                      {invoiceData.invoice.status === "ON_HOLD" && (
                        <div
                          className="item"
                          onClick={() => handleOpenCancelBooking()}
                        >
                          Cancel booking
                        </div>
                      )}
                      {invoiceData.invoice.status === "NO_SHOW" && (
                        <div
                          className="item"
                          onClick={() => handleOpenCancelBooking()}
                        >
                          Cancel booking
                        </div>
                      )}
                      {invoiceData.invoice.status === "CONFIRMED" && (
                        <div
                          className="item"
                          onClick={() => handleOpenNoShow()}
                        >
                          Set to no show
                        </div>
                      )}
                      <div
                        className="item"
                        onClick={toggleLockBookingDrawer("right", true)}
                      >
                        Lock booking
                      </div>
                      {hotelType !== "ISHA" && (
                        <div
                          className="item"
                          onClick={toggleEditBookingDrawer("right", true)}
                        >
                          Edit reservation
                        </div>
                      )}

                      <div
                        className="item"
                        onClick={toggleUnLockBookingDrawer("right", true)}
                      >
                        Unlock booking
                      </div>
                      {invoiceData?.invoice?.room_ids_list[0] !==
                        "UNASSIGNED" && (
                        <div
                          className="item"
                          onClick={toggleunAssignRoomDrawer("right", true)}
                        >
                          Unassign room
                        </div>
                      )}
                      {hotelType !== "ISHA" && (
                        <div
                          className="item"
                          onClick={toggleCheckInDrawer("right", true)}
                        >
                          Modify checkin
                        </div>
                      )}
                      <div
                        className="item"
                        onClick={toggleCheckOutDrawer("right", true)}
                      >
                        Modify checkout
                      </div>
                      {hotelType !== "ISHA" && (
                        <div
                          className="item"
                          onClick={toggleSplitRoomDrawer("right", true)}
                        >
                          Split Room
                        </div>
                      )}
                      <div
                        className="item"
                        onClick={toggleUpgradeRoomDrawer("right", true)}
                      >
                        Move Room
                      </div>
                      {reservationIds &&
                        Object.keys(reservationIds).length > 0 &&
                        hotelType !== "ISHA" && (
                          <div
                            className="item"
                            onClick={toggleAddRoomToBookingDrawer(
                              "right",
                              true
                            )}
                          >
                            Add new room to group booking
                          </div>
                        )}

                      {reservationIds &&
                        Object.keys(reservationIds).length > 1 && (
                          <div
                            className="item"
                            onClick={() => handleOPenDeleteGroupRoomBooking()}
                          >
                            Cancel room from group booking
                          </div>
                        )}

                      {invoiceData?.invoice?.room_ids_list[0] ===
                        "UNASSIGNED" &&
                        invoiceData?.invoice?.room_ids_list?.length === 1 && (
                          <div
                            className="item"
                            onClick={toggleAssignRoomDrawer("right", true)}
                          >
                            Assign Room
                          </div>
                        )}

                      {invoiceData.invoice.status === "CHECKED_OUT" &&
                        hotelType !== "ISHA" && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                      {invoiceData.invoice.status === "CHECKED_OUT" &&
                        hotelType === "ISHA" &&
                        invoiceData?.invoice?.eReceipt_map !== "NA" &&
                        invoiceData?.invoice?.eReceipt_map !== undefined &&
                        invoiceData?.invoice?.eReceipt_map !== null &&
                        Object.entries(invoiceData?.invoice?.eReceipt_map)
                          .length > 0 && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                      {invoiceData.invoice.status === "CANCELLED" &&
                        hotelType !== "ISHA" && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                      {invoiceData.invoice.status === "CANCELLED" &&
                        hotelType === "ISHA" &&
                        invoiceData?.invoice?.eReceipt_map !== "NA" &&
                        invoiceData?.invoice?.eReceipt_map !== undefined &&
                        invoiceData?.invoice?.eReceipt_map !== null &&
                        Object.entries(invoiceData?.invoice?.eReceipt_map)
                          .length > 0 && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                      {invoiceData.invoice.status === "ADMIN_CONFIRMED" &&
                        hotelType !== "ISHA" && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                      {invoiceData.invoice.status === "ADMIN_CONFIRMED" &&
                        hotelType === "ISHA" &&
                        invoiceData?.invoice?.eReceipt_map !== "NA" &&
                        invoiceData?.invoice?.eReceipt_map !== undefined &&
                        invoiceData?.invoice?.eReceipt_map !== null &&
                        Object.entries(invoiceData?.invoice?.eReceipt_map)
                          .length > 0 && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                      {invoiceData.invoice.status === "NO_SHOW" &&
                        hotelType !== "ISHA" && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                      {invoiceData.invoice.status === "NO_SHOW" &&
                        hotelType === "ISHA" &&
                        invoiceData?.invoice?.eReceipt_map !== "NA" &&
                        invoiceData?.invoice?.eReceipt_map !== undefined &&
                        invoiceData?.invoice?.eReceipt_map !== null &&
                        Object.entries(invoiceData?.invoice?.eReceipt_map)
                          .length > 0 && (
                          <div
                            className="item"
                            onClick={() => handleOpenRollbackBooking()}
                          >
                            Rollback booking
                          </div>
                        )}
                    </div>
                  }
                  left="22rem"
                />
              </div> */}
              <div
                className="closeToggleDrawer"
                onClick={toggleDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}

          {/* <Divider /> */}
        </div>
        <Divider />
        <div className="folioTabs">
          {/* <AppBar position="static" className="appBar">
            <Tabs
              value={value}
              onChange={handleChange}
              className="invoiceWrapperTabs"
              indicatorColor="secondary"
              //   textColor="primary"
              //   variant="fullWidth"
              //   aria-label="full width tabs example"
            >
              <Tab
                label="Booking information"
                {...a11yProps(0)}
                className="invoiceTab"
              />
              <Tab
                label="Customer information"
                {...a11yProps(1)}
                className="invoiceTab"
              />

              {/* <Tab
                label="Modify dates"
                {...a11yProps(2)}
                className="invoiceTab"
              /> */}
          {/* <Tab
                label="Move/Upgrade room"
                {...a11yProps(3)}
                className="invoiceTab"
              /> */}
          {/* <Tab
                label="Split room"
                {...a11yProps(4)}
                className="invoiceTab"
              />
            </Tabs>
          </AppBar> */}
          {invoiceData && (
            // <SwipeableViews
            //   // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            //   index={value}
            //   onChangeIndex={handleChangeIndex}
            // >
            // <TabPanel value={value} index={0}>
            //   <BookingInformation
            //     hotelId={hotelId}
            //     bookingId={bookingId}
            //     currency={currency}
            //     onCancelBookingBtn={toggleDrawer(anchor, false)}
            //     data={invoiceData}
            //     invoiceSummary={summaryData}
            //     hotelType={hotelType}
            //   />
            // </TabPanel>
            <TabPanel value={value} index={0} className="tabsContent">
              <CustomerInformation
                hotelId={hotelId}
                bookingId={bookingId}
                onCancelClick={toggleDrawer(anchor, false)}
                data={invoiceData.invoice}
                accessControl={data?.accessControl}
                updateData={() => updateInvoice()}
                reservationId={reservationIds}
                doNotRent={doNotRent}
                getDoNotRentStatus={getDoNotRentStatus}
                hotelType={hotelType}
                selectedLanguage={selectedLanguage}
              />
            </TabPanel>

            // {/* <TabPanel value={value} index={2}>
            //   <ModifyDates
            //   onCancel={toggleDrawer(anchor, false)}
            //   hotelId={hotelId}
            //   bookingId={bookingId}
            //   invoiceData={invoiceData}
            //   updateData={() => updateInvoice()}
            //   />
            // </TabPanel> */}
            // <TabPanel value={value} index={3} className="tabsContent">
            //   <UpgradeRoom
            //     onCancel={toggleDrawer(anchor, false)}
            //     hotelId={hotelId}
            //     bookingId={bookingId}
            //     invoiceData={invoiceData}
            //   />
            // </TabPanel>
            // {/* <TabPanel value={value} index={4} className="tabsContent">
            //   <SplitRoom
            //     onCancel={toggleDrawer(anchor, false)}
            //     bookingId={bookingId}
            //   />
            // </TabPanel> */}
            // </SwipeableViews>
          )}
        </div>
      </div>
    </Box>
  );

  const guestList = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">
                  {invoiceHeader}#{bookingId}
                </div>
                <div className="bookingSrc">
                  {/* {invoiceData?.invoice.booking_source_displayname} */}
                  {t("Guest information")}
                  {selectedCustomerNotes?.length > 0 &&
                    selectedCustomerNotes[0]?.do_not_rent === "YES" && (
                      <>
                        <div
                          className="badge badge-danger ml-4"
                          style={{ fontSize: "11px" }}
                        >
                          {t("Do-Not Rent")}
                        </div>
                        {selectedCustomerNotes[0]?.severity === "LOW" && (
                          <div
                            className="badge badge-info ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("LOW")}
                          </div>
                        )}
                        {selectedCustomerNotes[0]?.severity === "MEDIUM" && (
                          <div
                            className="badge badge-warning ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("MEDIUM")}
                          </div>
                        )}
                        {selectedCustomerNotes[0]?.severity === "HIGH" && (
                          <div
                            className="badge badge-dark ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("HIGH")}
                          </div>
                        )}
                        {selectedCustomerNotes[0]?.severity === "BLACKLIST" && (
                          <div
                            className="badge badge-danger ml-2"
                            style={{ fontSize: "11px" }}
                          >
                            {t("BLACKLIST")}
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleGuestDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}

          {/* <Divider /> */}
        </div>
        <Divider />
        <div className="folioTabs">
          <TabPanel value={value} index={0} className="tabsContent">
            <GuestInformation
              hotelId={hotelId}
              bookingId={bookingId}
              onCancelClick={toggleGuestDrawer(anchor, false)}
              updateData={() => {
                updateInvoice();
                updateSummaryData();
              }}
              roomIdList={invoiceData?.invoice?.room_ids_list}
              datas={selectedGuestInfo}
              customerNotes={selectedCustomerNotes}
              getCustomerNotes={getAllGuestNotes}
              guestDataList={getAllData}
              ishaGuestData={selectedIshaConfig}
              UserImagesData={UserIdImages}
              emailId={invoiceData.invoice.customer_email}
              reservationIds={reservationIds}
              reservationInfoData={
                invoiceData?.invoice?.reservation_room_info_list
              }
              allGuestsList={allGuestUsers}
              resCurrentConfig={resCurrentConfig}
              selectedGuestStatus={selectedGuestStatus}
              updateInvoice={updateInvoice}
              balanceDue={summaryData?.balance_due}
              selectedUserResId={selectedUserResId}
              invoiceData={invoiceData}
              selectedLanguage={selectedLanguage}
            />
          </TabPanel>
        </div>
      </div>
    </Box>
  );

  //edit adult and children info

  const editAdultChildren = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Change Adults/Children")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleAdultChildrenDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}

          {/* <Divider /> */}
        </div>
        <Divider />
        {/* <div className="folioTabs">
          <TabPanel value={value} index={0} className="tabsContent"> */}
        <EditAdultChildrenInfo
          onCancelClick={toggleAdultChildrenDrawer(anchor, false)}
          bookingId={bookingId}
          updatedDatas={updateInvoice}
          updateSummaryData={updateSummaryData}
          selectedLanguage={selectedLanguage}
        />
        {/* </TabPanel>
        </div> */}
      </div>
    </Box>
  );

  //edit modify checkin

  const modifyCheckin = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Modify check-in")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleCheckInDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}

          {/* <Divider /> */}
        </div>
        <Divider />
        {/* <div className="folioTabs">
            <TabPanel value={value} index={0} className="tabsContent"> */}
        <ModifyCheckin
          onCancelClick={toggleCheckInDrawer(anchor, false)}
          bookingId={bookingId}
          hotelId={hotelId}
          reservationId={reservationIds}
          invoiceData={invoiceData}
          reloadGrid={fetchBookingData}
          updateData={() => updateInvoice()}
          updateSummaryData={() => updateSummaryData()}
          isGrid={isGrid}
          selectedLanguage={selectedLanguage}
        />
        {/* </TabPanel>
          </div> */}
      </div>
    </Box>
  );

  const copyReservation = (anchor) => (
    <Box
      sx={{ width: 1200 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Copy Reservation")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleCopyReservationDrawerState("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}

          {/* <Divider /> */}
        </div>
        <Divider />
        {/* <div className="folioTabs">
            <TabPanel value={value} index={0} className="tabsContent"> */}
        <CopyReservation
          onCancelClick={toggleCopyReservationDrawerState(anchor, false)}
          bookingId={bookingId}
          hotelId={hotelId}
          reservationId={reservationIds}
          invoiceData={invoiceData}
          reloadGrid={fetchBookingData}
          updateData={() => updateInvoice()}
          updateSummaryData={() => updateSummaryData()}
          isGrid={isGrid}
          selectedLanguage={selectedLanguage}
        />
        {/* </TabPanel>
          </div> */}
      </div>
    </Box>
  );

  //edit modify checkout

  const modifyCheckout = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Modify check-out")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleCheckOutDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />
        <ModifyCheckout
          onCancelClick={toggleCheckOutDrawer(anchor, false)}
          bookingId={bookingId}
          hotelId={hotelId}
          reservationId={reservationIds}
          invoiceData={invoiceData}
          reloadGrid={fetchBookingData}
          updateData={() => updateInvoice()}
          isGrid={isGrid}
          updateSummaryData={() => updateSummaryData()}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );

  //Move and upgrad room

  const moveAndUpgradeRoom = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Move/Upgrade Room")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleUpgradeRoomDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}

          {/* <Divider /> */}
        </div>
        <Divider />
        {/* <div className="folioTabs">
              <TabPanel value={value} index={0} className="tabsContent"> */}
        <UpgradeRoom
          onCancel={toggleUpgradeRoomDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          reservationId={reservationIds}
          invoiceData={invoiceData}
          reloadGrid={fetchBookingData}
          isGrid={isGrid}
          selectedLanguage={selectedLanguage}
        />
        {/* </TabPanel>
            </div> */}
      </div>
    </Box>
  );

  //add room to booking

  const addRoomToBooking = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Add new room to group booking")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleAddRoomToBookingDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />

        <AddRoomToBooking
          onCancel={toggleAddRoomToBookingDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          invoiceData={invoiceData}
          reservationId={reservationIds}
          reloadGrid={fetchBookingData}
          isGrid={isGrid}
          updateInvoice={updateInvoice}
          updateSummaryData={updateSummaryData}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );

  //Split  room

  const splitRoomss = (anchor) => (
    <Box
      sx={{ width: 800 }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Split Room")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleSplitRoomDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />

        <SplitRoomsss
          closeModal={toggleSplitRoomDrawer("right", false)}
          onCancel={toggleSplitRoomDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          reservationId={reservationIds}
          invoiceData={invoiceData}
          reloadGrid={fetchBookingData}
          isGrid={isGrid}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );

  //Lock booking

  const lockBooking = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Lock room")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleLockBookingDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />

        <LockBookings
          onCancel={toggleLockBookingDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          invoiceData={invoiceData}
          reservationId={reservationIds}
          reloadGrid={fetchBookingData}
          isGrid={isGrid}
          updateInvoice={updateInvoice}
          updateSummaryData={updateSummaryData}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );

  //Edit booking

  const editBooking = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Edit reservation")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleEditBookingDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />

        <EditBooking
          onCancel={toggleEditBookingDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          invoiceData={invoiceData}
          reservationId={reservationIds}
          reloadGrid={fetchBookingData}
          status={invoiceData.invoice.status}
          isGrid={isGrid}
          updateInvoice={updateInvoice}
          updateSummaryData={updateSummaryData}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );

  //unLock booking

  const unlockRoom = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Unlock room")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleUnLockBookingDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />

        <UnlockRoom
          onCancel={toggleUnLockBookingDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          invoiceData={invoiceData}
          reservationId={reservationIds}
          reloadGrid={fetchBookingData}
          isGrid={isGrid}
          updateInvoice={updateInvoice}
          updateSummaryData={updateSummaryData}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );

  //assign Room

  const assignRoom = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Assign room")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleAssignRoomDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />

        <AssignRoom
          onCancel={toggleAssignRoomDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          invoiceData={invoiceData}
          reservationId={reservationIds}
          isGrid={isGrid}
          updateInvoice={updateInvoice}
          updateSummaryData={updateSummaryData}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );

  //unAssign room

  const unAssignRoom = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      // role="presentation"
    >
      <div className="folioUpdateWrapper">
        <div className="folioInformationActionWrapper">
          {invoiceData && (
            <>
              <div className="folioInformation">
                <div className="fId">{t("Unassign room")}</div>
              </div>
              <div
                className="closeToggleDrawer"
                onClick={toggleunAssignRoomDrawer("right", false)}
              >
                <AiIcons.AiOutlineClose size="20px" color="black" />
              </div>
            </>
          )}
        </div>
        <Divider />

        <UnAssignRoom
          onCancel={toggleunAssignRoomDrawer(anchor, false)}
          hotelId={hotelId}
          bookingId={bookingId}
          invoiceData={invoiceData}
          reservationId={reservationIds}
          reloadGrid={fetchBookingData}
          isGrid={isGrid}
          updateInvoice={updateInvoice}
          updateSummaryData={updateSummaryData}
          selectedLanguage={selectedLanguage}
        />
      </div>
    </Box>
  );
  //Get selcted guest info
  const [selectedGuestInfo, setselectedGuestInfo] = useState(null);
  const [selectedIshaConfig, setSelectedIshaConfig] = useState(null);
  const [selectedCustomerNotes, setSelectedCustomerNotes] = useState(null);
  const [resCurrentConfig, setResCurrentConfig] = useState(null);

  const getAllSelectedUsersData = async (email) => {
    try {
      let lowercase_email = String(email).toLowerCase();
      const data = await getSelectedGuest(lowercase_email, bookingId);

      setselectedGuestInfo(data);
    } catch (e) {
      toast.error(e);
    }
  };

  const custom_pg_name = customPgData?.active_pg_name;

  const shift4_utg_url = customPgData?.shift4_utg_url;
  const shift4_api_terminal_id = customPgData?.shift4_api_terminal_id;
  const shift4_access_token = customPgData?.shift4_access_token;

  const getAllGuestNotes = async (email) => {
    let lowercase_email = String(email).toLowerCase();
    handleSpringDataRequest(
      `core/api/v1/do-not-rent/get-all?emailId=${lowercase_email}`
    )
      .then((res) => {
        setSelectedCustomerNotes(res);
      })
      .catch((err) => {
        console.log("The err is : ", err);
      });
  };

  const getCurrentResConfig = async () => {
    const data = await getAllCurrentConfig(bookingId);
    setResCurrentConfig(data);
  };

  const getIshaGuestConfigData = async (email) => {
    try {
      const data = await getIshaGuestConfig(email);

      setSelectedIshaConfig(data);
    } catch (e) {
      toast.error(e);
    }
  };

  // formatting the date
  const handleFormatDate = (date) => {
    let formatted_date = moment(date).format("MMM DD, YYYY hh:mm A");
    return formatted_date;
  };

  // editing the customer identification field

  const [custFieldLoader, setCustFieldLoader] = useState(false);

  const [custField, setCustField] = useState("");

  const [updatedCustField, setUpdatedCustField] = useState(custField);
  const [referenceId, setreferenceId] = useState(
    invoiceData?.invoice?.booking_reference_id
  );

  useEffect(() => {
    setUpdatedCustField(custField);
  }, [custField, bookingId]);

  useEffect(() => {
    setCustField(invoiceData?.invoice.identification);
  }, [summaryData, bookingId]);

  const handleCustField = (e) => {
    let val = e.target.value;
    setUpdatedCustField(val);
  };

  // updating the customer identification field
  const handleUpdateField = () => {
    setCustFieldLoader(true);
    djangoHandleDataMutationRequest("POST", "invoice/updateFolioAttribute/", {
      booking_id: bookingId,
      pk: "identification",
      value: updatedCustField,
    })
      .then((res) => updateInvoice())
      // .then((res) => updateSummaryData())
      .then((res) =>
        toast.success(
          `${t("Successfully updated the customer identification field.")}`
        )
      )
      .then((res) => setCustFieldLoader(false))
      .catch((error) => toast.error(error));
  };

  // updating the  Booking Reference Id
  const handleBookingReferenceId = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/update-booking-reference-id?bookingId=${bookingId}&bookingReferenceId=${referenceId}`
    )
      .then((res) => {
        toast.success(`${t("Successfully updated booking reference id")}`);
        updateInvoice();
      })
      .catch((err) =>
        toast.error(`${t("Error in updating the booking reference id")}`)
      );
  };

  // defining functions for the drawer
  const [showCustEditDrawer, setShowCustEditDrawer] = useState(false);
  const openCustEditModal = () => setShowCustEditDrawer(true);
  const onCloseCustEditDrawer = () => {
    setShowCustEditDrawer(false);
  };

  //invalidate cache

  const invalidateCache = () => {
    handleSpringDataRequest(
      `core/api/v1/folio/persist-folio-cache/?bookingId=${bookingId}&mode=ALL`
    )
      .then((res) => console.log())
      .catch((err) => console.error(err));
  };

  /* Reload Booking */
  /* Reload Booking */
  const [reloadInvoice, setReloadInvoice] = useState(false);
  function handleReloadBooking() {
    setReloadInvoice(true);
    if (checked === "-1") {
      // handleTrueSummaryMockLoader();
      // handleTrueWrapperMockLoader();
      updateInvoice();
      updateSummaryData();
      // updateHotelPgData();
      updateRoomBreakupData();
      taxExemptAccessControl && updateDiscountTaxExemptInvoiceData();
      taxExemptAccessControl && updateDiscountTaxExemptSummaryData();
      // updateOtaBookingInfo();
      invalidateCache();
      setTimeout(() => {
        toast.success(`${t("Successfully updated")}`);
        setReloadInvoice(false);
        // handleFalseSummaryMockLoader();
        // handleFalseWrapperMockLoader();
      }, 1500);
    } else if (checked === "-2") {
      getAdvancedData();
      updateSummaryData();

      taxExemptAccessControl && updateDiscountTaxExemptAdvancedInvoiceData();
      // updateHotelPgData();
      // updateOtaBookingInfo();
      setTimeout(() => {
        toast.success(`${t("Successfully updated advanced folio")}`);
        setReloadInvoice(false);
        // handleFalseSummaryMockLoader();
        // handleFalseWrapperMockLoader();
      }, 1500);
    } else if (checked === "-3") {
      getDetailedInvData();
      setTimeout(() => {
        toast.success(`${t("Successfully updated advanced itemized folio")}`);
        setReloadInvoice(false);
      }, 1500);
    } else if (!["-1", "-2", "-3"].includes(checked)) {
      const customFolioName = customFolios?.filter(
        (customFolio) => customFolio?.custom_folio_id === checked
      )[0]?.custom_folio_name;
      handleGetCustomInvoiceData(checked);
      setTimeout(() => {
        toast.success(`${customFolioName} ${t("updated successfully")}`);
        setReloadInvoice(false);
      }, 1500);
    }
  }

  /* Hold Booking */

  // hold booking confirmation modal
  const [openHoldBooking, setOpenHoldBooking] = useState(false);
  const handleOpenHoldBooking = () => setOpenHoldBooking(true);
  const handleCloseHoldBooking = () => setOpenHoldBooking(false);

  // Delete confirmation modal
  const [deleteGroupRoomBooking, setdeleteGroupRoomBooking] = useState(false);
  const handleOPenDeleteGroupRoomBooking = () =>
    setdeleteGroupRoomBooking(true);
  const handleCloseDeleteGroupRoomBooking = () =>
    setdeleteGroupRoomBooking(false);

  // hold booking loader
  const [holdBookingLoader, setHoldBookingLoader] = useState(false);

  // handling hold booking
  const handleHoldBooking = () => {
    setHoldBookingLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/hold-booking/?bookingId=${bookingId}`,
        {}
      )
      .then((res) => updateInvoice())
      .then((res) => updateSummaryData())
      .then((res) =>
        toast.success(
          `${t("Successfully placed")} ${bookingId} ${t("on hold")}`
        )
      )
      .then((res) => setHoldBookingLoader(false))
      .then((res) => invalidateNightAuditBooking(bookingId))
      .then((res) => handleCloseHoldBooking())
      .then((res) => (isGrid ? fetchBookingData() : ""))
      .catch((error) =>
        toast.error(`${t("Something went wrong. Can't put booking on hold.")}`)
      )
      .then((res) => setHoldBookingLoader(false))
      .then((res) => handleCloseHoldBooking());
  };

  // handling group cancel booking
  const handleGroupCancelBooking = () => {
    setHoldBookingLoader(true);
    handleSpringDataRequest(
      `core/api/v1/reservation/booking/deleteRooms/${bookingId}?phyrIds=${selectedRooms.toString()}`
    )
      .then((res) => updateInvoice())
      .then((res) => updateSummaryData())
      .then((res) =>
        toast.success(`${t("Successfully removed the room from")} ${bookingId}`)
      )
      .then((res) => setHoldBookingLoader(false))
      .then((res) => handleCloseDeleteGroupRoomBooking())
      .then((res) => (isGrid ? fetchBookingData() : ""))
      .then((res) => invalidateNightAuditBooking(bookingId))
      .catch((error) => toast.error(error.response.data))
      .then((res) => setHoldBookingLoader(false))
      .then((res) => handleCloseDeleteGroupRoomBooking());
  };

  /* CANCEL BOOKING */

  // cancel booking confirmation modal
  const [openCancelBooking, setOpenCancelBooking] = useState(false);
  const handleOpenCancelBooking = () => setOpenCancelBooking(true);
  const handleCloseCancelBooking = () => setOpenCancelBooking(false);

  // cancel booking loader
  const [cancelBookingLoader, setCancelBookingLoader] = useState(false);

  // const handleCancelBooking = () => {
  //   if (voidTypeOption === "") {
  //     toast.error("Please select any value");
  //   } else {
  //     let isApplyRefund = voidTypeOption === "APPLY_REFUND";
  //     setCancelBookingLoader(true);
  //     handleSpringDataMutationRequest(
  //       "POST",
  //       `core/api/v1/reservation/cancel-booking/`,
  //       {
  //         applyRefund: true,
  //         bookingId: bookingId,
  //         excludeChannelIds: [],
  //         external: true,
  //         isExternal: true,
  //         applyCancellationPolicy: isApplyRefund,
  //       }
  //     )
  //       .then((res) => {
  //         if (voidTypeOption !== "APPLY_REFUND") {
  //           handleVoidCharges();
  //         }
  //         await handleGetDoorLockDetails()
  //       })
  //       .then((res) =>
  //         toast.success(`Booking for ${bookingId} has been cancelled.`)
  //       )
  //       .then((res) => setCancelBookingLoader(false))
  //       .then((res) => handleCloseCancelBooking())
  //       .then((res) => setVoidTypeOption(voidTypeOptions[0]))
  //       .then((res) => (isGrid ? fetchBookingData() : ""))
  //       .then((res) => updateInvoice())
  //       .then((res) => updateSummaryData())
  //       .catch((error) =>
  //         toast.error("Something went wrong. Cannot cancel the booking.")
  //       )
  //       .then((res) => setCancelBookingLoader(false))
  //       .then((res) => handleCloseCancelBooking())
  //       .then((res) => setVoidTypeOption(voidTypeOptions[0]))
  //       .then((res) => updateInvoice())
  //       .then((res) => updateSummaryData());
  //   }
  // };

  const handleCancelBooking = async () => {
    if (voidTypeOption === "") {
      toast.error("Please select a value");
    } else {
      const isApplyRefund = voidTypeOption === "APPLY_REFUND";
      setCancelBookingLoader(true);

      try {
        await axios.post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/cancel-booking/`,
          {
            applyRefund: true,
            bookingId: bookingId,
            excludeChannelIds: [],
            external: true,
            isExternal: true,
            applyCancellationPolicy: isApplyRefund,
          }
        );

        if (voidTypeOption !== "APPLY_REFUND") {
          await handleVoidCharges();
        }

        await handleGetDoorLockDetails();

        if (isGrid) {
          fetchBookingData();
        }
        // Add a 1.5-second sleep
        await new Promise((resolve) => setTimeout(resolve, 1500));
        toast.success(`${t("Successfully cancelled")} ${bookingId}`);
        setCancelBookingLoader(false);
        invalidateNightAuditBooking(bookingId);
        handleCloseCancelBooking();
        setVoidTypeOption(voidTypeOptions[0]);
        updateInvoice();
        updateSummaryData();
        if (groupHotelEmail === "reservation@maharashtratourism.gov.in") {
          window.location.reload();
        }
      } catch (error) {
        toast.error(`${t("Something went wrong. Cannot cancel the booking.")}`);
        setCancelBookingLoader(false);
        handleCloseCancelBooking();
        setVoidTypeOption(voidTypeOptions[0]);
        updateInvoice();
        updateSummaryData();
        if (groupHotelEmail === "reservation@maharashtratourism.gov.in") {
          window.location.reload();
        }
      }
    }
  };

  // Log the details for destroying the card
  const [destroyCardLogs, setDestroyCardLogs] = useState({});
  const handleGetDoorLockDetails = async () => {
    if (sessionStorage.getItem(`xytronEnabled${hotelId}`) === "true") {
      try {
        const doorLockDetails = await handleSpringDataRequest(
          `core/api/v1/xytron/get-door-lock-details?bookingId=${bookingId}`
        );
        await handleDestroyXytronCard(doorLockDetails);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleDestroyXytronCard = async (doorLockDetails) => {
    for (let details of doorLockDetails) {
      try {
        const res = await axios.post("http://127.0.0.1:8081/destroycard.php", {
          booking_id: details["booking_id"],
          callback_url: details["callback_url"],
        });
        setDestroyCardLogs((prev) => ({
          ...prev,
          [details["booking_id"]]: res,
        }));
      } catch (err) {
        setDestroyCardLogs((prev) => ({
          ...prev,
          [details["booking_id"]]: err,
        }));
      }
    }
  };

  /* Rollback Booking */

  // rollback booking based on the status "ADMIN_CONFIRMED" or "CHECKED_OUT"

  // confirmation modal for handling the rollback of booking
  const [openRollbackBooking, setOpenRollbackBooking] = useState(false);
  const handleOpenRollbackBooking = () => setOpenRollbackBooking(true);
  const handleCloseRollbackBooking = () => setOpenRollbackBooking(false);

  // loader for rollback booking
  const [rollbackLoader, setRollbackLoader] = useState(false);

  // handling the rollback booking
  const handleRollbackBooking = () => {
    setRollbackLoader(true);
    // api call based on the status
    if (invoiceData.invoice.status === "ADMIN_CONFIRMED") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/rollback-checkin-booking`,
          {
            bookingId: bookingId,
            resIds: [],
          }
        )
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .then((res) =>
          toast.success(`${t("Status is changed to CONFIRMED successfully.")}`)
        )
        .then((res) => setRollbackLoader(false))
        .then((res) => handleCloseRollbackBooking())
        .then((res) => (isGrid ? fetchBookingData() : ""))
        .catch((err) =>
          toast.error(`${t("Error in changing the status to CONFIRMED.")}`)
        )
        .catch((err) => setRollbackLoader(false))
        .then((res) => setRollbackLoader(false))
        .then((res) => invalidateNightAuditBooking(bookingId))
        .then((res) => handleCloseRollbackBooking());
    } else if (invoiceData.invoice.status === "CHECKED_OUT") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/rollback-checkout-booking`,
          {
            bookingId: bookingId,
            resIds: [],
          }
        )
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .then((res) =>
          toast.success(`${t("Status is changed to CHECKED-IN successfully.")}`)
        )
        .then((res) => setRollbackLoader(false))
        .then((res) => invalidateNightAuditBooking(bookingId))
        .then((res) => handleCloseRollbackBooking())
        .then((res) => (isGrid ? fetchBookingData() : ""))
        .catch((err) => {
          if (err?.response?.data?.code === "500") {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(`${t("Error in changing the status to CHECKED-IN.")}`);
          }

          // console.log("invoicewrapper", err);
        })
        .then((res) => setRollbackLoader(false))
        .then((res) => handleCloseRollbackBooking());
    } else if (invoiceData.invoice.status === "CANCELLED") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/rollback-cancel-booking`,
          {
            bookingId: bookingId,
            resIds: [],
          }
        )
        .then((res) => {
          if (res?.data?.code === "200") {
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())

        .then((res) => setRollbackLoader(false))
        .then((res) => invalidateNightAuditBooking(bookingId))
        .then((res) => handleCloseRollbackBooking())
        .then((res) => (isGrid ? fetchBookingData() : ""))
        .catch((err) =>
          toast.error(
            `${t("Error in changing the status to ADMIN_CONFIRMED.")}`
          )
        )
        .then((res) => setRollbackLoader(false))
        .then((res) => handleCloseRollbackBooking());
    } else if (invoiceData.invoice.status === "NO_SHOW") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/rollback-noshow-booking`,
          {
            bookingId: bookingId,
            resIds: [],
          }
        )
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .then((res) =>
          toast.success(`${t("Successfully rolled back booking status")}`)
        )
        .then((res) => setRollbackLoader(false))
        .then((res) => invalidateNightAuditBooking(bookingId))
        .then((res) => handleCloseRollbackBooking())
        .then((res) => (isGrid ? fetchBookingData() : ""))
        .catch(
          (err) => toast.error(err?.response?.data?.message)

          // toast.error("Error in changing the status to ADMIN_CONFIRMED.")
        )
        .then((res) => setRollbackLoader(false))
        .then((res) => handleCloseRollbackBooking());
    }
  };

  // void type options
  const [voidTypeOptions, setVoidTypeOptions] = useState([
    {
      name: "APPLY_REFUND",
      description: `${t("Apply Cancellation Policy")}`,
    },
    {
      name: "NO_VOID",
      description: `${t("Don't void")}`,
    },
    {
      name: "CHARGE_ONE_NIGHT",
      description: `${t("Charge 1 night and void remaining")}`,
    },
    {
      name: "VOID",
      description: `${t("Void all remaining charges")}`,
    },
  ]);
  const [voidTypeOption, setVoidTypeOption] = useState("");
  const handleVoidTypeOption = (e) => {
    setVoidTypeOption(e.target.value);
  };

  // Handle cancellation charge booking API
  const handleVoidCharges = async () => {
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/voidCancellationCharges",
      {
        bookingId: bookingId.toString(),
        voidType: voidTypeOption,
      }
    ).catch((err) =>
      toast.error(`${t("Error in voiding cancellation charges")}`)
    );
  };

  /* Set booking.com booking to no show */
  // confirmation modal for handling the booking to no show status
  const [openBookingNoShow, setOpenBookingNoShow] = useState(false);
  const handleOpenBookingNoShow = () => setOpenBookingNoShow(true);
  const handleCloseBookingNoShow = () => setOpenBookingNoShow(false);

  /* Set booking to no show */
  // confirmation modal for handling the booking to no show status
  const [openNoShow, setOpenNoShow] = useState(false);
  const handleOpenNoShow = () => setOpenNoShow(true);
  const handleCloseNoShow = () => setOpenNoShow(false);

  // confirmation modal for handling the booking to no show status
  const [openEreceiptModal, setopenEreceiptModal] = useState(false);
  const handleOpenEReceipt = () => setopenEreceiptModal(true);
  const handleCloseEReceipt = () => setopenEreceiptModal(false);

  // loader for rollback booking
  const [noShowLoader, setNoShowLoader] = useState(false);

  // handling the booking no show
  const handleBookingNoShow = () => {
    if (voidTypeOption === "") {
      toast.error("Please select any value");
    } else {
      const isApplyRefund = voidTypeOption === "APPLY_REFUND";
      setNoShowLoader(true);
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/set-booking-noshow?bookingId=${bookingId}&bookingSrc=${invoiceData?.invoice.booking_source}&isReservation=false&applyCancellationPolicy=${isApplyRefund}`,
          {}
        )
        .then((res) => {
          if (voidTypeOption !== "APPLY_REFUND") {
            handleVoidCharges();
          }
        })
        .then((res) =>
          toast.success(`${t("Successfully marked  NO SHOW for")} ${bookingId}`)
        )
        .then((res) => setNoShowLoader(false))
        .then((res) => handleCloseNoShow())
        .then((res) => setVoidTypeOption(voidTypeOptions[0]))
        .then((res) => (isGrid ? fetchBookingData() : ""))
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .catch((err) =>
          toast.error(`${t("Error in changing the status to NO SHOW.")}`)
        )
        .then((res) => setNoShowLoader(false))
        .then((res) => handleCloseNoShow())
        .then((res) => setVoidTypeOption(voidTypeOptions[0]))
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .then((res) => invalidateNightAuditBooking(bookingId));
    }
  };

  /* Capture Guest Signature */
  // creating or updating terms and conditions for the front desk
  // data will be shown into the terminal
  // used by end users to sign the toc

  const [openToc, setOpenToc] = useState(false);
  const handleOpenToc = () => setOpenToc(true);
  const handleCloseToc = () => setOpenToc(false);

  // toc
  const [toc, setToc] = useState(invoiceData?.hotel.hotel_terms_conditions);

  // toc loader
  const [tocLoader, setTocLoader] = useState(false);

  function getCurrentDateTime() {
    const now = new Date();

    // Pad single digits with leading zeros
    function pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    }

    // Format milliseconds to ensure three digits by padding with leading zeros
    function formatMilliseconds(milliseconds) {
      if (milliseconds < 10) {
        return "00" + milliseconds;
      } else if (milliseconds < 100) {
        return "0" + milliseconds;
      }
      return milliseconds;
    }

    // Get timezone offset in hours and minutes
    const offset = -now.getTimezoneOffset(),
      offsetSign = offset >= 0 ? "+" : "-",
      offsetHours = pad(Math.floor(Math.abs(offset) / 60)),
      offsetMinutes = pad(Math.abs(offset) % 60);

    // Format date and time in yyyy-mm-ddThh:mm:ss.nnn+hh:mm format
    const dateTime =
      now.getFullYear() +
      "-" +
      pad(now.getMonth() + 1) +
      "-" +
      pad(now.getDate()) +
      "T" +
      pad(now.getHours()) +
      ":" +
      pad(now.getMinutes()) +
      ":" +
      pad(now.getSeconds()) +
      "." +
      formatMilliseconds(now.getMilliseconds()) +
      offsetSign +
      offsetHours +
      ":" +
      offsetMinutes;

    return dateTime;
  }

  // handling and updating the toc
  const handleToc = () => {
    setTocLoader(true);
    if (custom_pg_name === "SHIFT4") {
      let myHeaders = new Headers();
      myHeaders.append("InterfaceVersion", "4.0");
      myHeaders.append("InterfaceName", "Stayflexi");
      myHeaders.append("CompanyName", "Stayflexi");
      myHeaders.append("AccessToken", shift4_access_token);
      myHeaders.append("Content-Type", "application/json");
      let requestBody = {
        dateTime: getCurrentDateTime(),
        device: {
          terminalId: shift4_api_terminal_id,
        },
      };
      let raw = JSON.stringify(requestBody);
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      let URL = shift4_utg_url + "api/rest/v1/devices/promptsignature";

      fetch(URL, requestOptions)
        .then((response) => {
          return response.json().then((json) => {
            return response.ok
              ? json
              : Promise.reject({ status: response.status, json });
          });
        })
        .then((result) => {
          const guestEmailId = invoiceData?.invoice.customer_email;

          handleSpringDataMutationRequest(
            "POST",
            "core/api/v1/payments/shift4/save-signature/?bookingId=" +
              bookingId +
              "&guestEmailId=" +
              guestEmailId,
            result
          )
            .then((res) => updateInvoice())
            .then((res) => updateSummaryData())
            .then((res) =>
              toast.success(`${t("Guest signature captured successfully.")}`)
            )
            .then((res) => setTocLoader(false))
            .then((res) => handleCloseToc())
            .catch((error) =>
              toast.error(`${t("Failed to capture guest signature.")}`)
            )
            .then((res) => setTocLoader(false))
            .then((res) => handleCloseToc());
        });
    } else {
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/sendTocSignatureRequest/",
        {
          hotelId: hotelId,
          booking_id: bookingId,
          toc: toc,
          cust_email: invoiceData?.invoice.customer_email,
          logged_user_email: loggedUserEmail,
        }
      )
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .then((res) =>
          toast.success(`${t("Guest signature captured successfully.")}`)
        )
        .then((res) => setTocLoader(false))
        .then((res) => handleCloseToc())
        // .catch((error) => toast.error("Failed to send. Please try again later!"))
        .catch((error) => toast.error(error))
        .then((res) => setTocLoader(false))
        .then((res) => handleCloseToc());
    }
  };

  /* ADD NEW SERVICE */

  // Showing mock loaders while add new service to both the wrapper as well as the summary
  const [mockLoaders, setMockLoaders] = useState(false);

  // adding the new services ---  "CREDIT" and "DEBIT"

  // creating modal for adding the services
  const [openAddNewService, setOpenAddNewService] = useState(false);
  const handleOpenAddNewService = () => setOpenAddNewService(true);
  const handleCloseAddNewService = () => setOpenAddNewService(false);

  // creating hotel Addons model
  const [openHotelAddon, setopenHotelAddon] = useState(false);
  const handleOpenHotelAddon = () => setopenHotelAddon(true);
  const handleCloseHotelAddon = () => setopenHotelAddon(false);

  // creating hotel Addons model
  const [openCollectGuestInfo, setopenCollectGuestInfo] = useState(false);
  const handleOpenCollectGuestInfo = () => setopenCollectGuestInfo(true);
  const handleCloseCollectGuestInfo = () => setopenCollectGuestInfo(false);

  // creating hotel Addons model
  const [copyBookingId, setcopyBookingId] = useState("");
  const [openCopyReservation, setopenCopyReservation] = useState(false);
  const handleOpenCopyReservation = () => {
    setcopyBookingId("");
    setopenCopyReservation(true);
  };
  const handleCloseCopyReservation = () => setopenCopyReservation(false);

  // apply discount model
  const [openDiscount, setopenDiscount] = useState(false);
  const handleOpenDiscount = () => setopenDiscount(true);
  const handleCloseDiscount = () => setopenDiscount(false);

  // add new service loader
  const [addNewServiceLoader, setAddNewServiceLoader] = useState(false);

  // getting all the tax categories with the percentage vals
  const [taxCats, setTaxCats] = useState([
    {
      id: "-1",
      name: "Default",
      is_group_tax: "false",
      percentage: 0,
      province: "India",
    },
  ]);

  // handling the api to get all the tax cats
  function getAllTaxCats() {
    djangoHandleDataRequests("tax/getAllTaxCategories/?include_groups=true")
      .then((res) => res.map((item) => taxCats.push(item)))
      .catch((error) => toast.error(`${t("Cannot fetch any tax categories")}`));
  }

  useEffect(() => {
    getAllTaxCats();
  }, []);

  // fields to feed into the info for adding the new service
  // rendering the fee

  const [serviceDate, setServiceDate] = useState(new Date());
  const [serviceAmtType, setServiceAmtType] = useState("DEBIT");
  const [serviceDesc, setServiceDesc] = useState("");
  const [serviceTax, setServiceTax] = useState(0);
  const [serviceAmt, setServiceAmt] = useState(0.0);
  const [finalServiceAmt, setFinalServiceAmt] = useState(0.0);
  const [serviceTaxCash, setServiceTaxCash] = useState(0);

  // rendering the final service amount
  // Handle the service amount
  function handleServiceAmt(val) {
    setServiceAmt(val);
    calFinalService(val);
  }

  function calFinalService(val) {
    var formattedVal = val ? Number(val) : 0;
    if (serviceTax) {
      let amt =
        parseFloat(formattedVal) +
        (parseFloat(serviceTax.toFixed(2)) / 100.0) *
          parseFloat(formattedVal.toFixed(2));
      setFinalServiceAmt(parseFloat(amt.toFixed(2)));
    } else {
      setFinalServiceAmt(formattedVal);
    }
  }

  useEffect(() => {
    calFinalService(serviceAmt);
  }, [serviceTax, bookingId]);

  // Function to calculate the pre tax amount
  // Handling the final amount entered by the user
  function handleFinalServiceAmt(val) {
    setFinalServiceAmt(val);
    handlePretaxAmt(val);
  }
  function handlePretaxAmt(val) {
    var formattedVal = val >= 0 ? Number(val) : 0;
    if (serviceTax) {
      let tempExempTax = parseFloat(
        ((serviceTax * formattedVal) / (Number(100) + serviceTax)).toFixed(2)
      );
      let amt = parseFloat(formattedVal.toFixed(2)) - tempExempTax;
      setServiceAmt(parseFloat(amt.toFixed(2)));
    } else {
      setServiceAmt(formattedVal);
    }
  }

  // useEffect(() => {
  //   handlePretaxAmt(finalServiceAmt);
  // }, [serviceTax, bookingId]);

  /* Rendering the booking status */
  const [bookStatusBgColor, setBookStatusBgColor] = useState("black");
  const [displayBookingName, setDisplayBookingName] = useState("");

  const renderBookingStatus = () => {
    let bookingStatus = invoiceData?.invoice.status;
    StatusMap.filter((item) => {
      if (item.name === bookingStatus) {
        return item;
      }
    }).map((val) => {
      setDisplayBookingName(val.display_name);
      setBookStatusBgColor(val.color);
    });
  };

  useEffect(() => {
    renderBookingStatus();
  }, [invoiceData, bookingId]);

  // Get tax name for hotel
  // const [taxes, setTaxes] = useState([]);
  // const getTaxNamesForHotel = () => {
  //   handleSpringDataRequest("core/api/v1/reservation/getTaxNamesForHotel")
  //     .then((res) => console.log("getting the tax name for hotel : ", res))
  //     .catch((err) => toast.error("Error in fetching the taxes"));
  // };

  // useEffect(() => {
  //   getTaxNamesForHotel();
  // }, []);

  // rendering the add new service UI based on "CREDIT" or "DEBIT"
  function renderAddNewService() {
    return (
      <div className="renderAddService">
        <div className="addServiceWrapper">
          <div className="invoiceInfoWrapper">
            <div className="infoField">
              <TextField
                id="outlined-multiline-flexible"
                label={t("Folio item name")}
                name="service_desc"
                multiline
                value={serviceDesc}
                onChange={(e) => setServiceDesc(e.target.value)}
                sx={{ width: "100%" }}
                error={serviceDesc.length === 0 ? true : false}
                helperText={
                  serviceDesc.length === 0 &&
                  `${t("Mandatory to add item name.")}`
                }
              />
            </div>
            {serviceAmtType === "CREDIT" && (
              <div className="infoField" style={{ width: "100%" }}>
                <TextField
                  id="outlined-multiline-flexible"
                  label={t("Service amount without tax")}
                  name="service_amount"
                  multiline
                  value={serviceAmt}
                  onChange={(e) => handleServiceAmt(e.target.value)}
                  sx={{ width: "100%" }}
                  type={"number"}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
            )}

            <div className="infoField">
              {serviceAmtType === "DEBIT" && (
                <Stack direction="row" spacing={2}>
                  <div className="infoField">
                    <TextField
                      id="outlined-multiline-flexible"
                      label={t("Service amount without tax")}
                      name="service_amount"
                      multiline
                      value={serviceAmt}
                      onChange={(e) => handleServiceAmt(e.target.value)}
                      sx={{ width: "100%" }}
                      type={"number"}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </div>
                  <div className="infoField" style={{ width: "15rem" }}>
                    {serviceAmtType === "DEBIT" && (
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          {t("Applicable taxes")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={serviceTax}
                          label={t("Applicable taxes")}
                          onChange={(e) => setServiceTax(e.target.value)}
                          name="service_tax"
                        >
                          {taxCats &&
                            taxCats.map((item, idx) => (
                              <MenuItem value={item.percentage}>
                                {item.name} [{item.percentage}%]
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  <div className="infoField">
                    <TextField
                      id="outlined-multiline-flexible"
                      label={t("Service amount with tax")}
                      name="final_service_amount"
                      multiline
                      value={finalServiceAmt}
                      onChange={(e) => handleFinalServiceAmt(e.target.value)}
                      sx={{ width: "100%" }}
                      type={"number"}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </div>
                </Stack>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // function to create the tax
  function handleTaxCalc() {
    let totalServiceTax =
      (parseFloat(serviceTax).toFixed(2) / 100.0) *
      parseFloat(serviceAmt).toFixed(2);
    setServiceTaxCash(parseFloat(totalServiceTax).toFixed(2));
  }

  useEffect(() => {
    handleTaxCalc();
  }, [serviceTax, serviceAmt, bookingId]);

  // handling add new service
  function handleAddNewService() {
    // handleTrueSummaryMockLoader()
    // handleTrueWrapperMockLoader()
    setAddNewServiceLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/addServiceToInvoice/`,
        {
          hotelId: hotelId,
          booking_id: bookingId,
          reservation_id: selectedResId,
          service_item: {
            service_date: moment(serviceDate).format("MMM DD YYYY HH:mm:ss"),
            service_desc: serviceDesc,
            service_amount: parseFloat(serviceAmt).toFixed(2),
            service_amount_type: serviceAmtType,
            service_tax: parseFloat(serviceTaxCash).toFixed(2),
          },
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => updateSummaryData())
      .then((res) => updateInvoice())
      .then((res) =>
        toast.success(`${t("Successfully added new service")} ${bookingId}`)
      )
      .then((res) => setAddNewServiceLoader(false))
      // .then((res) => handleFalseSummaryMockLoader())
      // .then((res) => handleFalseWrapperMockLoader())
      .then((res) => handleCloseAddNewService())
      .then((res) => setServiceDate(new Date()))
      .then((res) => setServiceAmtType("DEBIT"))
      .then((res) => setServiceDesc(""))
      .then((res) => setServiceTax(0))
      .then((res) => setServiceAmt(""))
      .then((res) => setFinalServiceAmt(""))
      .then((res) => setServiceTaxCash(0))
      .catch((error) => toast.error(error))
      .then((res) => setAddNewServiceLoader(false))
      // .then((res) => handleFalseSummaryMockLoader())
      // .then((res) => handleFalseWrapperMockLoader())
      .then((res) => handleCloseAddNewService())
      .then((res) => setServiceDate(new Date()))
      .then((res) => setServiceAmtType("DEBIT"))
      .then((res) => setServiceDesc(""))
      .then((res) => setServiceTax(0))
      .then((res) => setServiceAmt(""))
      .then((res) => setFinalServiceAmt(""))
      .then((res) => setServiceTaxCash(0));
    // .then((res) => invalidateNightAuditBooking(bookingId));
  }

  /* Folio Logs */

  /* Getting the folio log data */
  const [folioLogs, setFolioLogs] = useState(null);

  function handleLoggedActions() {
    djangoHandleDataRequests(
      `invoice/bookingInvoiceFolioLogReport?bookingID=${bookingId}`
    )
      .then((res) => setFolioLogs(res))
      .catch((error) => toast.error(`${t("Oops! Error fetching the logs!")}`));
  }
  useEffect(() => {
    handleLoggedActions();
  }, []);

  // Modal for folio logs
  const [openFolioLog, setOpenFolioLog] = useState(false);

  const handleOpenFolioLog = () => {
    handleLoggedActions();
    setOpenFolioLog(true);
  };
  const handleCloseFolioLog = () => setOpenFolioLog(false);

  /* Tax Exemption Status */
  //----------error handeling states----------

  const [errDisplay, setErrDisplay] = useState("none");
  const [idErrDisplay, setIdErrDisplay] = useState("none");

  // getting the tax exemption id
  const [taxExemptionId, setTaxExemptionId] = useState("");
  function handleTaxExemptionId(event) {
    setIdErrDisplay("none");
    setTaxExemptionId(event.target.value);
  }

  useEffect(() => {
    setTaxExemptionId(
      summaryData?.tax_exempt_id == "NA" ? "" : summaryData?.tax_exempt_id
    );
  }, [summaryData]);

  // Fetching the room taxes
  // const [taxNames, setTaxNames] = useState([]);
  // function handleTaxNames() {
  //   // setTaxNames(taxNameWithStatus.map((item) => item.taxName));
  //   taxNames.length === 0 &&
  //     handleSpringDataRequest(
  //       `core/api/v1/reservation/getTaxNamesForRoom/?roomTypeId=${summaryData?.roomtype_id}`
  //     )
  //       .then((res) => setTaxNames(res))
  //       .catch((err) => toast.error("Error in fetching the room taxes."));
  // }

  // useEffect(() => {
  //   handleTaxNames();
  // }, [summaryData]);

  // Handling all the exempted taxes and creating a payload

  const [taxExemptName, setTaxExemptName] = useState([]);

  // prepopulate the tax exempt name if there is tax exempt id with the predefined taxes
  // useEffect(() => {
  //   setTaxExemptName(taxNames);
  // }, [taxNames]);

  const handleExemptedTaxes = () => {
    // if (taxNames.length > 0) {
    //   setTaxExemptName(taxNames.map((taxName) => taxName));
    // }

    let taxExemptArr = [];
    Object.entries(summaryData?.tax_breakdown).map(([keys, values]) => {
      if (Number(values) === 0) {
        return taxExemptArr.push(keys);
      }
    });

    return setTaxExemptName(taxExemptArr);
  };

  useEffect(() => {
    handleExemptedTaxes();
  }, [summaryData]);

  // Handling the tax exempt names from the select statement
  const handleTaxExemptName = (event) => {
    setErrDisplay("none");
    // const {
    //   target: { value },
    // } = event;
    // setTaxExemptName(
    //   typeof value === "string" ? value.split(",") : value
    // );

    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (
        taxExemptName.length === Object.keys(summaryData.tax_breakdown).length
      ) {
        setTaxExemptName([]);
      } else {
        setTaxExemptName(Object.keys(summaryData.tax_breakdown));
      }
    } else {
      setTaxExemptName(value);
    }
  };

  // Setting the status for the exempted taxes --- true or false

  const [isTaxExempted, setIsTaxExempted] = useState(false);
  const [customTaxData, setCustomTaxData] = useState([]);

  const handleChangeTaxExemptStatus = async (event) => {
    if (isTaxExempted) {
      setTaxExemptName(Object.keys(summaryData.tax_breakdown));
    }
    setIsTaxExempted((prev) => !prev);
    await getAllCustomTaxData();
  };

  const getAllCustomTaxData = async () => {
    try {
      await handleSpringDataRequest("core/api/v1/customTax/getAllCustomTax")
        .then((res) => {
          setCustomTaxData(res);
        })
        .catch((err) => toast.error(`${t("Error in fetching fees")}`));
    } catch (e) {
      console.error(e);
    }
  };
  const [updatedTaxBreakdown, setUpdatedTaxBreakdown] = useState({});

  useEffect(() => {
    // Update tax breakdown when component mounts or data changes
    const updatedData = filterCustomTaxData();
    setUpdatedTaxBreakdown(updatedData);
  }, [summaryData, customTaxData]);

  const filterCustomTaxData = () => {
    const taxNamesToRemove = new Set(customTaxData.map((tax) => tax.tax_name));

    const updatedTaxBreakdown = Object.fromEntries(
      Object.entries(summaryData.tax_breakdown).filter(
        ([taxName]) => !taxNamesToRemove.has(taxName)
      )
    );

    return updatedTaxBreakdown;
  };

  // Convert updatedTaxBreakdown to an array if you need to use map
  const taxBreakdownEntries = Object.entries(updatedTaxBreakdown);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allTaxNames = taxBreakdownEntries.map(([taxName]) => taxName);
      setTaxExemptName(allTaxNames); // Select all filtered tax names
    } else {
      setTaxExemptName([]); // Deselect all
    }
  };

  // Call this function in useEffect to fetch the custom tax data initially
  useEffect(() => {
    getAllCustomTaxData();
  }, []);

  // if tax exempted is coming as no from summary data set the isTaxExempted status as true
  // otherwise false

  useEffect(() => {
    Object.values(summaryData?.tax_breakdown).includes(0)
      ? setIsTaxExempted(true)
      : setIsTaxExempted(false);
  }, [summaryData, bookingId]);

  // Modal for tax exemption status

  const [openTaxExempt, setOpenTaxExempt] = useState(false);
  const handleOpenTaxExempt = () => setOpenTaxExempt(true);
  const handleCloseTaxExempt = () => setOpenTaxExempt(false);

  // Handling the tax exemption

  // Loader for tax exemption

  const [taxExemptLoader, setTaxExemptLoader] = useState(false);

  function handleTaxExemption() {
    if (!taxExemptionId) {
      setIdErrDisplay("block");
      return;
    }
    if (String(taxExemptName).length === 0) {
      setErrDisplay("block");
      return;
    }

    setTaxExemptLoader(true);
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/setTaxExemptionStatusOnBooking/",
      {
        booking_id: bookingId,
        tax_exempt_names: isTaxExempted === true ? taxExemptName : [],
        hotelId: hotelId,
        tax_exempt_id: taxExemptionId,
        tax_exempt_status: isTaxExempted.toString(),
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => updateInvoice())
      .then((res) => updateSummaryData())
      .then((res) =>
        toast.success(`${t("Successfully set the tax exemption status.")}`)
      )
      .then((res) => setTaxExemptLoader(false))
      .then((res) => invalidateNightAuditBooking(bookingId))
      .then((res) => handleCloseTaxExempt())
      .catch((error) => toast.error(error))
      .catch((error) => handleCloseTaxExempt());
  }

  // Error handling for tax exemption status

  /* CTA DROPDOWN */

  // handling the CTA dropdowns
  const [ctaDropdown, setCtaDropdown] = useState([]);

  const [defaultCtadropdown, setDefaultdropdown] = useState([]);

  function handleCtaDropdown() {
    // handleTrueWrapperMockLoader();
    setCtaDropdown([]);
    setDefaultdropdown([]);
    // if (Number(summaryData?.total_cta_balance) === 0) {
    // setCtaDropdown([
    //   {
    //     ctaName: "Walk-In",
    //     taxId: null,
    //   },
    // ]);
    djangoHandleDataRequests("invoice/getBookingSrcDropdownData/")
      .then((res) => {
        setDefaultdropdown(res);
        setCtaDropdown(res);
        // Number(summaryData?.total_cta_balance) === 0 && setCtaDropdown(res);
        // const dat = res.map((item, idx) => {
        //   setCtaDropdown((prev) => [
        //     ...prev,
        //     {
        //       ctaName: item.cta_name,
        //       taxId: item.cta_tax_id_no,
        //       ctaEmail: item.cta_email,
        //     },
        //   ]);
        // });
      })
      // .then((res) => handleFalseWrapperMockLoader())
      .catch((err) => toast.error(`${t("Error fethcing cta dropdown.")}`));
    // .then((res) => handleFalseWrapperMockLoader());
    // }
    // else if (Number(summaryData?.total_cta_balance) === 0) {
    //   // setCtaDropdown([])
    //   djangoHandleDataRequests("invoice/getBookingSrcDropdownData/")
    //     .then((res) =>
    //       res.map((item, idx) => {
    //         setCtaDropdown((prev) => [
    //           ...prev,
    //           {
    //             ctaName: item.cta_name,
    //             taxId: item.cta_tax_id_no,
    //           },
    //         ]);
    //       })
    //     )
    //     .catch((err) => toast.error("Error fethcing cta dropdown."));
    // }
  }

  useEffect(() => {
    handleCtaDropdown();
  }, [bookingId]);

  const [userDefinedSources, setUserDefinedSources] = useState(null);

  const getUserDefinedSources = () => {
    handleSpringDataRequest(`core/api/v1/user-defined-source/get-all-sources`)
      .then((res) => {
        setUserDefinedSources(res);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    getUserDefinedSources();
  }, []);

  const [mapsss, setMapsss] = useState(null);

  useEffect(() => {
    const mp = {
      DIRECT: [
        `${t("WALK-IN")}`,
        `${t("PHONE CALL")}`,
        `${t("EMAIL")}`,
        `${t("WHATSAPP")}`,
        `${t("SOCIAL MEDIA")}`,
      ],
      METASEARCH: [
        `${t("GOOGLE")}`,
        `${t("TRIPADVISOR")}`,
        `${t("TRIVAGO")}`,
        `${t("KAYAK")}`,
      ],
      // DIRECT_BILLING: ctaDropdown,
    };
    if (ctaDropdown?.length > 0) {
      mp["DIRECT_BILLING"] = ctaDropdown;
    }
    setMapsss(mp);
  }, [ctaDropdown]);

  useEffect(() => {
    if (mapsss && majorSources && userDefinedSources) {
      let tempser = majorSources;
      let temparr = [];
      const respS = Object.keys(mapsss).map((res) => {
        temparr.push(res);
      });

      const datacs = tempser.map((res) => {
        temparr.push(res);
      });
      if (
        JSON.parse(data?.accessControl?.user_feature_map?.ShowOtaSegment)
          .read === "YES"
      ) {
        setMostMajorSource(temparr);
      } else {
        setMostMajorSource(temparr.filter((item) => item !== "OTA"));
      }
      setSegment(invoiceData.invoice.segment);
      let tempusersrs = userDefinedSources;
      let newarr = [];
      const dattsa = Object.entries(mapsss).map(([key, val]) => {
        newarr[key] = val;
      });
      const dattsass = Object.entries(tempusersrs).map(([key, val]) => {
        newarr[key] = val;
      });
      setMostSecondarySource(newarr);
    }
  }, [mapsss, userDefinedSources, majorSources]);

  useEffect(() => {
    if (userDefinedSources !== null) {
      let srs = Object.keys(userDefinedSources);
      setMajorSources(srs);
      setSegment(srs[0]);
    }
  }, [userDefinedSources]);

  const handleSegmentChange = (res) => {
    addSegment(res);
    setSegment(res);
  };

  useEffect(() => {
    if (segment !== null && mostSecondarySource !== null) {
      const arr = mostSecondarySource[segment];
      setSubSegmentArr(arr);
      if (arr?.length > 0) {
        if (typeof arr[0] === "object") {
          setSubSegment(arr[0].cta_name);
        } else {
          setSubSegment(arr[0]);
        }
      }
    }
  }, [segment, mostSecondarySource]);

  const handleSubSegmentChange = (res) => {
    setSubSegment(res);
    setInitSubSegment(res);
    if (res === "WALK-IN") {
      updateSource("STAYFLEXI_HD");
    } else {
      updateSource(res);
    }
  };

  const handleGetBillToCta = () => {
    let adjustedAmt = 0;
    let finalAmt = 0;
    if (invoiceData?.invoice?.cta_billed_items.length > 0) {
      const data = invoiceData?.invoice.cta_billed_items?.forEach(
        (item) => (adjustedAmt += item?.refund_amount)
      );
      finalAmt = Number(summaryData?.bill_to_cta_amt) - Number(adjustedAmt);
    } else {
      finalAmt = 0;
    }
    return parseFloat(finalAmt).toFixed(2);
  };

  // useEffect(() => {
  //   if (ctas !== null) {
  //     let val = null;
  //     const data = ctas.map((res) => {
  //       if (subSegment === res.cta_name) val = res;
  //     });
  //     if (val !== null) {
  //       setSelectedCta(val);
  //       pricingRequestModel.ctaId = val.cta_id;
  //       pricingRequestModel.resetParams();
  //       fetchUpdatedBookingPricing();
  //     }
  //   }
  // }, [subSegment]);

  const addSegment = async (seg) => {
    // handleTrueWrapperMockLoader();
    setInitSegment(seg);
    const data = await addSegmentAndSubSegment(bookingId, seg)
      .then((res) => {
        if (seg !== null && mostSecondarySource !== null) {
          const arr = mostSecondarySource[seg];
          setSubSegmentArr(arr);
          if (typeof arr[0] === "object") {
            updateSource(arr[0].cta_name);
            setInitSubSegment(arr[0].cta_name);
            setSubSegment(arr[0].cta_name);
          } else {
            if (arr[0] === "WALK-IN") {
              updateSource("STAYFLEXI_HD");
            } else {
              updateSource(arr[0]);
            }
            setInitSubSegment(arr[0]);
            setSubSegment(arr[0]);
          }
        }
        updateInvoice();
        handleCtaDropdown();
        // toast.success("Booking source changed successfully.");
      })
      .catch((err) => toast.error(err));
  };

  // updating booking source using folio attribute
  function updateSource(newBookingSrc) {
    // handleTrueWrapperMockLoader();
    djangoHandleDataMutationRequest("POST", "invoice/updateFolioAttribute/", {
      name: "booking_src",
      value: newBookingSrc,
      pk: "booking_source",
      hotel_id: hotelId,
      booking_id: bookingId,
    })
      // .then((res) => handleFalseWrapperMockLoader())
      // .then((res) => toast.success("Booking source changed successfully."))
      .then((res) => {
        updateInvoice();
        handleCtaDropdown();
        // toast.success("Booking source changed successfully.");
      })
      .catch((err) => toast.error(err));
  }

  // function to split the address into the new line using the comma
  function splitAddress(address) {
    return address.split(",").join("<br />");
  }

  // Displaying the mock invoice table loader while performing any operation into the table
  const [mockTable, setMockTable] = useState(false);
  const mockTableTrue = () => setMockTable(true);
  const mockTableFalse = () => setMockTable(false);

  /* Getting the advanced invoice data */
  // field for the advanced invoice data
  const [advancedInvoiceData, setAdvancedInvoiceData] = useState(null);
  const getAdvancedData = () => {
    mockTableTrue();
    djangoHandleDataRequests(
      `invoice/advancedInvoiceDataRender/?bookingID=${bookingId}`
    )
      .then((res) => setAdvancedInvoiceData(res))
      .then((res) => mockTableFalse())
      .catch((err) =>
        toast.error(`${t("Error fetching the advanced invoice details")}`)
      )
      .then((res) => mockTableFalse());
  };

  // Getting adv invoice room data
  const [detailedInvData, setDetailedInvData] = useState(null);
  function getDetailedInvData() {
    mockTableTrue();
    handleSpringDataRequest(
      `core/api/v1/folio/get-detail-invoice/?bookingId=${bookingId}`
    )
      .then((res) => setDetailedInvData(res))
      .then((res) => mockTableFalse())
      .catch((err) =>
        toast.error(`${t("Error fetching the advanced row invoice details")}`)
      )
      .then((res) => mockTableFalse());
  }

  // Get the data for the custom invoices
  // Setting the required custom invoice data
  const [customInvoiceData, setCustomInvoiceData] = useState(null);
  function handleGetCustomInvoiceData(customInvoiceId) {
    mockTableTrue();
    djangoHandleDataRequests(
      `invoice/subFolioInvoiceDataRender?bookingID=${bookingId}&customFolioId=${customInvoiceId}`
    )
      .then((res) => setCustomInvoiceData(res))
      .catch((err) => toast.error(err))
      .finally(() => mockTableFalse());
  }

  useEffect(() => {
    if (checked === "-2") {
      getAdvancedData();
    } else if (checked === "-3") {
      getDetailedInvData();
    } else if (!["-1", "-2", "-3"].includes(checked)) {
      handleGetCustomInvoiceData(checked);
    }
  }, [checked]);

  // handle display address
  function handleDisplayAddress(address) {
    if (!address.length > 0) {
      return null;
    } else if (address.includes("NA")) {
      return null;
    } else if (address.includes("null")) {
      return null;
    } else {
      return address.concat(",");
    }
  }
  function handleDisplayCode(address) {
    if (!address.length > 0) {
      return null;
    } else if (address.includes("NA")) {
      return null;
    } else if (address.includes("null")) {
      return null;
    } else {
      return address;
    }
  }

  /* Handle Sending Links to Customer  */

  // 1. Sending Booking Confirmation
  // 2. Sending Magic Link

  // handling the email booking confirmation modal
  const [openBookingConf, setOpenBookingConf] = useState(false);
  const handleOpenBookingConf = () => {
    setOpenBookingConf(true);
  };
  const handleCloseBookingConf = () => {
    setOpenBookingConf(false);
  };

  // handling booking confirmation email loading
  const [bookingConfLoading, setBookingConfLoading] = useState(false);

  // pre populating booking confirmation mail
  const [bookingConfMail, setBookingConfMail] = useState();

  // Get the epoch time based on the date time and the timezone
  const dateToEpoch = (dateString) => {
    const epochTime = moment.tz(dateString, data.hotelTimeZone).unix();
    return epochTime;
  };

  // Handle email booking confirmation
  function handleSendBookingConfirmation() {
    setBookingConfLoading(true);
    hotelType === "ISHA"
      ? handleSpringDataRequest(
          `core/api/v1/reservation/isha-send-email-confirmation/?bookingId=${bookingId}`
        )
          .then((res) =>
            toast.success(
              `${t("Successfully sent booking confirmation for")} ${bookingId}`
            )
          )
          .then((res) => setBookingConfLoading(false))
          .then((res) => handleCloseBookingConf())
          .catch((err) =>
            toast.error(err).catch((err) => setBookingConfLoading(false))
          )
      : invoiceData?.invoice?.is_enquiry === "true"
      ? // ? djangoHandleDataRequests(
        //     `email/sendEnquiryNotif?bookingId=${bookingId}&emailId=${bookingConfMail}&expiryTime=${dateToEpoch(
        //       invoiceData?.invoice?.enquiry_expiration_time
        //     )}`
        //   )
        //     .then((res) => toast.success("Enquiry mail sent successfully."))
        //     .catch((err) => toast.error(err))
        //     .finally(() => {
        //       setBookingConfLoading(false);
        //       handleCloseBookingConf();
        //     })
        handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/reservation/sendEnquiryEmailCustomer",
          {
            bookingId: bookingId,
            emailId: bookingConfMail,
            expiryTime: dateToEpoch(
              invoiceData?.invoice?.enquiry_expiration_time
            ),
            source: invoiceData?.invoice.booking_source,
          }
        )
          .then((res) =>
            toast.success(`${t("Enquiry mail sent successfully.")}`)
          )
          .catch((err) => toast.error(err))
          .finally(() => {
            setBookingConfLoading(false);
            handleCloseBookingConf();
          })
      : handleSpringDataMutationRequest(
          "POST",
          `core/api/v1/reservation/${
            invoiceData?.invoice?.status === "CONFIRMED"
              ? "sendBookingConfirmationCust"
              : "sendCheckinConfirmationCust"
          }`,
          {
            booking_id: bookingId,
            email_id: bookingConfMail,
          }
        )
          .then((res) =>
            toast.success(
              `${t("Successfully sent booking confirmation for")} ${bookingId}`
            )
          )
          .then((res) => setBookingConfLoading(false))
          .then((res) => handleCloseBookingConf())
          .catch((err) =>
            toast.error(err).catch((err) => setBookingConfLoading(false))
          );
  }

  // Email folio to the mentioned email id

  // handling the email folio modal
  const [openMailFolio, setOpenMailFolio] = useState(false);
  const handleOpenMailFolio = () => {
    setOpenMailFolio(true);
  };
  const handleCloseMailFolio = () => {
    setOpenMailFolio(false);
  };

  // handling email folio loading
  const [sendFolioLoading, setSendFolioLoading] = useState(false);

  // pre populating folio mail id
  const [mailFolioId, setMailFolioId] = useState();

  // Handle email folio
  function handleSendEmailFolio() {
    setSendFolioLoading(true);
    djangoHandleDataRequests(
      `email/sendBookingInvoiceCustEmail/?booking_id=${bookingId}&email=${mailFolioId}`
    )
      .then((res) => toast.success(`${t("Successfully mailed folio")}`))
      .then((res) => setSendFolioLoading(false))
      .then((res) => handleCloseMailFolio())
      .catch((err) => toast.error(err))
      .then((res) => setSendFolioLoading(false))
      .then((res) => handleCloseMailFolio());
  }

  // handling the email magic link modal
  const [openMagicLinkModal, setOpenMagicLinkModal] = useState(false);
  const handleOpenMagicLinkModal = () => {
    setOpenMagicLinkModal(true);
  };
  const handleCloseMagicLinkModal = () => {
    setOpenMagicLinkModal(false);
  };

  // open currency Modal

  // const [openCurrencyModal, setOpenCurrencyModal] = useState(false);
  // const handleOpenCurrencyModal = () => {
  //   setOpenCurrencyModal(true);
  // };
  // const handleCloseCurrencyModal = () => {
  //   setOpenCurrencyModal(false);
  // };

  // handling edit meal plan magic link modal
  const [openMealPlanModal, setMealPlanModal] = useState(false);
  const handleOpenMealPlanModal = () => {
    setMealPlanModal(true);
  };
  const handleCloseMealPlanModal = () => {
    setMealPlanModal(false);
  };

  //handle Scanty Baggage Modal

  const [openScantyModal, setScantyModal] = useState(false);
  const handleOpenScantyModal = () => {
    setScantyModal(true);
  };
  const handleCloseScantyModal = () => {
    setScantyModal(false);
  };
  const [isScantybaggae, setisScantybaggae] = useState("NO");
  const [markedScantybaggage, setmarkedScantybaggage] = useState("NO");

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/booking-misc-info/get-for-booking?bookingId=${bookingId}`
    )
      .then((res) => setmarkedScantybaggage(res.scantyBaggage))
      .catch((err) => console.error(err));
  }, []);

  const markScantyBaggage = () => {
    setMagicLinkLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/booking-misc-info/create-update`,
      {
        bookingId: bookingId,
        hotelId: hotelId,
        scantyBaggage: isScantybaggae,
      }
    )
      .then((res) => {
        toast.success(
          `${t("Successfully")} ${
            isScantybaggae === "YES" ? `${t("added")}` : `${t("removed")}`
          } ${t("scanty baggage")}`
        );
        setMagicLinkLoading(false);
        updateInvoice();
        handleCloseScantyModal();
      })
      .catch((err) => {
        toast.error(`${t("Error in marking scanty baggage")}`);
        setMagicLinkLoading(false);
      });
  };

  // handling the split booking modal
  const [openSplitbookingModal, setopenSplitbookingModal] = useState(false);
  const handleOpenSplitBookingModal = () => {
    setopenSplitbookingModal(true);
  };
  const closeOpenSplitBookingModal = () => {
    setopenSplitbookingModal(false);
  };

  // open={openRepushModal}
  //       onClose={closeOpenRepushModal}
  const [openRepushModal, setOpenRepushModal] = useState(false);
  const handleOpenRepushModal = () => {
    setOpenRepushModal(true);
  };
  const closeOpenRepushModal = () => {
    setOpenRepushModal(false);
  };

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const handleOpenPreviewModal = () => {
    setOpenPreviewModal(true);
  };
  const closeOpenPreviewModal = () => {
    setOpenPreviewModal(false);
  };

  //Complimentary booking modal
  const [complimentaryModal, setComplimentaryModal] = useState(false);
  const openComplimentaryModal = () => {
    setComplimentaryModal(true);
  };
  const closeComplimentaryModal = () => {
    setComplimentaryModal(false);
  };

  const [primaryCustModal, setPrimaryCustModal] = useState(false);
  const openPrimaryCustModal = () => {
    setPrimaryCustModal(true);
  };
  const closePrimaryCustModal = () => {
    setPrimaryCustModal(false);
  };
  const [selectedPrimaryCust, setSelectedPrimaryCust] = useState("");

  // handling booking confirmation email loading
  const [magicLinkLoading, setMagicLinkLoading] = useState(false);

  // pre populating magic link modal fields
  const [magicLinkMail, setMagicLinkMail] = useState();
  const [magicLinkPhone, setMagicLinkPhone] = useState();
  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  function handleSendMagicLink() {
    if (!validateEmail(magicLinkMail)) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    if (magicLinkMail.includes("example.com")) {
      toast.error(`${t("Please enter valid email")}`);
      setMagicLinkLoading(false);
      return;
    }
    setMagicLinkLoading(true);
    if (magicLinkMail == "" || magicLinkPhone == "") {
      toast.error(
        `${t("Empty email or phone, please enter valid email and phone.")}`
      );
      setMagicLinkLoading(false);
      return;
    }
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/sendSelfServiceLinkCust/",
      {
        bookingId: bookingId,
        email: magicLinkMail,
        phoneNumber: magicLinkPhone,
      }
    )
      .then((res) => toast.success(`${t("Successfully sent magic link")}`))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseMagicLinkModal())
      .catch((err) => toast.error(err))
      .catch((err) => setMagicLinkLoading(false));
  }

  //Send link to collect guest info

  function handleSendLinkToCollectGuestInfo() {
    setMagicLinkLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/send-guest-info-updation-email",
      {
        bookingId: bookingId,
        emails: [],
      }
    )
      .then((res) => toast.success(`${t("Link sent successfully.")}`))
      .then((res) => setMagicLinkLoading(false))
      .then((res) => handleCloseCollectGuestInfo())
      .catch((err) => {
        toast.error(err);
        handleCloseCollectGuestInfo();
      })
      .catch((err) => setMagicLinkLoading(false));
  }

  //Copy Reservation

  function handleCopyReservationFlow() {
    setMagicLinkLoading(true);
    handleSpringDataRequest(
      `web-api/create-duplicate-reservation?bookingId=${bookingId}`
    )
      .then((res) => {
        setcopyBookingId(res?.bookingId);
        toast.success(
          `${t("Successfully copied reservation")} ${res?.bookingId}`
        );
      })
      .then((res) => setMagicLinkLoading(false))
      // .then((res) => handleCloseCopyReservation())
      .catch((err) => {
        toast.error(err.response.data);
        handleCloseCopyReservation();
      })
      .catch((err) => setMagicLinkLoading(false));
  }

  // Populating the sending links fields
  useEffect(() => {
    setBookingConfMail(invoiceData?.invoice.customer_email);
    setMailFolioId(invoiceData?.invoice.customer_email);
    setMagicLinkMail(invoiceData?.invoice.customer_email);
    setMagicLinkPhone(invoiceData?.invoice.customer_phone);
  }, [invoiceData, bookingId]);

  // Handling the customer identification name
  const handleCustomerId = (customerId) => {
    if (currency === "Rs.") {
      if (customerId === "null") {
        return `${t("Edit GST number")}`;
      } else if (customerId === "NA") {
        return `${t("Edit GST number")}`;
      } else if (customerId === "") {
        return `${t("Edit GST number")}`;
      } else {
        return customerId;
      }
    }
    if (customerId === "null") {
      return `${t("Edit customer identification")}`;
    } else if (customerId === "NA") {
      return `${t("Edit customer identification")}`;
    } else if (customerId === "") {
      return `${t("Edit customer identification")}`;
    } else {
      return customerId;
    }
  };

  //handle booking refernece id
  // Handling the customer identification name
  const handleBookingRefernceId = (customerId) => {
    if (customerId === "null") {
      return "Edit booking reference id";
    } else if (customerId === "NA") {
      return "Edit booking reference id";
    } else if (customerId === "") {
      return "Edit booking reference id";
    } else {
      return customerId;
    }
  };

  /* Bulk Edit Price */
  // Handle open bulk edit adv price
  const [openBulkEdit, setOpenBulkEdit] = useState(false);
  const handleOpenBulkEdit = () => setOpenBulkEdit(true);
  const handleCloseBulkEdit = () => setOpenBulkEdit(false);

  // Bulk edit loading
  const [bulkEditLoading, setBulkEditLoading] = useState(false);

  // Bulk edit price fields
  const checkinDate = moment(invoiceData?.invoice.checkin).toDate();
  const checkoutDate = moment(invoiceData?.invoice.checkout).toDate();

  const [datePicker, setDatePicker] = useState([
    {
      startDate: checkinDate,
      endDate: checkoutDate,
      key: "selection",
    },
  ]);
  const [roomId, setRoomId] = useState([]);
  // handle select all the room ids by deafult
  const handleSelectAllRoomIds = () => {
    let data = [];
    const temp = invoiceData?.invoice.room_ids_list.map(
      (item) => !data.includes(item) && data.push(item)
    );
    return setRoomId(data);
  };
  useEffect(() => {
    handleSelectAllRoomIds();
  }, [invoiceData]);

  // Status to check if given booking is a group booking or not
  const [roomBreakupModal, setRoomBreakupModal] = useState(false);
  const handleOpenRoomBreakupModal = () => setRoomBreakupModal(true);
  const handleCloseRoomBreakupModal = () => setRoomBreakupModal(false);
  const [groupVal, setGroupVal] = useState(false);
  useEffect(() => {
    if (invoiceData) {
      if (invoiceData?.invoice?.room_ids_list?.length > 1) {
        setGroupVal(true);
      }
    }
  }, [invoiceData]);

  const handleChangeRoomId = (e) => {
    setRoomId(e.target.value);
  };
  const [priceType, setPriceType] = useState("PRETAX");
  const handlePriceType = (e) => {
    setPriceType(e.target.value);
  };
  const [updatedPrice, setUpdatedPrice] = useState("");
  const handleUpdatedPrice = (e) => {
    setUpdatedPrice(e.target.value);
  };

  const handleDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
  };

  // Handling the bulk update price for the advanced folio
  const handleBulkUpdatePrice = () => {
    setBulkEditLoading(true);
    // handleTrueSummaryMockLoader();
    axios
      .post(
        `${process.env.REACT_APP_BG_DJANGO_URL}invoice/bulkEditAdvInvoiceRoomWithTaxAmount/`,
        {
          hotelId: hotelId,
          booking_id: bookingId,
          amount_type: priceType,
          amount: Number(updatedPrice),
          room_ids: roomId,
          daterange:
            handleDate(datePicker[0].startDate) +
            " - " +
            handleDate(datePicker[0].endDate),
          logged_user_email: loggedUserEmail,
        }
      )
      .then((res) => updateSummaryData())
      .then((res) => setBulkEditLoading(false))
      .then((res) => updateInvoice())
      .then((res) => getAdvancedData())
      .then((res) => toast.success(`${t("Price updated successfully.")}`))
      .then((res) => handleCloseBulkEdit())
      .catch((err) => toast.error(err))
      .then((res) => setBulkEditLoading(false))
      // .then((res) => handleFalseSummaryMockLoader())
      .then((res) => handleCloseBulkEdit());
    // handling the api to get all the guest Users
  };
  const [allGuestUsers, setallGuestUsers] = useState([]);
  useEffect(async () => {
    await getAllData();
  }, [bookingId]);

  const getAllData = async () => {
    try {
      const data = await getAllGuestUsers(bookingId);
      setallGuestUsers(data);
    } catch (e) {
      toast.error(e);
    }
  };

  /* PRINT RESERVATION */
  // Modal for printing Registration card
  // Giving an option to print normal Registration card or empty res card for hotels to fill out the details
  const [openResCard, setOpenResCard] = useState(false);
  const handleOpenResCard = () => setOpenResCard(true);
  const handleCloseResCard = () => setOpenResCard(false);
  // delete folio configuration
  const [openDeleteFolio, setOpenDeleteFolio] = useState(false);
  const [deleteFolioLoader, setDeleteFolioLoader] = useState(false);
  const [openTransferReservation, setOpenTransferReservation] = useState(false);

  const deleteFolio = () => {
    setDeleteFolioLoader(true);
    handleSpringDataRequest(
      `core/api/v1/reservation/delete-booking?bookingId=${bookingId}`
    )
      .then((msg) => {
        setDeleteFolioLoader(false);
        toast.success(msg);
        history.push(`/calendar?hotelId=${hotelId}`);
      })
      .catch((err) => {
        toast.error(err);
        setDeleteFolioLoader(false);
      });
  };

  // Checkbox to determine whether to print with details of fill up the details on own
  const [manualResCard, setManualResCard] = useState(false);
  const handleChangeManualResCard = (e) => {
    setManualResCard(e.target.checked);
  };

  //Split Bookings

  const splitBooking = (id) => {
    setsplitBookingloader(true);
    handleSpringDataRequest(
      `core/api/v1/reservation/splitBooking/?bookingId=${id}`
    )
      .then((res) => {
        if (res === "operation successfull") {
          toast.success(`${t("Successfully splitted booking")}`);
          invalidateNightAuditBooking(id);
        } else {
          toast.error(`${t("Something went wrong")}`);
        }
      })
      .catch((err) => toast.error(err));
    setsplitBookingloader(false);
    closeOpenSplitBookingModal();
  };

  const [splitBookingloader, setsplitBookingloader] = useState(false);

  const [reservationIds, setreservationIds] = useState(null);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/reservation/fetch-reservation-ids?bookingId=${bookingId}`,
      { "Content-Type": "application/json" }
    )
      .then((res) => {
        setreservationIds(res);
        setselectedRooms([Object.keys(res)[0]]);
      })
      .catch((err) => console.log(err));
  }, [bookingId]);

  const [donotRent, setdonotRent] = useState();
  useEffect(async () => {
    const data = await getAllCustomerNotes(invoiceData?.invoice.customer_email);
    const notesData = data.filter(function (item) {
      return item.do_not_rent === "YES";
    });
    setdonotRent(notesData);
  }, [bookingId]);

  //update Customer Note Modal
  const [updateNotesModal, setupdateNotesShow] = useState(false);
  const [UpdateRentLoader, setUpdateRentLoader] = useState(false);

  const handleNoteClose = () => setupdateNotesShow(false);
  const handleNoteShow = () => setupdateNotesShow(true);
  const updateDonotRent = async () => {
    setUpdateRentLoader(true);
    const payload = {
      emailId: invoiceData?.invoice.customer_email,
      status: "NO",
    };
    const data = await removeNewDoNotRentStatus(payload);
    if (data) {
      toast.success(data);
    } else {
      toast.error(`${t("Something went wrong")}`);
    }
    setUpdateRentLoader(false);
    await getAllCustomerNotes(invoiceData?.invoice.customer_email);

    updateInvoice();
    updateSummaryData();
    handleNoteClose();
  };

  // Getting the hotel logo
  useEffect(() => {
    summaryData && invoiceData && hotelLogo === null && handleBase64Img();
  }, [summaryData, invoiceData, hotelLogo, hotelId]);

  /* Download invoice having POS bill combined or not */
  const [invoiceDownload, setInvoiceDownload] = useState(false);
  const handleOpenSetInvoiceDownload = () => setInvoiceDownload(true);
  const handleCloseSetInvoiceDownload = () => setInvoiceDownload(false);

  // checkbox in case of having separate bills for invoice and POS
  const [checkPosBill, setCheckPosBill] = useState(false);
  const handleCheckPosBill = (e) => setCheckPosBill(e.target.checked);

  /* POS VALUES */
  const [posSubTotal, setPosSubTotal] = useState(0.0);
  const [posTotal, setPosTotal] = useState(0.0);
  const [posTotalTax, setPosTotalTax] = useState(0.0);

  // Calculate total pos subtotal amount
  const handlePosSubTotal = () => {
    let tempPosSubTotal = 0.0;
    const data = invoiceData?.invoice?.items.map(
      (item) =>
        item?.service_desc.includes("POS ORDER") &&
        (tempPosSubTotal += Number(item?.service_amount))
    );
    return setPosSubTotal(tempPosSubTotal.toFixed(2));
  };

  // Calculate total pos amount
  const handlePosTotal = () => {
    let tempPosTotal = 0.0;
    const data = invoiceData?.invoice?.items.map(
      (item) =>
        item?.service_desc.includes("POS ORDER") &&
        (tempPosTotal += Number(item?.service_total))
    );
    return setPosTotal(tempPosTotal.toFixed(2));
  };

  const handlePosTotalTax = () => {
    let tempPosTotalTax = 0.0;
    const data = invoiceData?.invoice?.items.map(
      (item) =>
        item?.service_desc.includes("POS ORDER") &&
        (tempPosTotalTax += Number(item?.service_tax))
    );
    return setPosTotalTax(tempPosTotalTax.toFixed(2));
  };

  const [allRatePlans, setallRatePlans] = useState([]);
  const [ratePlanLoader, setratePlanLoader] = useState(false);
  const [selectedRatePlan, setselectedRatePlan] = useState(
    invoiceData?.invoice?.rate_plan_id
  );
  const getAllRatePlans = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/getAllRatePlansToChangePrice?roomTypeId=${invoiceData?.invoice?.roomtype_id}`
    )
      .then((res) => setallRatePlans(Object.entries(res)))
      .catch((e) => toast.error("Something went wrong"));
  };

  useEffect(() => {
    if (openMealPlanModal) {
      getAllRatePlans();
    }
  }, [openMealPlanModal]);

  const [UpdateRateplanAMount, setUpdateRateplanAMount] = useState(
    invoiceData?.invoice?.booking_amount
  );

  useEffect(() => {
    setInitSegment(invoiceData?.invoice?.segment);
    setSegment(invoiceData?.invoice?.segment);
    setInitSubSegment(invoiceData?.invoice?.booking_source_displayname);
    setSubSegment(invoiceData?.invoice?.booking_source_displayname);
    setUpdateRateplanAMount(invoiceData?.invoice?.booking_amount);
    setselectedRatePlan(invoiceData?.invoice?.rate_plan_id);
    setreferenceId(invoiceData?.invoice?.booking_reference_id);
  }, [invoiceData]);

  const updatedRatePlanPrice = () => {
    const payload = {
      hotelId: hotelId,
      checkin: moment(invoiceData?.invoice?.actual_checkin).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
      checkout: moment(invoiceData?.invoice?.actual_checkout).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
      roomTypeId: invoiceData?.invoice?.roomtype_id,
      ratePlanId: selectedRatePlan,
      numAdults: parseInt(invoiceData?.invoice?.adults),
      numChildren: parseInt(invoiceData?.invoice?.children),
      newRate: null,
      newRateType: "TOTAL_PRETAX",
      ctaId: null,
    };
    setratePlanLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/getRatePlanChangePricePayload?existingRatePlanId=${invoiceData?.invoice?.rate_plan_id}&existingAmount=${invoiceData?.invoice?.booking_amount}`,
      payload
    )
      .then((res) => {
        if (Object.entries(res).length > 0) {
          setUpdateRateplanAMount(res?.priceAfterTax);
        } else {
          setUpdateRateplanAMount(0);
        }

        setratePlanLoader(false);
      })
      .catch((err) => toast.error(`${t("Something went wrong")}`));
  };

  useEffect(() => {
    updatedRatePlanPrice();
  }, [selectedRatePlan]);

  const saveRatePlanPrice = () => {
    setratePlanLoader(true);
    const payload = {
      hotelId: hotelId,
      bookingId: invoiceData?.invoice?.bookingid,
      newAmount: UpdateRateplanAMount,
      roomTypeId: invoiceData?.invoice?.roomtype_id,
      ratePlanId: selectedRatePlan,
      ratePlanName: allRatePlans.filter(
        (item, index) => item[0] === selectedRatePlan
      )[0][1].ratePlanName,
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/changeRatePlanAfterBookingIsMade`,
      payload
    )
      .then((res) => {
        // setUpdateRateplanAMount(res?.priceAfterTax);
        setratePlanLoader(false);
        toast.success(
          `${t("Successfully updated booking to rate")} ${
            allRatePlans.filter(
              (item, index) => item[0] === selectedRatePlan
            )[0][1].ratePlanName
          }`
        );
        updateInvoice();
        invalidateNightAuditBooking(invoiceData?.invoice?.bookingid);
        handleCloseMealPlanModal();
      })
      .then((res) => updateSummaryData())
      .catch((err) => {
        toast.error(`${t("Something went wrong")}`);
        setratePlanLoader(false);
      });
  };

  //Hotel add ons

  const [hotelAddons, sethotelAddons] = useState([]);
  const [selectedAddons, setselectedAddons] = useState([]);

  useEffect(async () => {
    const data = await getHotelAddons();
    let roomTypeIdList = [];
    if (invoiceData?.invoice?.roomtype_ids) {
      roomTypeIdList = invoiceData?.invoice?.roomtype_ids.split(",");
    }
    const selectedAddons = data.filter(function (el) {
      if (roomTypeIdList?.length > 1) {
        let isPresent = false;
        let dummyVar3 = el?.room_type_id.split(",").map((roomType) => {
          if (roomTypeIdList.includes(roomType)) {
            isPresent = true;
          }
        });
        return isPresent;
      } else if (invoiceData?.invoice?.roomtype_id) {
        return el.room_type_id.includes(invoiceData?.invoice?.roomtype_id);
      }
    });
    var todaysDate = moment(new Date()).unix();

    const arr = [];
    const datsasa = selectedAddons?.map((e, index) => {
      var startDateUnix = moment(
        selectedAddons[index].start_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      var endDateUnix = moment(
        selectedAddons[index].end_date,
        "DD-MM-YYYY HH:MM:SS"
      ).unix();
      if (todaysDate >= startDateUnix && todaysDate <= endDateUnix) {
        arr.push(e);
      }
    });

    sethotelAddons(arr);
  }, []);

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    setselectedAddons(typeof value === "string" ? value.split(",") : value);
  };

  const [selectedAddonsArray, setselectedAddonsArray] = useState([]);
  const [addonsProductArray, setaddonsProductArray] = useState([]);
  const [applyDate, setapplyDate] = useState(
    new Date(invoiceData?.invoice?.checkin)
  );

  // handling add new service
  function handleAddons() {
    if (addonsProductArray?.length > 0) {
      setAddNewServiceLoader(true);
      // for (let i = 0; i < addonsProductArray?.length; i++) {
      //   setAddNewServiceLoader(true);
      // djangoHandleDataMutationRequest(
      //   "POST",
      //   "invoice/addServiceToInvoice/",
      //   {
      //     hotelId: hotelId,
      //     booking_id: bookingId,
      //     reservation_id: selectedResId,
      //     service_item: {
      //       service_date: moment(serviceDate).format("MMM DD YYYY HH:mm:ss"),
      //       service_desc: `${addonsProductArray[i].name} Quantity : ${addonsProductArray[i].quantity}`,
      //       service_amount:
      //         parseFloat(addonsProductArray[i].priceWithoutTax) *
      //         parseFloat(addonsProductArray[i].quantity),
      //       service_amount_type: serviceAmtType,
      //       service_tax:
      //         addonsProductArray[i].priceWithTax -
      //         parseFloat(addonsProductArray[i].priceWithoutTax) *
      //           parseFloat(addonsProductArray[i].quantity),
      //       hsn_code: addonsProductArray[i]?.hsn_code
      //         ? addonsProductArray[i]?.hsn_code
      //         : "",
      //     },
      //     logged_user_email: loggedUserEmail,
      //   }
      // )
      //   .then((res) => updateSummaryData())
      //   .then((res) => updateInvoice())
      //   .then((res) =>
      //     toast.success(`${t("Successfully added new service")} ${bookingId}`)
      //   )
      //   .then((res) => setAddNewServiceLoader(false))
      //   // .then((res) => handleFalseSummaryMockLoader())
      //   // .then((res) => handleFalseWrapperMockLoader())
      //   .then((res) => handleCloseAddNewService())
      //   .then((res) => setServiceDate(new Date()))
      //   .then((res) => setServiceAmtType("DEBIT"))
      //   .then((res) => setServiceDesc(""))
      //   .then((res) => setServiceTax(0))
      //   .then((res) => setServiceAmt(""))
      //   .then((res) => setFinalServiceAmt(""))
      //   .then((res) => setServiceTaxCash(0))
      //   .catch((error) => toast.error(error))
      //   .then((res) => setAddNewServiceLoader(false))
      //   // .then((res) => handleFalseSummaryMockLoader())
      //   // .then((res) => handleFalseWrapperMockLoader())
      //   .then((res) => handleCloseAddNewService())
      //   .then((res) => setServiceDate(new Date()))
      //   .then((res) => setServiceAmtType("DEBIT"))
      //   .then((res) => setServiceDesc(""))
      //   .then((res) => setServiceTax(0))
      //   .then((res) => setServiceAmt(""))
      //   .then((res) => setFinalServiceAmt(""))
      //   .then((res) => setServiceTaxCash(0));
      // .then((res) => invalidateNightAuditBooking(bookingId));
      let checkin = moment(invoiceData?.invoice?.checkin).format(
        "DD-MM-yyyy HH:mm:ss"
      );
      let checkout = moment(invoiceData?.invoice?.checkout).format(
        "DD-MM-yyyy HH:mm:ss"
      );
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/hotelAddons/create-from-folio?bookingId=${bookingId}&checkin=${checkin}&checkout=${checkout}&applyDate=${moment(
          applyDate
        ).format("DD-MM-YYYY")}`,
        addonsProductArray
      )
        .then((res) => {
          toast.success("Successfully added addons");
          handleReloadBooking();
          setAddNewServiceLoader(false);
          // updateInvoice();
          // updateSummaryData();
          invalidateNightAuditBooking(bookingId);
          handleCloseAddNewService();
        })
        .catch((errr) => {
          toast.error("Something went wrong");
          setAddNewServiceLoader(false);
        });
      // }
    }
  }

  useEffect(() => {
    const arr = [];
    const selectedAddonDastas = [];
    if (selectedAddons.length > 0) {
      for (let i = 0; i < selectedAddons.length; i++) {
        const data = hotelAddons.filter((e) => e.id === selectedAddons[i]);
        arr.push(data[0]);
        const obj = {
          id: data[0].id,
          name: data[0]?.name,
          quantity: 1,
          priceWithoutTax: data[0].addon_value,
          taxPercentage: data[0].tax_percentage,
          priceWithTax:
            data[0].addon_value +
            data[0].addon_value * (data[0].tax_percentage / 100),
          description: data[0].description,
          hsn_code: data[0]?.hsn_code ? data[0]?.hsn_code : "",
        };
        selectedAddonDastas.push(obj);
      }
    }
    setselectedAddonsArray(arr);
    setaddonsProductArray(selectedAddonDastas);
  }, [selectedAddons]);

  const columns = [
    {
      dataField: "name",
      text: `${t("Name")}`,

      editable: false,
    },
    {
      dataField: "quantity",
      text: `${t("Quantity")}`,
    },
    {
      dataField: "priceWithoutTax",
      text: `${t("Price without tax")}`,
    },
    {
      dataField: "taxPercentage",
      text: `${t("Tax percentage")}`,

      editable: false,
    },
    {
      dataField: "priceWithTax",
      text: `${t("Price with tax")}`,

      editable: false,
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: `${t("Price should be numeric")}`,
          };
        }
        return true;
      },
    },
  ];

  const [selfCheckins, setselfCheckins] = useState([]);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/reservation/get-selfservice-details-folio/?bookingsId=${invoiceData?.invoice?.bookingid}`
    )
      .then((res) => {
        setselfCheckins(res?.details[0]?.selfServiceType);
      })
      .catch((err) =>
        toast.error(
          `${t("Error in getting selfCheckins! Please try again later.")}`
        )
      );
  }, []);

  const [fetchCurrentRate, setfetchCurrentRate] = useState(0);
  const updateDefaultRate = () => {
    const data = Currencies?.filter((e) => e.code === selectedCurrency);
    if (sessionStorage.getItem("currencyRate") === currencyRate) {
      sessionStorage.setItem("defaultRate", 0);
      sethotelConvertedCurrency(data.code);
      sessionStorage.setItem("convertedHotelCurrencySymbol", data.symbol);
      sessionStorage.setItem("convertedHotelCurrency", data.code);
      updateCurrency();
    } else {
      sessionStorage.setItem("convertedHotelCurrencySymbol", data[0].symbol);
      sessionStorage.setItem("defaultRate", currencyRate);
      sessionStorage.setItem("hotelConvertedCurrency", selectedCurrency);
    }

    updateCurrency();
    handleCloseCurrencyModalDefault();
  };

  useEffect(() => {
    if (
      hotelCurrency !== "" &&
      hotelCurrency !== undefined &&
      hotelCurrency !== null &&
      sessionStorage.getItem("convertedHotelCurrency") !== "" &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
      sessionStorage.getItem("convertedHotelCurrency") !== null
    ) {
      getConvertedvalue();
    }
  }, [sessionStorage.getItem("convertedHotelCurrency")]);

  //open Currenncy Modal

  const [openCurrencyModalDefault, setOpenCurrencyModalDefault] =
    useState(false);
  const handleOpenCurrencyModalDefault = () => {
    if (
      hotelCurrency !== "" &&
      hotelCurrency !== undefined &&
      hotelCurrency !== null &&
      sessionStorage.getItem("convertedHotelCurrency") !== "" &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
      sessionStorage.getItem("convertedHotelCurrency") !== null
    ) {
      getConvertedvalue();
    }
    setOpenCurrencyModalDefault(true);
  };
  const handleCloseCurrencyModalDefault = () =>
    setOpenCurrencyModalDefault(false);

  const [showHotelAddons, setshowHotelAddons] = useState("Add new service");
  // const [expanded, setExpanded] = useRef(false);
  const expanded = useRef(false);

  // expand card function
  const expandCard = () => {
    expanded.current = !expanded.current;
  };

  // get the image extension
  const getExtension = (img) => {
    if (img?.charAt(0) === "/") {
      return `data:image/jpeg;base64, ${img}`;
    } else if (img?.charAt(0) === "i") {
      return `data:image/png;base64, ${img}`;
    } else if (img?.charAt(0) === "U") {
      return `data:image/webp;base64, ${img}`;
    } else if (img?.charAt(0) === "R") {
      return `data:image/gif;base64, ${img}`;
    }
  };

  const rePushEreceipt = (bookingId) => {
    // bookingId
    setsplitBookingloader(true);
    handleSpringDataRequest(
      `web-api/isha/rePushVisitorDetailsToVMS/?bookingId=${bookingId}`
    )
      .then((res) => {
        toast.success(`${t("Successfully pushed guest record to VMS")}`);
        setsplitBookingloader(false);
        closeOpenRepushModal();
      })
      .catch((err) => {
        toast.error(`${t("Failed to push guest info to VMS")}`);
        setsplitBookingloader(false);
        closeOpenRepushModal();
      });
  };
  const [previewImageIsha, setpreviewImageIsha] = useState("");
  const [EreciptGenerate, setEreciptGenerate] = useState("false");
  const previewReceipt = () => {
    // bookingId
    setsplitBookingloader(true);
    handleSpringDataMutationRequest("POST", `web-api/create-rent-eReceipt`, {
      bookingId: bookingId,
      mode: "Cash_Payment",
      isPreviewReceipt: true,
    })
      .then((res) => {
        if (res?.status === "ok") {
          setpreviewImageIsha(res?.image);
          setEreciptGenerate("true");
          toast.success(`${t("Successfully generated")}`);
          setsplitBookingloader(false);
        } else if (res?.status === "error") {
          toast.error(res?.error);
          setsplitBookingloader(false);
        } else {
          toast.error(`${t("E-Receipt already generated")}`);
          setsplitBookingloader(false);
        }
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong")}`);
        setsplitBookingloader(false);
        // closeOpenPreviewModal();
      });
  };
  useEffect(() => {
    sessionStorage.setItem("EreciptGenerated", EreciptGenerate);
  }, [EreciptGenerate]);

  const history = useHistory();
  const openPreivewisha = () => {
    // window.open(`data:image/png;base64,${previewImageIsha}`, "_blank");
    history.push({
      pathname: "/isha-preview-e-receipt",
      state: { detail: previewImageIsha },
    });
  };
  useEffect(() => {
    sessionStorage.setItem("previewImageIsha", previewImageIsha);
  }, [previewImageIsha]);

  const addUpdateComplimentaryBooking = async () => {
    if (
      requestApprover !== "" &&
      requestContact !== "" &&
      requestDept !== "" &&
      requestName !== ""
    ) {
      setsplitBookingloader(true);
      const data = await updateComplimentaryBooking({
        bookingId: bookingId,
        compRemarks: "",
        compRequestApprove: requestApprover,
        compRequestContact: requestContact,
        compRequestDepartment: requestDept,
        compRequestName: requestName,
      })
        .then((res) => {
          toast.success(`${t("Successfully added complimentary information")}`);
          setsplitBookingloader(false);
          updateSummaryData();
          updateInvoice();
          closeComplimentaryModal();
        })
        .catch((err) => {
          toast.error(`${t("Failed to add complimentary information")}`);
          setsplitBookingloader(false);
          closeComplimentaryModal();
        });
    } else {
      toast.error(
        `${t("Please fill all details to mark booking complimentary")}`
      );
    }
  };

  useEffect(() => {
    if (
      invoiceData?.invoice?.eReceipt_map !== "NA" &&
      invoiceData?.invoice?.eReceipt_map !== undefined &&
      invoiceData?.invoice?.eReceipt_map !== null &&
      Object.entries(invoiceData?.invoice?.eReceipt_map).length > 0
    ) {
      setEreciptGenerate("true");
    }
  }, [invoiceData]);

  // Return room booking amount
  const roomBookingAmt = () =>
    invoiceData.invoice.items
      ?.filter((item) => {
        return item.service_id === "0" && item;
      })
      .map((val) => {
        return Number(val?.service_amount).toFixed(2);
      });

  // Return Room Booking Tax
  const roomBookingTax = () =>
    invoiceData.invoice.items
      ?.filter((item) => {
        return item.service_id === "0" && item;
      })
      .map((val) => {
        return val?.service_tax.toFixed(2);
      });

  // Getting the total for add ons / services
  const getTotalAddOnVal = (services) => {
    let totalAmt = 0;
    services.map((service) => {
      if (service.service_id !== "0") {
        totalAmt += parseInt(service.service_amount);
      }
    });
    return totalAmt.toFixed(2);
  };

  // Getting the total tax for add ons / services
  const getTotalTaxAddOnVal = (services) => {
    let totalTaxAmt = 0;
    services.map((service) => {
      if (service.service_id !== "0") {
        totalTaxAmt += parseFloat(service.service_tax);
      }
    });
    return parseFloat(totalTaxAmt).toFixed(2);
  };

  const [accssCode, setaccssCode] = useState(
    Object.entries(invoiceData?.invoice?.access_codes)
  );

  //Add Booking Note Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [customerShow, setCustomerShow] = useState(false);

  const handleCloseCustomer = () => setCustomerShow(false);
  const handleShowCustomer = () => setCustomerShow(true);

  //Booking Notes
  const [loading, setloading] = useState(false);
  const [bookingNoteData, setbookingNoteData] = useState("");

  const createBookingNote = async () => {
    setloading(true);
    const payload = {
      hotelId: hotelId,
      bookingID: bookingId,
      note: bookingNoteData,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewBookingNote(payload);
    setloading(false);
    if (data.message === "Booking note recorded") {
      toast.success(`${t("Successfully created the booking note")}`);
      setbookingNoteData("");
    } else {
      toast.error(`${t("Something went wrong !")}`);
      setbookingNoteData("");
    }
    await getAllBookingData();
    setbookingNoteData("");
    // handleCloseCustomer();
  };

  const getAllBookingData = async () => {
    try {
      const data = await getAllBookingNotes(bookingId);
      setBookingNotes(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAllBookingData();
  }, []);

  //Add Booking Note Modal
  const [showCustomerNotes, setShowCustomerNotes] = useState(false);

  const handleCloseCustomerNotes = () => setShowCustomerNotes(false);
  const handleShowCustomerNotes = () => setShowCustomerNotes(true);

  //Add Booking Note Modal
  const [showBookingNotes, setShowBookingNotes] = useState(false);

  const handleCloseBookingNotes = () => setShowBookingNotes(false);
  const handleShowBookingNotes = () => setShowBookingNotes(true);
  const deleteBookingNote = async (id, notes) => {
    const datas = await deleteTheBookingNotes(
      bookingId,
      id,
      localStorage.getItem("email"),
      notes
    );
    if (datas.message === "Booking note deleted") {
      toast.success(`${t("Successfully deleted the booking note")}`);
    } else {
      toast.error(`${t("Something went wrong !")}`);
    }
    getAllBookingData();
  };
  //Customer Notes
  const [customerNotes, setcustomerNotes] = useState([]);
  const [loader, setloader] = useState(false);
  const [customerNoteData, setcustomerNoteData] = useState("");

  useEffect(() => {
    getAllCustomerData();
  }, []);

  const getAllCustomerData = async () => {
    try {
      const data2 = await getAllCustomerNotes(
        invoiceData?.invoice.customer_email
      );
      setcustomerNotes(data2);
    } catch (e) {
      console.error(e);
    }
  };

  const [checkedes, setCheckedes] = useState(false);
  const createCustomerNote = async () => {
    setloading(true);
    const payload = {
      hotelId: hotelId,
      cust_email: mailFolioId,
      note: customerNoteData,
      do_not_rent: checkedes,
      bookingId: bookingId,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewCustomerNote(payload);
    setloading(false);
    if (data.message === "Customer note recorded") {
      toast.success(`${t("Successfully created the customer note")}`);
      setcustomerNoteData("");
    } else {
      toast.error(`${t("Something went wrong !")}`);
      setcustomerNoteData("");
    }
    setCheckedes(false);
    await getAllCustomerData();
    setcustomerNoteData("");
    // handleClose();
  };

  const deleteCustomerNote = async (id, notes) => {
    const data = await deleteTheCustomerNotes(
      mailFolioId,
      id,
      bookingId,
      localStorage.getItem("email"),
      notes
    );
    if (data.message === "Customer note deleted") {
      toast.success(`${t("Successfully deleted the customer note")}`);
    } else {
      toast.error(`${t("Something went wrong !")}`);
    }
    getAllCustomerData();
  };

  const [tags, settags] = useState({});
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/booking-tags/get-all?bookingId=${bookingId}`
    )
      .then((res) => settags(res[0]))
      .catch((err) => console.log(err));
  }, []);

  const [code, setcode] = useState("secretcode");

  const [PromocodeData, setPromocodeData] = useState();
  const [promoValue, setpromoValue] = useState("");
  const [promoPricingList, setpromoPricingList] = useState("");
  const [roomRequestedList, setRoomRequestedList] = useState([]);
  const [PromoValidation, setPromoValidation] = useState("");
  const [showPromoButton, setshowPromoButton] = useState(true);
  const [showDiscountButton, setshowDiscountButton] = useState(true);
  const [discountvalue, setdiscountvalue] = useState(0);
  const [dicountType, setdicountType] = useState("percent");
  const [discountAmount, setdiscountAmount] = useState(0);
  const [discountApplied, setdiscountApplied] = useState("");
  const [finalDiscountAmount, setfinalDiscountAmount] = useState(0);

  const getPricingLists = async () => {
    let roomsRequested = [];
    let arr = [];
    for (let i = 0; i < roomBreakupData?.length; i++) {
      const data = await getAllPromocodesPriceList(
        moment(invoiceData.invoice.checkin).format("DD-MM-YYYY HH:00:00"),
        moment(invoiceData.invoice.checkout).format("DD-MM-YYYY HH:00:00"),
        roomBreakupData[i]?.room_type_id,
        roomBreakupData[i]?.rate_plan_id
      );
      let roomData = roomBreakupData[i];
      roomsRequested.push({
        roomTypeId: roomData?.room_type_id,
        ratePlanId: roomData?.rate_plan_id,
        adultChildList: [
          {
            numAdults: roomData?.adults,
            numChildren: roomData?.children,
          },
        ],
        dayLevelPriceList: data,
      });
      arr.push(data);
    }
    setpromoPricingList(arr);
    setRoomRequestedList(roomsRequested);
    // const data = await getAllPromocodesPriceList(
    //   moment(invoiceData.invoice.checkin).format("DD-MM-YYYY HH:00:00"),
    //   moment(invoiceData.invoice.checkout).format("DD-MM-YYYY HH:00:00"),
    //   invoiceData.invoice?.roomtype_id,
    //   invoiceData.invoice?.rate_plan_id
    // );
    // setpromoPricingList(data);
  };

  useEffect(() => {
    getPricingLists();
    getPromoAllData();
  }, [invoiceData]);

  const checkIfTTDC = (email) => {
    if (email == "ttdcitchennai@gmail.com") {
      return true;
    } else {
      return false;
    }
  };

  const [paymentRefId, setPaymentRefId] = useState("");
  const handlePaymentLogData = () => {
    djangoHandleDataRequests(
      `invoice/bookingInvoicePaymentLogReport?hotelId=${hotelId}&bookingID=${bookingId}&paymentType=gateway`
    )
      .then((res) => {
        if (
          res?.invoice?.payment_data !== undefined &&
          res?.invoice?.payment_data?.pg_payments !== undefined &&
          res?.invoice?.payment_data?.pg_payments.length > 0
        ) {
          let payment_data = res?.invoice?.payment_data?.pg_payments[0];

          setPaymentRefId(payment_data?.pm_chargeid);
        }
      })
      /* .then((res) =>
        amplitude
          .getInstance()
          .logEvent(
            "USER VISITED VIEW AND MANAGE PAYMENT PAGE THROUGH FOLIO PAGE",
            amplitudeEventProperties
          )
      ) */
      .catch((error) =>
        console.log(`${t("Error in fetching payment log report.")}`)
      );
  };

  useEffect(() => {
    checkIfTTDC(data?.emailId) && handlePaymentLogData();
  }, []);

  const applyingPromo = async () => {
    setPromoValidation("");
    const selectedData = PromocodeData?.filter(
      (e) => e.promoCode === promoValue
    );
    setcopyPromocodeData(selectedData);
    const payload = {
      hotelId: hotelId,
      promoCode: promoValue,
      checkin: moment(invoiceData.invoice.checkin).format(
        "DD-MM-YYYY HH:00:00"
      ),
      checkout: moment(invoiceData.invoice.checkout).format(
        "DD-MM-YYYY HH:00:00"
      ),
      numRooms: roomRequestedList?.length,
      amountBeforeTax: summaryData?.invoice_subtotal_amount,
      taxAmount: summaryData?.invoice_total_tax_amount,
      percentage: selectedData[0]?.discount,
      roomsRequested: roomRequestedList,
    };

    const data = await checkPromoCodeQuickBook(payload);
    setPromoValidation(data);
    if (data.validPromo === true) {
      setshowPromoButton(false);
    }
  };

  const removePromo = () => {
    setshowPromoButton(true);
    setPromoValidation("");
    setpromoValue("");
  };

  const getPromoAllData = async () => {
    try {
      const data = await getAllPromocodes();
      const mydata = JSON.parse(data?.message);
      const filterData = mydata?.filter((e) => e.activeStatus === "ACTIVE");
      setPromocodeData(filterData);
    } catch (e) {
      console.error(e);
    }
  };

  const applyDiscount = () => {
    let booking_fees = invoiceData?.invoice?.total_fees
      ? Number(invoiceData?.invoice?.total_fees)
      : 0.0;
    if (
      (dicountType === "percent" && Number(discountAmount) > 100) ||
      (dicountType === "percent" && Number(discountAmount) < 0)
    ) {
      toast.error("Please enter value in between 0-100");
    } else if (
      (dicountType === "flat" &&
        Number(discountAmount) >
          parseFloat(
            summaryData?.total_room_amount - summaryData?.total_room_tax_amount
          ).toFixed(2)) ||
      (dicountType === "flat" && Number(discountAmount) < 0)
    ) {
      toast.error("Please enter correct value");
    } else {
      handleSpringDataRequest(
        `core/api/v1/reservation/apply-discount-with-amount-and-tax?amountBeforeTax=${
          summaryData?.invoice_subtotal_amount - booking_fees
        }&discountType=${dicountType.toUpperCase()}&discount=${discountAmount}&taxAmount=${
          summaryData?.invoice_total_tax_amount
        }`
      )
        .then((res) => {
          setfinalDiscountAmount(JSON.parse(res?.message));
          data = res?.message;
        })
        .catch((err) => toast.error(`${t("Something went wrong")}`));
      setshowDiscountButton(false);
      setdiscountApplied("true");
    }
  };

  const removeDiscount = () => {
    setdiscountAmount("");
    setshowDiscountButton(true);
    setdiscountApplied("");
  };

  const applyDiscountedAmount = () => {
    setAddNewServiceLoader(true);

    const amount = parseFloat(PromoValidation?.discountValue);
    const promoAMount =
      discountApplied === "" ? amount : finalDiscountAmount?.discountValue;

    const promoAply =
      discountApplied === ""
        ? code === "promocode"
          ? `${promoValue}|OFFER`
          : `${promoValue}|COUPONCODE`
        : "DISCOUNT|DISCOUNT";
    handleSpringDataRequest(
      `core/api/v1/reservation/apply-promo-code-post-booking?bookingId=${bookingId}&amount=${invoiceData?.invoice?.booking_amount}&promoAmount=${promoAMount}&appliedPromocode=${promoAply}`
    )
      .then((res) => {
        updateSummaryData();
        updateInvoice();
        invalidateNightAuditBooking(bookingId);
        setAddNewServiceLoader(false);
        toast.success(
          `${t("Successfully applied discount/offer")} ${bookingId}`
        );
      })
      .catch((err) => {
        toast.error(`${t("Error in applying the prices")}`);
        setAddNewServiceLoader(false);
      });
  };

  // Get the bill to preference
  const [billPreferenceModal, setBillPreferenceModal] = useState(false);
  function handleOpenBillPreferenceModal() {
    setBillPreferenceModal(true);
  }
  function handleCloseBillPreferenceModal() {
    setBillPreferenceModal(false);
    setBillToPref("GUEST");
  }

  // Company Details
  const [custCompanyDetails, setCustCompanyDetails] = useState(null);
  // On changing the bill to pref to company call this in
  // order to get the company details
  function handleGetCompanyDetails() {
    if (invoiceData?.invoice?.company_gst_number?.trim()?.length > 0) {
      handleSpringDataRequest(
        `core/api/v1/guest-company-details/get-by-gst?gstNumber=${invoiceData?.invoice?.company_gst_number?.trim()}`
      )
        .then((res) => setCustCompanyDetails(res))
        .catch((err) => toast.error(err));
    }
  }

  // const [billToPref, setBillToPref] = useState("GUEST");
  const [billToPref, setBillToPref] = useState("GUEST");
  const handleChangeBillToPref = (event) => {
    setBillToPref(event.target.value);
    if (event.target.value === "COMPANY") {
      handleGetCompanyDetails();
    }
  };

  // Rendering the folio pdf components
  function renderFolioPdfComponents(showRoomId) {
    let ifShowNewCancellationPolicy = true;

    if (invoiceData?.invoice?.hasOwnProperty("cancellationPolicies")) {
      for (let policy of invoiceData?.invoice?.cancellationPolicies) {
        const policyArr = policy.split(":");
        const policyDesc = policyArr[1].trim();
        if (policyDesc.length === 0) {
          ifShowNewCancellationPolicy = false;
          break;
        }
      }
    } else {
      ifShowNewCancellationPolicy = false;
    }
    const handleAdvanceInvoice = (bookingId) => {
      axios
        .get(
          `${springBaseUrl}core/api/v1/folio/get-adv-invoice?bookingId=${bookingId}`,
          {
            headers: {
              Accept: "application/pdf",
              Authorization: localStorage.getItem("token"),
            },
            responseType: "blob",
          }
        )
        .then(async (response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${bookingId}_advanced_folio.pdf`;
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {});
    };

    if (
      checked === "-1" &&
      (hotelType !== "ISHA" || hotelId === "24740") &&
      invoiceData &&
      summaryData &&
      customFolioConfig &&
      folioLogs
    ) {
      if (true) {
        return (
          <PrintInvoiceLoader
            api="get-invoice"
            bookingId={bookingId}
            billToPref={billToPref}
            currencyFrom={localStorage.getItem("hotelCurrency")}
            currencyTo={
              sessionStorage.getItem("convertedHotelCurrency") ||
              localStorage.getItem("hotelCurrency")
            }
            defaultRate={
              sessionStorage.getItem("defaultRate") ||
              sessionStorage.getItem("currencyRate") ||
              0.0
            }
            currencySymbol={
              sessionStorage.getItem("convertedHotelCurrencySymbol")
                ? sessionStorage.getItem("convertedHotelCurrencySymbol") === "₹"
                  ? "Rs."
                  : sessionStorage.getItem("convertedHotelCurrencySymbol")
                : currency === "₹"
                ? "Rs."
                : currency
            }
            showRoomId={showRoomId === false ? false : true}
          />
        );
      }

      return (
        <PDFDownloadLink
          document={
            <Document title={bookingId}>
              <Page size="A4" style={styles.page} wrap>
                <InvoiceDoc
                  hotelTax={hotelTax}
                  invoice={
                    taxExemptAccessControl &&
                    discountTaxExemptInvoiceData?.invoice
                      ? discountTaxExemptInvoiceData?.invoice
                      : invoiceData?.invoice
                  }
                  symbol={
                    isEditable
                      ? currency === "₹"
                        ? "Rs."
                        : currency
                      : sessionStorage.getItem(
                          "convertedHotelCurrencySymbol"
                        ) === "₹"
                      ? "Rs."
                      : sessionStorage.getItem("convertedHotelCurrencySymbol")
                  }
                  hotelLogo={hotelLogo}
                  hotelData={invoiceData?.hotel}
                  summaryData={
                    taxExemptAccessControl && discountTaxExemptSummaryData
                      ? discountTaxExemptSummaryData
                      : summaryData
                  }
                  customDetails={customFolioConfig}
                  customerIdentification={invoiceData?.invoice.identification}
                  selfCheckin={selfCheckins && selfCheckins}
                  groupHotelEmail={groupHotelEmail}
                  ctaDetails={
                    defaultCtadropdown?.filter(
                      (cta) =>
                        cta?.cta_name === invoiceData?.invoice?.booking_source
                    )[0]
                  }
                  billToPref={billToPref}
                  custCompanyDetails={
                    billToPref === "COMPANY" ? custCompanyDetails : []
                  }
                  ifShowNewCancellationPolicy={ifShowNewCancellationPolicy}
                  igst={igst}
                  dbigst={dbigst}
                  utgst={utgst}
                  customerState={customerState}
                  propertyState={propertyState}
                  selectedLanguage={selectedLanguage}
                  isTTDCProperty={checkIfTTDC(data?.emailId)}
                  paymentRefId={paymentRefId}
                  folioLogs={folioLogs}
                  hotelEmail={groupHotelEmail}
                  otaBookingInfoData={otaBookingInfoData}
                />
              </Page>
            </Document>
          }
          fileName={`${bookingId}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <p style={{ fontSize: "0.8rem" }}>Loading invoice...</p>
            ) : (
              <a
                href={url}
                rel={"noreferrer"}
                target="_blank"
                // download={`Folio_${bookingId}`}
              >
                {window.screen.width > 416 && (
                  <Tooltip title={t("Print & download invoice")}>
                    <IconButton
                      onClick={() => hotelLogo === null && handleBase64Img()}
                    >
                      <AiIcons.AiFillPrinter size="27.5px" color="black" />
                    </IconButton>
                  </Tooltip>
                )}
              </a>
            )
          }
        </PDFDownloadLink>
      );
    } else if (
      checked === "-2" &&
      invoiceData &&
      advancedInvoiceData &&
      folioLogs &&
      customFolioConfig
    ) {
      if (folioConfig?.invoice_config_type !== "FOLIO1") {
        return (
          <PrintInvoiceLoader
            api="get-adv-invoice"
            bookingId={bookingId}
            billToPref={billToPref}
            currencyFrom={localStorage.getItem("hotelCurrency")}
            currencyTo={
              sessionStorage.getItem("convertedHotelCurrency") ||
              localStorage.getItem("hotelCurrency")
            }
            defaultRate={
              sessionStorage.getItem("defaultRate") ||
              sessionStorage.getItem("currencyRate") ||
              0.0
            }
            currencySymbol={
              sessionStorage.getItem("convertedHotelCurrencySymbol")
                ? sessionStorage.getItem("convertedHotelCurrencySymbol") === "₹"
                  ? "Rs."
                  : sessionStorage.getItem("convertedHotelCurrencySymbol")
                : currency === "₹"
                ? "Rs."
                : currency
            }
            showRoomId={showRoomId === false ? false : true}
          />
        );
      }

      return (
        <PDFDownloadLink
          document={
            <Document title={bookingId}>
              {folioConfig?.invoice_config_type === "FOLIO1" ? (
                <Invoice
                  advInvoice={advancedInvoiceData?.invoice}
                  invoice={invoiceData.invoice}
                  symbol={currency === "₹" ? "Rs." : currency}
                  hotelData={invoiceData?.hotel}
                  customDetails={customFolioConfig}
                  userId={loggedUserEmail}
                  hotelLogo={hotelLogo}
                  summaryData={summaryData}
                  customerIdentification={invoiceData?.invoice.identification}
                  ctaDetails={
                    defaultCtadropdown?.filter(
                      (cta) =>
                        cta?.cta_name === invoiceData?.invoice?.booking_source
                    )[0]
                  }
                  billToPref={billToPref}
                  custCompanyDetails={
                    billToPref === "COMPANY" ? custCompanyDetails : []
                  }
                  ifShowNewCancellationPolicy={ifShowNewCancellationPolicy}
                  igst={igst}
                  dbigst={dbigst}
                  utgst={utgst}
                  customerState={customerState}
                  propertyState={propertyState}
                  selectedLanguage={selectedLanguage}
                  folioLogs={folioLogs}
                  hotelEmail={groupHotelEmail}
                />
              ) : (
                <Page size="A4" style={styles.page}>
                  <AdvInvoiceDoc
                    advInvoice={
                      taxExemptAccessControl &&
                      discountTaxExemptAdvancedInvoiceData?.invoice
                        ? discountTaxExemptAdvancedInvoiceData?.invoice
                        : advancedInvoiceData?.invoice
                    }
                    invoice={
                      taxExemptAccessControl &&
                      discountTaxExemptInvoiceData?.invoice
                        ? discountTaxExemptInvoiceData?.invoice
                        : invoiceData.invoice
                    }
                    symbol={currency === "₹" ? "Rs." : currency}
                    hotelData={invoiceData?.hotel}
                    customDetails={customFolioConfig}
                    userId={loggedUserEmail}
                    hotelLogo={hotelLogo}
                    summaryData={
                      taxExemptAccessControl && discountTaxExemptSummaryData
                        ? discountTaxExemptSummaryData
                        : summaryData
                    }
                    customerIdentification={invoiceData?.invoice.identification}
                    ctaDetails={
                      defaultCtadropdown?.filter(
                        (cta) =>
                          cta?.cta_name === invoiceData?.invoice?.booking_source
                      )[0]
                    }
                    billToPref={billToPref}
                    custCompanyDetails={
                      billToPref === "COMPANY" ? custCompanyDetails : []
                    }
                    ifShowNewCancellationPolicy={ifShowNewCancellationPolicy}
                    igst={igst}
                    dbigst={dbigst}
                    utgst={utgst}
                    customerState={customerState}
                    propertyState={propertyState}
                    selectedLanguage={selectedLanguage}
                    folioConfig={folioConfigs}
                    isTTDCProperty={checkIfTTDC(data?.emailId)}
                    paymentRefId={paymentRefId}
                    folioLogs={folioLogs}
                    hotelEmail={groupHotelEmail}
                    otaBookingInfoData={otaBookingInfoData}
                  />
                </Page>
              )}
            </Document>
          }
          fileName={`${bookingId}.pdf`}
        >
          {({ blob, url, loading, error }) => {
            console.log("errorrrr", error);
            return loading ? (
              <p style={{ fontSize: "0.8rem" }}>
                {t("Loading advanced invoice...")}
              </p>
            ) : (
              <a
                href={url}
                rel={"noreferrer"}
                target="_blank"
                // download={`Advanced_Folio_${bookingId}`}
              >
                {window.screen.width > 416 && (
                  <Tooltip title={t("Print & download advanced invoice")}>
                    <IconButton>
                      <AiIcons.AiFillPrinter size="27.5px" color="black" />
                    </IconButton>
                  </Tooltip>
                )}
              </a>
            );
          }}
        </PDFDownloadLink>
      );
    } else if (
      checked === "-3" &&
      hotelType !== "ISHA" &&
      invoiceData &&
      detailedInvData &&
      summaryData &&
      customFolioConfig
    ) {
      if (true) {
        return (
          <PrintInvoiceLoader
            api="get-detailed-invoice"
            bookingId={bookingId}
            billToPref={billToPref}
            currencyFrom={localStorage.getItem("hotelCurrency")}
            currencyTo={
              sessionStorage.getItem("convertedHotelCurrency") ||
              localStorage.getItem("hotelCurrency")
            }
            defaultRate={
              sessionStorage.getItem("defaultRate") ||
              sessionStorage.getItem("currencyRate") ||
              0.0
            }
            currencySymbol={
              sessionStorage.getItem("convertedHotelCurrencySymbol")
                ? sessionStorage.getItem("convertedHotelCurrencySymbol") === "₹"
                  ? "Rs."
                  : sessionStorage.getItem("convertedHotelCurrencySymbol")
                : currency === "₹"
                ? "Rs."
                : currency
            }
            showRoomId={showRoomId === false ? false : true}
          />
        );
      }
      return (
        <PDFDownloadLink
          document={
            <Document title={bookingId}>
              <Page size="A4" style={styles.page}>
                <DetailedInvDoc
                  detailedInvData={detailedInvData}
                  invoice={invoiceData.invoice}
                  symbol={currency === "₹" ? "Rs." : currency}
                  hotelData={invoiceData?.hotel}
                  customDetails={customFolioConfig}
                  userId={loggedUserEmail}
                  hotelLogo={hotelLogo}
                  summaryData={summaryData}
                  customerIdentification={invoiceData?.invoice.identification}
                  ctaDetails={
                    defaultCtadropdown?.filter(
                      (cta) =>
                        cta?.cta_name === invoiceData?.invoice?.booking_source
                    )[0]
                  }
                  billToPref={billToPref}
                  custCompanyDetails={
                    billToPref === "COMPANY" ? custCompanyDetails : []
                  }
                  ifShowNewCancellationPolicy={ifShowNewCancellationPolicy}
                  igst={igst}
                  dbigst={dbigst}
                  utgst={utgst}
                  customerState={customerState}
                  propertyState={propertyState}
                  selectedLanguage={selectedLanguage}
                  isTTDCProperty={checkIfTTDC(data?.emailId)}
                  paymentRefId={paymentRefId}
                  folioLogs={folioLogs}
                  hotelEmail={groupHotelEmail}
                  otaBookingInfoData={otaBookingInfoData}
                />
              </Page>
            </Document>
          }
          fileName={`${bookingId}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <p style={{ fontSize: "0.8rem" }}>
                {t("Loading advanced invoice itemized ...")}
              </p>
            ) : (
              <a href={url} rel={"noreferrer"} target="_blank">
                {window.screen.width > 416 && (
                  <Tooltip
                    title={t("Print & download Advanced invoice itemized")}
                  >
                    <IconButton>
                      <AiIcons.AiFillPrinter size="27.5px" color="black" />
                    </IconButton>
                  </Tooltip>
                )}
              </a>
            )
          }
        </PDFDownloadLink>
      );
    } else if (
      !["-1", "-2", "-3"].includes(checked) &&
      hotelType !== "ISHA" &&
      customInvoiceData &&
      customFolioSummary &&
      customFolioConfig
    ) {
      return (
        <PDFDownloadLink
          document={
            <Document title={bookingId}>
              <Page size="A4" style={styles.page} wrap>
                <CustomInvoiceDoc
                  invoice={invoiceData?.invoice}
                  items={customInvoiceData?.invoice?.items}
                  symbol={
                    isEditable
                      ? currency === "₹"
                        ? "Rs."
                        : currency
                      : sessionStorage.getItem(
                          "convertedHotelCurrencySymbol"
                        ) === "₹"
                      ? "Rs."
                      : sessionStorage.getItem("convertedHotelCurrencySymbol")
                  }
                  hotelLogo={hotelLogo}
                  hotelData={invoiceData?.hotel}
                  summaryData={customFolioSummary}
                  customDetails={customFolioConfig}
                  customerIdentification={invoiceData?.invoice.identification}
                  selfCheckin={selfCheckins && selfCheckins}
                  groupHotelEmail={groupHotelEmail}
                  ctaDetails={
                    defaultCtadropdown?.filter(
                      (cta) =>
                        cta?.cta_name === invoiceData?.invoice?.booking_source
                    )[0]
                  }
                  customInvoiceName={
                    customFolios?.filter(
                      (customFolio) => customFolio?.custom_folio_id === checked
                    )[0]?.custom_folio_name
                  }
                  billToPref={billToPref}
                  custCompanyDetails={
                    billToPref === "COMPANY" ? custCompanyDetails : []
                  }
                  ifShowNewCancellationPolicy={ifShowNewCancellationPolicy}
                  selectedLanguage={selectedLanguage}
                  folioLogs={folioLogs}
                  hotelEmail={groupHotelEmail}
                  otaBookingInfoData={otaBookingInfoData}
                />
              </Page>
            </Document>
          }
          fileName={`${bookingId}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <p style={{ fontSize: "0.8rem" }}>{t("Loading invoice...")}</p>
            ) : (
              <a
                href={url}
                rel={"noreferrer"}
                target="_blank"
                // download={`Folio_${bookingId}`}
              >
                {window.screen.width > 416 && (
                  <Tooltip title={t("Print & download invoice")}>
                    <IconButton
                      onClick={() => hotelLogo === null && handleBase64Img()}
                    >
                      <AiIcons.AiFillPrinter size="27.5px" color="black" />
                    </IconButton>
                  </Tooltip>
                )}
              </a>
            )
          }
        </PDFDownloadLink>
      );
    }
  }
  // ------------------------------
  const handleFormCDownloader = () => {
    const downloadUrl =
      "https://prop-images.s3.us-east-2.amazonaws.com/misc/C-Form-1.pdf";
    window.open(downloadUrl, "_blank");
  };

  const [waiveNoShowFee, setwaiveNoShowFee] = useState("no");

  const markBookingDotComNoShow = () => {
    if (voidTypeOption === "") {
      toast.error(`${t("Please select any value")}`);
    } else {
      const isApplyRefund = voidTypeOption === "APPLY_REFUND";
      setNoShowLoader(true);
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/set-booking-noshow?waivedFees=${waiveNoShowFee}&bookingId=${bookingId}&bookingSrc=${invoiceData?.invoice.booking_source}&isReservation=false&applyCancellationPolicy=${isApplyRefund}`,
          {}
        )
        .then((res) => {
          if (voidTypeOption !== "APPLY_REFUND") {
            handleVoidCharges();
          }
        })
        .then((res) =>
          toast.success(`${t("Successfully marked  NO SHOW for")} ${bookingId}`)
        )
        .then((res) => setNoShowLoader(false))
        .then((res) => handleCloseNoShow())
        .then((res) => handleCloseBookingNoShow())
        .then((res) => setVoidTypeOption(voidTypeOptions[0]))
        .then((res) => (isGrid ? fetchBookingData() : ""))
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .catch((err) =>
          toast.error(`${t("Error in changing the status to NO SHOW.")}`)
        )
        .then((res) => setNoShowLoader(false))
        .then((res) => handleCloseNoShow())
        .then((res) => setVoidTypeOption(voidTypeOptions[0]))
        .then((res) => updateInvoice())
        .then((res) => updateSummaryData())
        .then((res) => setwaiveNoShowFee("no"));
    }
  };

  const invalidateNightAuditBooking = (id) => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/invalidate-night-audit-tracker-booking?bookingId=${id}`
    )
      .then((res) => console.log())
      .catch((err) => console.error(err));
  };
  const invalidateNightAuditReservation = (id) => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/invalidate-night-audit-tracker-reservation?resId=${id}`
    )
      .then((res) => console.log())
      .catch((err) => console.error(err));
  };

  return (
    <div className="invoiceWrapper">
      <div className="hotelDetailsWrapper">
        <div className="hotelDetails">
          <div className="avatar">{hotelName[0]}</div>
          <div className="basicInfo">
            {invoiceData && (
              <div className="hotelName">
                <strong>{invoiceData && invoiceData.hotel.hotel_name}</strong>
              </div>
            )}
            {/* {invoiceData && (
              <div className="hotelAddress">{invoiceData.hotel.hotel_name}</div>
            )} */}
          </div>
          {hotelType !== "ISHA" && (
            <div className="toggleAdvanced">
              <Stack sx={{ marginTop: "auto", marginBottom: "auto" }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    {t("Current Invoice Mode")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={checked}
                    onChange={(e) => handleFolioType(e)}
                    label={t("Current Invoice Mode")}
                  >
                    <MenuItem value={"-1"}>
                      {hotelCurrency === "USD"
                        ? `${t("Summary folio")}`
                        : `${t("Summary invoice")}`}
                    </MenuItem>
                    {hotelType !== "ISHA" && (
                      <MenuItem value={"-2"}>
                        {hotelCurrency === "USD"
                          ? `${t("Advanced folio")}`
                          : `${t("Advanced invoice")}`}
                      </MenuItem>
                    )}
                    {hotelType !== "ISHA" && (
                      <MenuItem value={"-3"}>
                        {hotelCurrency === "USD"
                          ? `${t("Advanced folio itemized")}`
                          : `${t("Advanced invoice itemized")}`}
                      </MenuItem>
                    )}
                    {customFolios?.length > 0 &&
                      customFolios?.map(
                        (customFolio) =>
                          customFolio?.custom_folio_status?.toUpperCase() ===
                            "ACTIVE" && (
                            <MenuItem value={customFolio?.custom_folio_id}>
                              {customFolio?.custom_folio_name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                </FormControl>
              </Stack>
            </div>
          )}

          {hotelType !== "ISHA" && (
            <Tooltip title="Convert currency">
              <Stack
                sx={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: "25px",
                }}
              >
                <CurrencyExchangeIcon
                  onClick={() => handleOpenCurrencyModalDefault()}
                  sx={{
                    // "&:hover": {
                    //   backgroundColor: "#B5B5C3",
                    //   borderRadius: "50px",
                    // },
                    // marginLeft: "rem",
                    color: "#1BC5BD",
                    cursor: "pointer",
                  }}
                ></CurrencyExchangeIcon>
              </Stack>
            </Tooltip>
          )}
        </div>
        <div className="folioInfo">
          <div
            className="folioId"
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="folioId"
              // style={{ textDecoration: "underline" }}
              // onClick={
              //   invoiceData &&
              //   summaryData &&
              //   isEditable &&
              //   // toggleDrawer("right", true)
              // }
            >
              {invoiceHeader}# {bookingId}
            </div>
            {(invoiceData?.invoice.status === "CHECKED_OUT" ||
              invoiceData?.invoice?.status === "CANCELLED" ||
              invoiceData?.invoice?.status === "NO_SHOW") &&
              (Number(summaryData?.payment_made) > 0 ||
                handleGetBillToCta(invoiceData?.invoice, summaryData) > 0) &&
              invoiceData?.invoice?.custom_invoice_id !== "" && (
                <div
                  style={{ fontSize: "10px", fontWeight: 400, color: "grey" }}
                >
                  {checkIfTTDC(data?.emailId)
                    ? "TTDC Reference ID"
                    : t("Custom") + " " + invoiceHeader}
                  #{invoiceData?.invoice.custom_invoice_id}
                </div>
              )}
            {/* {invoiceData?.invoice.status === "CHECKED_OUT" &&
              (Number(summaryData?.payment_made) > 0 ||
                handleGetBillToCta(invoiceData?.invoice, summaryData) > 0) && (
                <div
                  style={{ fontSize: "10px", fontWeight: 400, color: "grey" }}
                >
                  Custom {invoiceHeader}#
                  {invoiceData?.invoice.custom_invoice_id}
                </div>
              )} */}
          </div>

          {isEditable && (
            <div className="folioActionWrapper" style={{ zIndex: 80 }}>
              <ClickAwayListenerHelper
                right={10}
                clickAwayButtonName={
                  <Tooltip title={t("Folio actions")}>
                    <IconButton>
                      <BsIcons.BsThreeDotsVertical size="15px" color="black" />
                    </IconButton>
                  </Tooltip>
                }
                btnClassName="folioActionBtn"
                content={
                  invoiceData.invoice.status === "CHECKED_OUT" &&
                  subUser.toLowerCase() === "subuser" &&
                  isNightAuditOn ? (
                    <div className="d-flex">
                      <div className="folioActions mr-4">
                        {invoiceData?.invoice?.is_enquiry === "false" && (
                          <div
                            className="item"
                            onClick={() => handleOpenResCard()}
                          >
                            {t("Print Registration card")}
                          </div>
                        )}

                        {hotelType !== "ISHA" &&
                          invoiceData?.invoice.status === "CHECKED_OUT" && (
                            <div
                              className="item"
                              onClick={() => handleOpenMailFolio()}
                            >
                              {t("Send checkout email")}
                            </div>
                          )}
                        {currency === "$" && (
                          <div className="item" onClick={() => handleOpenToc()}>
                            {t("Capture guest signature")}
                          </div>
                        )}
                        <div className="item" onClick={handleOpenFolioLog}>
                          {t("Folio log")}
                        </div>
                        {JSON.parse(
                          data?.accessControl?.user_feature_map?.DeleteFolio
                        ).read === "YES" && (
                          <div
                            className="item"
                            onClick={() => setOpenDeleteFolio(true)}
                          >
                            {"Delete folio"}
                          </div>
                        )}

                        {JSON.parse(
                          data?.accessControl?.user_feature_map
                            ?.TaxexemptonCheckOutBooking
                        ).read === "YES" && (
                          <div className="item" onClick={handleOpenTaxExempt}>
                            {" "}
                            {t("Tax exempt status")}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <div className="folioActions mr-4">
                        {invoiceData?.invoice?.is_enquiry === "false" && (
                          <div
                            className="item"
                            onClick={() => handleOpenResCard()}
                          >
                            {t("Print Registration card")}
                          </div>
                        )}
                        {
                          <div
                            className="item"
                            onClick={() => setOpenTransferReservation(true)}
                          >
                            {t("Transfer Reservation")}
                          </div>
                        }

                        {invoiceData?.invoice.cta_billed_items?.length > 0 && (
                          <div className="item">
                            <PDFDownloadLink
                              style={{ height: "100vh" }}
                              document={
                                <Document title={bookingId}>
                                  <Page size="A4" style={styles.page} wrap>
                                    <DirectBillFolioDoc
                                      invoice={invoiceData?.invoice}
                                      invoiceSummary={summaryData}
                                      symbol={
                                        currency === "₹" ? "Rs." : currency
                                      }
                                      hotelData={invoiceData?.hotel}
                                      customDetails={customPgData}
                                      hotelLogo={hotelLogo}
                                      selectedLanguage={selectedLanguage}
                                    />
                                  </Page>
                                </Document>
                              }
                              fileName={`${bookingId}.pdf`}
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  <p style={{ fontSize: "0.8rem" }}>
                                    {t("Loading document...")}
                                  </p>
                                ) : (
                                  <a
                                    href={url}
                                    style={{ fontSize: "0.8rem" }}
                                    rel={"noreferrer"}
                                    target="_blank"
                                  >
                                    {window.screen.width > 416 && (
                                      <div
                                        style={{
                                          color: "black",
                                          backgroundColor: "transparent",
                                          border: "none",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {t("Print direct bill folio")}
                                      </div>
                                    )}
                                  </a>
                                )
                              }
                            </PDFDownloadLink>
                          </div>
                        )}
                        {hotelCurr === "INR" &&
                          hotelType?.toUpperCase() !== "ISHA" &&
                          invoiceData?.invoice?.is_enquiry === "false" && (
                            <div
                              className="item"
                              onClick={() => handleFormCDownloader()}
                            >
                              {t("Print C form")}
                            </div>
                          )}

                        {invoiceData?.invoice?.status === "CONFIRMED" &&
                          hotelType !== "ISHA" && (
                            <div
                              className="item"
                              onClick={() => handleOpenBookingConf()}
                            >
                              {invoiceData?.invoice?.is_enquiry === "true"
                                ? `${t("Resend enquiry mail")}`
                                : `${t("Email booking confirmation")}`}
                            </div>
                          )}

                        {invoiceData?.invoice?.status === "ADMIN_CONFIRMED" &&
                          hotelType !== "ISHA" && (
                            <div
                              className="item"
                              onClick={() => handleOpenBookingConf()}
                            >
                              Email folio details
                            </div>
                          )}
                        {hotelType !== "ISHA" &&
                          invoiceData?.invoice?.is_enquiry !== "true" &&
                          invoiceData?.invoice.status === "CHECKED_OUT" && (
                            <div
                              className="item"
                              onClick={() => handleOpenMailFolio()}
                            >
                              {t("Send checkout email")}
                            </div>
                          )}
                        {currency === "$" && (
                          <div className="item" onClick={() => handleOpenToc()}>
                            {t("Capture guest signature")}
                          </div>
                        )}

                        {/* {invoiceData.invoice.status === "ADMIN_CONFIRMED" && (
                <div className="item" onClick={() => handleOpenNoShow()}>
                  Set booking no show
                </div>
              )} */}

                        {/* {invoiceData.invoice.status === "ADMIN_CONFIRMED" && (
                <div
                  className="item"
                  onClick={() => handleOpenRollbackBooking()}
                >
                  Rollback booking
                </div>
              )} */}

                        {/* { invoiceData.invoice.status === "CONFIRMED" && <div className="item" onClick={() => handleOpenRollbackBooking()}>Rollback booking</div> } */}
                        <div className="item" onClick={handleOpenFolioLog}>
                          {t("Folio log")}
                        </div>

                        {hotelType !== "ISHA" &&
                          invoiceData?.invoice?.status === "CONFIRMED" &&
                          invoiceData?.invoice?.is_enquiry !== "true" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.MagicLinkCommunication
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenMagicLinkModal()}
                            >
                              {t("Send magic link")}
                            </div>
                          )}
                        {hotelType === "ISHA" &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.EditRatePlan
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenMealPlanModal()}
                            >
                              {t("Edit rate plan")}
                            </div>
                          )}
                        {hotelType !== "ISHA" &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.EditRatePlan
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenMealPlanModal()}
                            >
                              {t("Edit rate plan")}
                            </div>
                          )}

                        {hotelType === "ISHA" &&
                          !(
                            invoiceData?.invoice?.eReceipt_map !== "NA" &&
                            invoiceData?.invoice?.eReceipt_map !== undefined &&
                            invoiceData?.invoice?.eReceipt_map !== null &&
                            Object.entries(invoiceData?.invoice?.eReceipt_map)
                              .length > 0
                          ) &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.SplitRoom
                          ).read === "YES" &&
                          invoiceData?.invoice?.confirmed_room_ids_list.length >
                            1 &&
                          summaryData?.balance_due > 0 && (
                            <div
                              className="item"
                              onClick={() => handleOpenSplitBookingModal()}
                            >
                              {t("Split bookings")}
                            </div>
                          )}

                        {hotelType !== "ISHA" &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          invoiceData?.invoice?.confirmed_room_ids_list.length >
                            1 &&
                          summaryData?.balance_due > 0 && (
                            <div
                              className="item"
                              onClick={() => handleOpenSplitBookingModal()}
                            >
                              {t("Split bookings")}
                            </div>
                          )}
                        {hotelType === "ISHA" &&
                          checkIshaAccess(data?.emailId) && (
                            <div
                              className="item"
                              onClick={() => handleOpenRepushModal()}
                            >
                              {t("Re-push guest info")}
                            </div>
                          )}
                        {hotelType === "ISHA" &&
                          checkIshaAccess(data?.emailId) && (
                            <div
                              className="item"
                              onClick={() => handleOpenPreviewModal()}
                            >
                              {t("Preview E-Receipt")}
                            </div>
                          )}
                        {hotelType === "ISHA" && (
                          <div
                            className="item"
                            onClick={() => openComplimentaryModal()}
                          >
                            {t("Complimentary booking")}
                          </div>
                        )}
                        {/* {hotelType === "ISHA" && (
                  <div
                    className="item"
                    onClick={() => openPrimaryCustModal()}
                  >
                    Set primary customer
                  </div>
                )} */}
                        {groupVal && (
                          <div
                            className="item"
                            onClick={() => handleOpenRoomBreakupModal()}
                          >
                            {t("Group booking master data")}
                          </div>
                        )}
                        {/* <div className="horizontalLine" /> */}

                        {/* <div

                  className="item"
                  onClick={() => handleOpenAddNewService()}
                >
                  Add new service
                </div> */}

                        {((hotelType === "ISHA" && hotelId === "12801") ||
                          hotelType !== "ISHA") &&
                          summaryData?.promo_tax === 0 &&
                          summaryData?.promo_amount !== undefined &&
                          summaryData?.promo_amount !== null &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.ApplyCouponCode
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenDiscount()}
                            >
                              {t("Apply Coupon code / Discount / Offer")}
                            </div>
                          )}
                        {((invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.AddHotelAddons
                          ).read === "YES") ||
                          isMasterUser) && (
                          <div
                            className="item"
                            onClick={() => handleOpenHotelAddon()}
                          >
                            {t("Add hotel addons")}
                          </div>
                        )}
                        {hotelType === "ISHA" &&
                          invoiceData.invoice.status === "CANCELLED" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.AddHotelAddons
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenHotelAddon()}
                            >
                              {t("Add hotel addons")}
                            </div>
                          )}

                        {/* <div className="item">Create POS order</div> */}
                        {/* <div className="horizontalLine" /> */}
                        {hotelType !== "ISHA" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          ((invoiceData.invoice.status !== "CHECKED_OUT" &&
                            JSON.parse(
                              data?.accessControl?.user_feature_map
                                ?.TaxExemptStatus
                            ).read === "YES") ||
                            (invoiceData.invoice.status === "CHECKED_OUT" &&
                              JSON.parse(
                                data?.accessControl?.user_feature_map
                                  ?.TaxexemptonCheckOutBooking
                              ).read === "YES")) && (
                            <div className="item" onClick={handleOpenTaxExempt}>
                              {" "}
                              {t("Tax exempt status")}{" "}
                            </div>
                          )}
                        {hotelType === "ISHA" && (
                          <div
                            className="item"
                            onClick={() => handleOpenCollectGuestInfo()}
                          >
                            {t("Send link to collect guest info")}
                          </div>
                        )}
                        {hotelType === "ISHA" &&
                          checkIshaAccess(data?.emailId) && (
                            <div
                              className="item"
                              onClick={() => handleOpenCopyReservation()}
                            >
                              {t("Copy Reservation")}
                            </div>
                          )}
                        {hotelCurr === "INR" &&
                          invoiceData?.invoice?.is_enquiry === "false" &&
                          invoiceData?.invoice?.segment !==
                            "DIRECT_BILLING" && (
                            <div
                              className="item"
                              onClick={() => setshowCompanyModal(true)}
                            >
                              {t("Add Company Details")}
                            </div>
                          )}

                        {/* {hotelType === "ISHA" &&
                    !(
                      invoiceData?.invoice?.eReceipt_map !== "NA" &&
                      invoiceData?.invoice?.eReceipt_map !== undefined &&
                      invoiceData?.invoice?.eReceipt_map !== null &&
                      Object.entries(invoiceData?.invoice?.eReceipt_map)
                        .length > 0
                    ) &&
                    data?.accessControl?.accessUser?.accessmap
                      ?.EditBookingPrice && (
                      <div className="item" onClick={handleOpenTaxExempt}>
                        {" "}
                        Tax exempt status{" "}
                      </div>
                    )} */}

                        {invoiceData.invoice.status === "CHECKED_OUT" &&
                          hotelType !== "ISHA" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "CHECKED_OUT" &&
                          hotelType === "ISHA" &&
                          !(
                            invoiceData?.invoice?.eReceipt_map !== "NA" &&
                            invoiceData?.invoice?.eReceipt_map !== undefined &&
                            invoiceData?.invoice?.eReceipt_map !== null &&
                            Object.entries(invoiceData?.invoice?.eReceipt_map)
                              .length > 0
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "CANCELLED" &&
                          hotelType !== "ISHA" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "CANCELLED" &&
                          hotelType === "ISHA" &&
                          !(
                            invoiceData?.invoice?.eReceipt_map !== "NA" &&
                            invoiceData?.invoice?.eReceipt_map !== undefined &&
                            invoiceData?.invoice?.eReceipt_map !== null &&
                            Object.entries(invoiceData?.invoice?.eReceipt_map)
                              .length > 0
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "ADMIN_CONFIRMED" &&
                          hotelType !== "ISHA" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "ADMIN_CONFIRMED" &&
                          hotelType === "ISHA" &&
                          !(
                            invoiceData?.invoice?.eReceipt_map !== "NA" &&
                            invoiceData?.invoice?.eReceipt_map !== undefined &&
                            invoiceData?.invoice?.eReceipt_map !== null &&
                            Object.entries(invoiceData?.invoice?.eReceipt_map)
                              .length > 0
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "NO_SHOW" &&
                          hotelType !== "ISHA" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "NO_SHOW" &&
                          hotelType === "ISHA" &&
                          !(
                            invoiceData?.invoice?.eReceipt_map !== "NA" &&
                            invoiceData?.invoice?.eReceipt_map !== undefined &&
                            invoiceData?.invoice?.eReceipt_map !== null &&
                            Object.entries(invoiceData?.invoice?.eReceipt_map)
                              .length > 0
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.RollbackBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenRollbackBooking()}
                            >
                              {t("Rollback booking")}
                            </div>
                          )}
                        {checked === "-2" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.EditBookingPrice
                          ).read === "YES" && (
                            <>
                              {/* <div className="horizontalLine" /> */}
                              <div
                                className="item"
                                onClick={handleOpenBulkEdit}
                              >
                                {t("Bulk edit price")}
                              </div>
                            </>
                          )}
                        {JSON.parse(
                          data?.accessControl?.user_feature_map?.DeleteFolio
                        ).read === "YES" && (
                          <div
                            className="item"
                            onClick={() => setOpenDeleteFolio(true)}
                          >
                            {"Delete folio"}
                          </div>
                        )}
                      </div>
                      <div className="folioActions ml-4">
                        {invoiceData.invoice.status === "CONFIRMED" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) && (
                            <div
                              className="item"
                              onClick={() => handleOpenHoldBooking()}
                            >
                              {t("Hold booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "CONFIRMED" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.CancelBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenCancelBooking()}
                            >
                              {t("Cancel booking")}
                            </div>
                          )}
                        {/* {invoiceData.invoice.status === "ADMIN_CONFIRMED" &&
                    JSON.parse(
                      data?.accessControl?.user_feature_map?.CancelBooking
                    ).read === "YES" && (
                      <div
                        className="item"
                        onClick={() => handleOpenCancelBooking()}
                      >
                        Cancel booking
                      </div>
                    )} */}
                        {invoiceData.invoice.status === "ON_HOLD" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.CancelBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenCancelBooking()}
                            >
                              {t("Cancel booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "NO_SHOW" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.CancelBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={() => handleOpenCancelBooking()}
                            >
                              {t("Cancel booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status === "CONFIRMED" &&
                          invoiceData?.invoice?.is_enquiry === "false" && (
                            <div
                              className="item"
                              onClick={() => handleOpenNoShow()}
                            >
                              {t("Set to no show")}
                            </div>
                          )}

                        {invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.LockAndUnlockBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={toggleLockBookingDrawer("right", true)}
                            >
                              {t("Lock booking")}
                            </div>
                          )}

                        {invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ADMIN_CONFIRMED" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.EditReservation
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={toggleEditBookingDrawer("right", true)}
                            >
                              {t("Edit reservation")}
                            </div>
                          )}

                        {invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.LockAndUnlockBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={toggleUnLockBookingDrawer("right", true)}
                            >
                              {t("Unlock booking")}
                            </div>
                          )}
                        {invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.UnassignRoom
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={toggleunAssignRoomDrawer("right", true)}
                            >
                              {t("Unassign room")}
                            </div>
                          )}
                        {invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" && (
                            <div
                              className="item"
                              onClick={toggleAssignRoomDrawer("right", true)}
                            >
                              {t("Assign Room")}
                            </div>
                          )}
                        {hotelType !== "ISHA" &&
                          invoiceData.invoice.status !== "ADMIN_CONFIRMED" &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.ModifyCheckIn
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={toggleCheckInDrawer("right", true)}
                            >
                              {t("Modify checkin")}
                            </div>
                          )}
                        {invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.ModifyCheckOut
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={toggleCheckOutDrawer("right", true)}
                            >
                              {t("Modify checkout")}
                            </div>
                          )}
                        {hotelType !== "ISHA" &&
                          ((subUser.toLowerCase() === "subuser" &&
                            hotelemail !==
                              "reservation@maharashtratourism.gov.in") ||
                            subUser.toLowerCase() === "admin") &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map?.SplitRoom
                          ).read === "YES" &&
                          invoiceData?.invoice?.is_enquiry === "false" &&
                          data?.slotType !== "PURE_HOURLY" &&
                          parseInt(invoiceData.invoice.num_nights) > 1 && (
                            <div
                              className="item"
                              onClick={toggleSplitRoomDrawer("right", true)}
                            >
                              {t("Split Room")}
                            </div>
                          )}

                        {/* REMOVE THIS CODE TO HERE */}
                        {((subUser.toLowerCase() === "subuser" &&
                          hotelemail !==
                            "reservation@maharashtratourism.gov.in") ||
                          subUser.toLowerCase() === "admin") &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) && (
                            <div
                              className="item"
                              onClick={toggleUpgradeRoomDrawer("right", true)}
                            >
                              {t("Move Room")}
                            </div>
                          )}

                        {JSON.parse(
                          data?.accessControl?.user_feature_map?.ScantyBagage
                        ).read === "YES" && (
                          <div
                            className="item"
                            onClick={() => handleOpenScantyModal()}
                          >
                            {t("Scanty Baggage")}
                          </div>
                        )}

                        {reservationIds &&
                          Object.keys(reservationIds).length > 0 &&
                          ((subUser.toLowerCase() === "subuser" &&
                            hotelemail !==
                              "reservation@maharashtratourism.gov.in") ||
                            subUser.toLowerCase() === "admin" ||
                            subUser.toLowerCase() === "subadmin") &&
                          invoiceData.invoice.status !== "CHECKED_OUT" &&
                          invoiceData.invoice.status !== "CANCELLED" &&
                          invoiceData.invoice.status !== "NO_SHOW" &&
                          invoiceData.invoice.status !== "ON_HOLD" &&
                          !invoiceData.invoice.room_ids_list.includes(
                            "UNASSIGNED"
                          ) &&
                          JSON.parse(
                            data?.accessControl?.user_feature_map
                              ?.AddNewRoomToGroupBooking
                          ).read === "YES" && (
                            <div
                              className="item"
                              onClick={toggleAddRoomToBookingDrawer(
                                "right",
                                true
                              )}
                            >
                              {t("Add new room to group booking")}
                            </div>
                          )}

                        {reservationIds &&
                          Object.keys(reservationIds).length > 1 &&
                          ((subUser.toLowerCase() === "subuser" &&
                            hotelemail !==
                              "reservation@maharashtratourism.gov.in") ||
                            subUser.toLowerCase() === "admin" ||
                            subUser.toLowerCase() === "subadmin") &&
                          summaryData?.payment_made <= 0 && (
                            <div
                              className="item"
                              onClick={() => handleOPenDeleteGroupRoomBooking()}
                            >
                              {t("Delete room from group booking")}
                            </div>
                          )}

                        <div
                          className="downloadFolio"
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {invoiceData?.invoice?.segment === "DIRECT_BILLING" ||
                          !["₹", "Rs", "INR", "Rs."].includes(currency) ||
                          invoiceData?.invoice?.company_gst_number?.trim()
                            ?.length === 0 ? (
                            renderFolioPdfComponents(false)
                          ) : (
                            <></>
                          )}
                        </div>

                        {/* <div
                    className="item"
                    onClick={toggleAssignRoomDrawer("right", true)}
                  >
                    Assign Room
                  </div> */}
                      </div>
                    </div>
                  )
                }

                // right="22.5px"
              />
            </div>
          )}
          <div className="reloadBooking">
            {checked === "-1" && isEditable && (
              <Tooltip title={t("Reload invoice")}>
                <IconButton>
                  {!reloadInvoice ? (
                    <AiIcons.AiOutlineReload
                      size="25px"
                      color="black"
                      onClick={() => handleReloadBooking()}
                    />
                  ) : (
                    <CircularProgress size="25px" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {checked === "-2" && isEditable && (
              <Tooltip title={t("Reload advanced invoice")}>
                <IconButton>
                  {!reloadInvoice ? (
                    <AiIcons.AiOutlineReload
                      size="25px"
                      color="black"
                      onClick={() => handleReloadBooking()}
                    />
                  ) : (
                    <CircularProgress size="25px" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {checked === "-3" && isEditable && (
              <Tooltip title={t("Reload itemized invoice")}>
                <IconButton>
                  {!reloadInvoice ? (
                    <AiIcons.AiOutlineReload
                      size="25px"
                      color="black"
                      onClick={() => handleReloadBooking()}
                    />
                  ) : (
                    <CircularProgress size="25px" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {!["-1", "-2", "-3"].includes(checked) && isEditable && (
              <Tooltip title={t("Reload custom invoice")}>
                <IconButton>
                  {!reloadInvoice ? (
                    <AiIcons.AiOutlineReload
                      size="25px"
                      color="black"
                      onClick={() => handleReloadBooking()}
                    />
                  ) : (
                    <CircularProgress size="25px" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div
            className="downloadFolio"
            style={{
              marginLeft: "12px",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            {invoiceData?.invoice?.segment === "DIRECT_BILLING" ||
            !["₹", "Rs", "INR", "Rs."].includes(currency) ||
            invoiceData?.invoice?.company_gst_number?.trim()?.length === 0 ? (
              renderFolioPdfComponents()
            ) : (
              <Tooltip title={t("Print & download invoice")}>
                <IconButton
                  onClick={() => {
                    hotelLogo === null && handleBase64Img();
                    handleOpenBillPreferenceModal();
                  }}
                >
                  <AiIcons.AiFillPrinter size="27.5px" color="black" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="horizontalLine" />
      <div className="customerDetailsWrapper">
        <Accordion ref={expanded}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{ height: "35px", width: "auto" }}
                onClick={() => expandCard()}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="basicCustInfo">
              <div className="custDetailHeader" style={{ marginTop: "5px" }}>
                {t("Bill to :")}{" "}
              </div>
              <div className="nameSource">
                <div className="custName d-flex" style={{ marginTop: "5px" }}>
                  <div
                    className="custName"
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={
                      isEditable &&
                      toggleDrawer(
                        "right",
                        true,
                        invoiceData?.invoice.customer_email
                      )
                    }
                  >
                    {" "}
                    {invoiceData?.invoice.customer_name}
                  </div>
                  <ClickAwayListenerHelper
                    clickAwayButtonName={t("Guest list")}
                    btnClassName="ctaDropdownBtns"
                    content={
                      <div className="editCustIdentification">
                        {allGuestUsers?.map((item, idx) => (
                          <div
                            className="ctaNames"
                            onClick={toggleGuestDrawer(
                              "right",
                              true,
                              item.email,
                              item.guest_status,
                              item.reservationId
                            )}
                          >
                            <Stack
                              direction={"row"}
                              spacing={2}
                              // style={{ display: "flex", justifyContent: "space-between" }}
                            >
                              <Typography sx={{ color: "black" }}>
                                {item?.name}
                              </Typography>

                              {JSON.parse(
                                data?.accessControl?.user_feature_map
                                  ?.GuestCheckInCheckOut
                              ).read === "YES" && (
                                <Typography
                                  sx={{
                                    color: `${
                                      item.guest_status === "ADMIN_CONFIRMED"
                                        ? "#96C7C1"
                                        : item.guest_status === "CONFIRMED"
                                        ? "#F7D59C"
                                        : "#A3AECC"
                                    }`,
                                  }}
                                >
                                  {item.guest_status === "ADMIN_CONFIRMED"
                                    ? "CHECKED_IN"
                                    : item.guest_status}
                                </Typography>
                              )}
                            </Stack>
                          </div>
                        ))}
                      </div>
                    }
                  />
                  <Tooltip title={`${t("Add guest")}`} className="mb-0">
                    <AddIcon
                      onClick={
                        isEditable && toggleGuestDrawer("right", true, "test")
                      }
                      style={{ marginTop: "4px", marginRight: "1rem" }}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#B5B5C3",
                          borderRadius: "50px",
                        },
                      }}
                    ></AddIcon>
                  </Tooltip>{" "}
                </div>
                <div className="sourceNameWrapper">
                  {
                    handleGetBillToCta() > 0 ? (
                      <div className="ctaDropdownBtns">{initsegment}</div>
                    ) : (
                      mostMajorSource &&
                      mostMajorSource.length > 0 && (
                        <ClickAwayListenerHelper
                          clickAwayButtonName={
                            <Stack direction="row" spacing={5}>
                              <div
                                className="sourceName"
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                {initsegment}
                              </div>
                              <div
                                className="dropDownIcon"
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              >
                                <IoIcons.IoMdArrowDropdown />
                              </div>
                            </Stack>
                          }
                          btnClassName="ctaDropdownBtns"
                          content={
                            isEditable && (
                              <div className="editCustIdentification">
                                <div
                                  className="editCtaHeader"
                                  style={{
                                    borderBottom: "1px solid #DDDDDD",
                                    paddingBottom: "2.5px",
                                  }}
                                >
                                  {t("Change your booking segment to :")}
                                </div>
                                {
                                  mostMajorSource &&
                                    mostMajorSource.map((res) => {
                                      // if (mostSecondarySource[res]?.length > 0) {
                                      return (
                                        <div
                                          className="ctaNames"
                                          onClick={() =>
                                            handleSegmentChange(res)
                                          }
                                        >
                                          {res}
                                        </div>
                                      );
                                      // }
                                    })
                                  // ctaDropdown?.map((item, idx) => (
                                  //   <div
                                  //     className="ctaNames"
                                  //     onClick={() => updateSource(item.ctaName)}
                                  //   >
                                  //     {item.ctaName}
                                  //   </div>
                                  // ))
                                }
                              </div>
                            )
                          }
                        />
                      )
                    )
                    // : (
                    //   <div
                    //   // className="ctaDropdownBtns"
                    //   >
                    //     {initsegment}
                    //   </div>
                    // )
                  }
                </div>
                <div className="sourceNameWrapper">
                  {subSegmentArr && subSegmentArr.length > 0 ? (
                    <ClickAwayListenerHelper
                      clickAwayButtonName={
                        <Stack direction="row" spacing={5}>
                          <div
                            className="sourceName"
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            {initsubSegment}
                          </div>
                          <div
                            className="dropDownIcon"
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                          >
                            <IoIcons.IoMdArrowDropdown />
                          </div>
                        </Stack>
                      }
                      btnClassName="ctaDropdownBtns"
                      content={
                        isEditable && (
                          <div className="editCustIdentification">
                            <div
                              className="editCtaHeader"
                              style={{
                                borderBottom: "1px solid #DDDDDD",
                                paddingBottom: "2.5px",
                              }}
                            >
                              {t("Change your booking source to :")}
                            </div>

                            {/* {subSegmentArr &&
                            subSegmentArr.map((res) => {
                              if (typeof res === "object") {
                                return (
                                  <MenuItem value={res.cta_name}>
                                    {res.cta_name}
                                  </MenuItem>
                                );
                              }
                              return <MenuItem value={res}>{res}</MenuItem>;
                            })} */}
                            {subSegmentArr &&
                              subSegmentArr.map((res) => {
                                if (typeof res === "object") {
                                  return (
                                    <div
                                      className="ctaNames"
                                      onClick={() =>
                                        handleSubSegmentChange(res.cta_name)
                                      }
                                    >
                                      {res.cta_name}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="ctaNames"
                                      onClick={() =>
                                        handleSubSegmentChange(res)
                                      }
                                    >
                                      {res}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        )
                      }
                    />
                  ) : (
                    <div
                      style={{ marginLeft: "5px" }}
                      // className="ctaDropdownBtns"
                    >
                      {initsubSegment}
                    </div>
                  )}
                </div>
                {/* <div className="sourceNameWrapper">
              {Number(summaryData?.bill_to_cta_amt) <= 0 &&
              ctaDropdown.length > 0 ? (
                <ClickAwayListenerHelper
                  clickAwayButtonName={
                    <Stack direction="row" spacing={5}>
                      <div
                        className="sourceName"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        {invoiceData.invoice.booking_source_displayname}
                      </div>
                      <div
                        className="dropDownIcon"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        <IoIcons.IoMdArrowDropdown />
                      </div>
                    </Stack>
                  }
                  btnClassName="ctaDropdownBtns"
                  content={

                    hotelCurrency === hotelConvertedCurrency && (
                      <div className="editCustIdentification">
                        <div
                          className="editCtaHeader"
                          style={{
                            borderBottom: "1px solid #DDDDDD",
                            paddingBottom: "2.5px",
                          }}
                        >
                          Change your booking source to :
                        </div>
                        {ctaDropdown?.map((item, idx) => (
                          <div
                            className="ctaNames"
                            onClick={() => updateSource(item.ctaName)}
                          >
                            {item.ctaName}
                          </div>
                        ))}
                      </div>
                    )
                  }
                />
              ) : (
                <div
                // className="ctaDropdownBtns"
                >
                  {invoiceData.invoice.booking_source_displayname}
                </div>
              )}
            </div> */}
              </div>
              <div
                className="bookingStatus"
                style={{
                  backgroundColor: `${
                    invoiceData?.invoice?.is_enquiry === "true"
                      ? "#7F7D9C"
                      : bookStatusBgColor
                  }`,
                }}
              >
                {invoiceData?.invoice?.is_enquiry === "true"
                  ? invoiceData.invoice.status === "CONFIRMED"
                    ? `${t("PENDING ENQUIRY")}`
                    : invoiceData.invoice.status === "CANCELLED"
                    ? `${t("CANCELLED ENQUIRY")}`
                    : `${t("ENQUIRY")} ${invoiceData.invoice.status}`
                  : displayBookingName === ""
                  ? `${t("PENDING")}`
                  : displayBookingName}
              </div>
              {invoiceData?.invoice?.complimentary_booking && (
                <div
                  className="bookingStatus"
                  style={{
                    backgroundColor: `${"#F3AB59"}`,
                  }}
                >
                  {t("COMPLIMENTARY")}
                </div>
              )}
              {markedScantybaggage === "YES" && (
                <LuggageIcon
                  style={{
                    height: "2rem",
                    width: "2rem",
                    color: "red",
                    marginTop: "6px",
                    marginLeft: "10px",
                  }}
                  color="red"
                />
              )}
              {doNotRent && doNotRent?.do_not_rent === "YES" ? (
                <div
                  className="bookingStatus"
                  style={{ backgroundColor: "red", cursor: "pointer" }}
                  onClick={() => handleNoteShow()}
                >
                  {t("Do not rent")}
                </div>
              ) : (
                <></>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="customerDetail">
              <div className="details">
                <Stack spacing={2}>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">{t("Address")}</div>
                      {invoiceData?.invoice?.customer_address
                        ?.customer_address_line1 &&
                      invoiceData?.invoice?.customer_address
                        ?.customer_address_line1?.length > 0 ? (
                        <div className="custSubVal">
                          {handleDisplayAddress(
                            invoiceData.invoice.customer_address
                              ?.customer_address_line1
                          )}
                          <br />
                          {handleDisplayAddress(
                            invoiceData.invoice.customer_address
                              ?.customer_address_line2
                          )}
                          {handleDisplayAddress(
                            invoiceData.invoice.customer_address
                              ?.customer_address_city
                          )}{" "}
                          {handleDisplayAddress(
                            invoiceData.invoice.customer_address
                              ?.customer_address_state
                          )}
                          <br />
                          {handleDisplayAddress(
                            invoiceData.invoice.customer_address
                              ?.customer_address_country
                          )}
                          {handleDisplayCode(
                            invoiceData.invoice.customer_address
                              ?.customer_address_zip
                          )}
                        </div>
                      ) : (
                        <div className="custSubVal">
                          <i>{t("Not available")}</i>
                        </div>
                      )}
                    </Stack>
                  </div>
                  {((subUser?.toLowerCase() === "subuser" &&
                    JSON.parse(
                      data?.accessControl?.user_feature_map
                        ?.ResGridCustomerEmail
                    ).read !== "YES") ||
                    subUser?.toLowerCase() === "admin" ||
                    subUser?.toLowerCase() === "subadmin") && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Email")}</div>
                        {invoiceData && (
                          <div className="custSubVal">
                            {invoiceData.invoice.customer_email}
                          </div>
                        )}
                      </Stack>
                    </div>
                  )}
                  {((subUser?.toLowerCase() === "subuser" &&
                    JSON.parse(
                      data?.accessControl?.user_feature_map
                        ?.ResGridCustomerPhone
                    ).read !== "YES") ||
                    subUser?.toLowerCase() === "admin" ||
                    subUser?.toLowerCase() === "subadmin") &&
                    invoiceData.invoice.customer_phone !== "0000000000" && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">{t("Phone")}</div>
                          {invoiceData && (
                            <div className="custSubVal">
                              {invoiceData.invoice.customer_phone}
                            </div>
                          )}
                        </Stack>
                      </div>
                    )}
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">
                        {currency === "Rs."
                          ? `${t("GST Number")}`
                          : `${t("Customer identification")}`}
                      </div>
                      {/* <div className="custSubVal">null</div> */}
                      <ClickAwayListenerHelper
                        clickAwayButtonName={handleCustomerId(custField)}
                        btnClassName="editCustIdBtn"
                        content={
                          isEditable && (
                            <div className="editCustIdentification">
                              <input
                                className="editField"
                                value={updatedCustField}
                                onChange={(e) => handleCustField(e)}
                                placeholder={`Enter ${
                                  currency === "Rs."
                                    ? `${t("GST Number")}`
                                    : `${t("Customer identification")}`
                                }`}
                              />
                              <div className="updateBtns">
                                {custFieldLoader === false ? (
                                  <button
                                    className="updateId"
                                    onClick={() => handleUpdateField()}
                                  >
                                    {t("Update")}
                                  </button>
                                ) : (
                                  <button className="updateId">
                                    <CircularProgress size="15px" />
                                  </button>
                                )}
                              </div>
                            </div>
                          )
                        }
                      />
                    </Stack>
                  </div>

                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">
                        {" "}
                        {t("Booking reference id")}
                      </div>
                      {/* <div className="custSubVal">null</div> */}
                      <ClickAwayListenerHelper
                        clickAwayButtonName={handleBookingRefernceId(
                          referenceId
                        )}
                        btnClassName="editCustIdBtn"
                        content={
                          isEditable && (
                            <div className="editCustIdentification">
                              <input
                                className="editField"
                                value={referenceId}
                                onChange={(e) => setreferenceId(e.target.value)}
                                placeholder={"Enter booking reference id"}
                              />
                              <div className="updateBtns">
                                {custFieldLoader === false ? (
                                  <button
                                    className="updateId"
                                    onClick={() => handleBookingReferenceId()}
                                  >
                                    {t("Update")}
                                  </button>
                                ) : (
                                  <button className="updateId">
                                    <CircularProgress size="15px" />
                                  </button>
                                )}
                              </div>
                            </div>
                          )
                        }
                      />
                    </Stack>
                  </div>
                  {!invoiceData.invoice.booking_source_displayname.startsWith(
                    "Walk-In"
                  ) &&
                    ctaDropdown.length > 0 && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">
                            {/* CTA tax identification */}
                            {t("Direct billing tax id")}
                          </div>
                          {invoiceData && (
                            <div className="custSubVal">
                              {
                                ctaDropdown
                                  .filter((item) => {
                                    if (
                                      invoiceData.invoice
                                        .booking_source_displayname ===
                                      item.cta_name
                                    ) {
                                      return item;
                                    }
                                  })
                                  .map((item) => item.cta_tax_id_no)[0]
                              }
                            </div>
                          )}
                        </Stack>
                      </div>
                    )}

                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">{t("Room type")}</div>
                      {invoiceData && (
                        <div className="custSubVal">
                          {invoiceData?.invoice.reservation_room_info_list.map(
                            (item) => {
                              return item.roomTypeName;
                            }
                          )}
                        </div>
                      )}
                    </Stack>
                  </div>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading"> {t("Rate plans")}</div>
                      {invoiceData && (
                        <div className="custSubVal">
                          {invoiceData?.invoice.rate_plan_names}
                        </div>
                      )}
                    </Stack>
                  </div>
                  {/* <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">Room booking amount</div>

                      <div className="custSubVal">
                        {currency} {roomBookingAmt()}
                      </div>
                    </Stack>
                  </div> */}
                  {/* <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">Room booking tax</div>

                      <div className="custSubVal">
                        {currency} {roomBookingTax()}
                      </div>
                    </Stack>
                  </div> */}
                  {/* 
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">
                        Final amount with tax
                      </div>

                      <div className="custSubVal">
                        {currency} {summaryData?.total_room_amount}
                      </div>
                    </Stack>
                  </div> */}

                  {/* <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">Total tax</div>

                      <div className="custSubVal">
                        {currency} {summaryData?.total_tax_amount?.toFixed(2)}
                      </div>
                    </Stack>
                  </div> */}
                  {invoiceData?.invoice.is_enquiry === "true" && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">
                          {t("Enquiry expires at")}
                        </div>

                        <div className="custSubVal">
                          {moment(
                            invoiceData?.invoice?.enquiry_expiration_time
                          ).format("MMM D YYYY, h:mm:ss a")}
                        </div>
                      </Stack>
                    </div>
                  )}

                  {hotelType !== "ISHA" && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Access code")}</div>

                        <div className="custSubVal">
                          {accssCode?.map((item) => (
                            <p
                              className="d-flex mb-0 "
                              style={{ fontSize: "15px", color: "black" }}
                            >{` ${item[0]} - ${
                              item[1] === "" ? "NA " : item[1]
                            } , `}</p>
                          ))}
                        </div>
                      </Stack>
                    </div>
                  )}

                  {hotelType === "ISHA" && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Tags")}</div>

                        <div className="custSubVal">{tags?.tags}</div>
                      </Stack>
                    </div>
                  )}
                  {hotelType === "ISHA" && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Remark")}</div>

                        <div className="custSubVal">{tags?.remark}</div>
                      </Stack>
                    </div>
                  )}
                  {/* <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div
                        className="custSubHeading"
                        // onClick={handleShowBookingNotes}
                      >
                        Booking Notes
                      </div>
                      <div
                        onClick={handleShowCustomer}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Add / View booking note
                      </div>
                    </Stack>
                  </div> */}
                  {![null, "N/A", "", "NA"].includes(
                    summaryData?.tax_exempt_id
                  ) &&
                    ctaDropdown.length > 0 && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">
                            {t("Tax exempt ID")}
                          </div>
                          {invoiceData && (
                            <div className="custSubVal">
                              {summaryData?.tax_exempt_id}
                            </div>
                          )}
                        </Stack>
                      </div>
                    )}

                  {otaBookingInfoData?.ota_booking_id && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">
                          {t("OTA booking ID")}
                        </div>
                        <div className="custSubVal">
                          <a
                            href={otaBookingInfoData?.ota_invoice_url}
                            style={{
                              textDecoration: "underline",
                              fontSize: "12px",
                              color: "black",
                              marginTop: "auto",
                              marginBottom: "auto",
                              fontWeight: 500,
                            }}
                            target="_blank"
                          >
                            <u>{otaBookingInfoData?.ota_booking_id}</u>
                          </a>
                        </div>
                      </Stack>
                    </div>
                  )}

                  {invoiceData?.invoice?.eReceipt_map !== "NA" &&
                    invoiceData?.invoice?.eReceipt_map !== undefined &&
                    invoiceData?.invoice?.eReceipt_map !== null &&
                    Object.entries(invoiceData?.invoice?.eReceipt_map).length >
                      0 && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">
                            {" "}
                            {t("E-Receipt Number")}
                          </div>
                          <div
                            className="custSubVal"
                            style={{
                              textDecoration: "underline",
                              fontSize: "12px",
                              color: "black",
                              marginTop: "auto",
                              marginBottom: "auto",
                              fontWeight: 500,
                              cursor: "pointer",
                            }}
                            onClick={() => handleOpenEReceipt()}
                          >
                            {t("View E-Receipt")}
                          </div>
                        </Stack>
                      </div>
                    )}
                  {hotelType === "ISHA" && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">
                          {t("Medical condition")}
                        </div>
                        <div
                          className="custSubVal"
                          style={{
                            fontSize: "12px",
                            color: "black",
                            marginTop: "auto",
                            marginBottom: "auto",
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          {invoiceData?.invoice?.hasOwnProperty(
                            "booking_medical_condition"
                          )
                            ? t(invoiceData?.invoice?.booking_medical_condition)
                            : t("No")}
                        </div>
                      </Stack>
                    </div>
                  )}

                  {invoiceData?.invoice?.cico_push_time &&
                    invoiceData?.invoice?.cico_push_time !== undefined &&
                    invoiceData?.invoice?.cico_push_time !== "" && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">
                            {t("Cico push time")}{" "}
                          </div>
                          <div
                            className="custSubVal"
                            // style={{
                            //   textDecoration: "underline",
                            //   fontSize: "12px",
                            //   color: "black",
                            //   marginTop: "auto",
                            //   marginBottom: "auto",
                            //   fontWeight: 500,
                            //   cursor: "pointer",
                            // }}
                            // onClick={() => handleOpenEReceipt()}
                          >
                            {invoiceData?.invoice?.cico_push_time}
                          </div>
                        </Stack>
                      </div>
                    )}

                  {invoiceData?.invoice?.cico_Checkin_time &&
                    invoiceData?.invoice?.cico_Checkin_time !== undefined &&
                    invoiceData?.invoice?.cico_Checkin_time !== "" && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">
                            {t("Cico push checkin")}
                          </div>
                          <div
                            className="custSubVal"
                            // style={{
                            //   textDecoration: "underline",
                            //   fontSize: "12px",
                            //   color: "black",
                            //   marginTop: "auto",
                            //   marginBottom: "auto",
                            //   fontWeight: 500,
                            //   cursor: "pointer",
                            // }}
                            // onClick={() => handleOpenEReceipt()}
                          >
                            {invoiceData?.invoice?.cico_Checkin_time}
                          </div>
                        </Stack>
                      </div>
                    )}
                </Stack>
              </div>
              <div className="details" style={{ marginLeft: "15px" }}>
                <Stack spacing={2}>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">
                        {t("Booking made on")}
                      </div>
                      <div className="custSubVal">
                        {handleFormatDate(invoiceData?.invoice.booking_made_on)}
                      </div>
                    </Stack>
                  </div>
                  {!checked && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Checkin")}</div>
                        {invoiceData && (
                          <div className="custSubVal">
                            {handleFormatDate(invoiceData.invoice.checkin)}
                          </div>
                        )}
                      </Stack>
                    </div>
                  )}
                  {!checked && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Checkout")}</div>
                        {invoiceData && (
                          <div className="custSubVal">
                            {handleFormatDate(invoiceData.invoice.checkout)}
                          </div>
                        )}
                      </Stack>
                    </div>
                  )}
                  {checked && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Checkin")}</div>
                        {invoiceData && (
                          <div className="custSubVal">
                            {handleFormatDate(invoiceData.invoice.checkin)}
                          </div>
                        )}
                      </Stack>
                    </div>
                  )}
                  {checked && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">{t("Checkout")}</div>
                        {invoiceData && (
                          <div className="custSubVal">
                            {handleFormatDate(invoiceData.invoice.checkout)}
                          </div>
                        )}
                      </Stack>
                    </div>
                  )}
                  {checked &&
                    customFolioConfig?.show_actual_cin_cout === "YES" && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">
                            {t("Actual checkin")}
                          </div>
                          {invoiceData && (
                            <div className="custSubVal">
                              {handleFormatDate(
                                invoiceData.invoice.actual_checkin
                              )}
                            </div>
                          )}
                        </Stack>
                      </div>
                    )}
                  {checked &&
                    customFolioConfig?.show_actual_cin_cout === "YES" && (
                      <div className="detail">
                        <Stack spacing={2.5} direction="row">
                          <div className="custSubHeading">
                            {t("Actual checkout")}
                          </div>
                          {invoiceData && (
                            <div className="custSubVal">
                              {handleFormatDate(
                                invoiceData.invoice.actual_checkout
                              )}
                            </div>
                          )}
                        </Stack>
                      </div>
                    )}
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">{t("Room ID")}</div>
                      {invoiceData && (
                        <div className="custSubVal">
                          {invoiceData?.invoice.hasOwnProperty(
                            "confirmed_room_ids_list"
                          )
                            ? invoiceData.invoice.confirmed_room_ids_list.join(
                                ","
                              )
                            : invoiceData?.invoice?.roomids}
                        </div>
                      )}
                    </Stack>
                  </div>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">{t("Nights")}</div>
                      {invoiceData && (
                        <div className="custSubVal">
                          {parseInt(invoiceData.invoice.num_nights)}
                        </div>
                      )}
                    </Stack>
                  </div>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">
                        {t("Adults/Children/Infant")}
                      </div>
                      {invoiceData && (
                        <div
                          className="custSubVal"
                          style={{
                            textDecoration: "underline",
                            marginLeft: "3rem",
                            cursor: "pointer",
                            paddingRight: "3rem",
                          }}
                          onClick={
                            // isNightAuditOn &&
                            invoiceData.invoice.status !== "CHECKED_OUT" &&
                            isEditable &&
                            JSON.parse(
                              data?.accessControl?.user_feature_map
                                ?.EditBookingPrice
                            ).read === "YES" &&
                            toggleAdultChildrenDrawer("right", true)
                          }
                        >
                          {invoiceData.invoice.adults}/
                          {invoiceData.invoice.children}/
                          {invoiceData.invoice.children1}
                        </div>
                      )}
                    </Stack>
                  </div>
                  {/* <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">Addons amount</div>

                      <div className="custSubVal">
                        {currency}{" "}
                        {getTotalAddOnVal(invoiceData?.invoice.items)}
                      </div>
                    </Stack>
                  </div>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">Addons tax</div>

                      <div className="custSubVal">
                        {currency}{" "}
                        {getTotalTaxAddOnVal(invoiceData?.invoice.items)}
                      </div>
                    </Stack>
                  </div>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">Payment made</div>

                      <div className="custSubVal">
                        {summaryData?.payment_made}
                      </div>
                    </Stack>
                  </div> */}
                  {/* <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div className="custSubHeading">Balance due</div>

                      <div className="custSubVal">
                        {currency} {summaryData?.balance_due}
                      </div>
                    </Stack>
                  </div> */}
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div
                        className="custSubHeading"
                        // onClick={handleShowCustomerNotes}
                      >
                        {t("Customer Notes")}
                      </div>
                      <div
                        className="custSubVal"
                        onClick={handleShow}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}

                        // sx={{
                        //   "&:hover": {
                        //     backgroundColor: "#B5B5C3",
                        //     borderRadius: "50px",
                        //   },
                        // }}
                      >
                        {`${t("Add / View customer notes")} (${
                          customerNotes?.length
                        })`}
                      </div>
                    </Stack>
                  </div>
                  <div className="detail">
                    <Stack spacing={2.5} direction="row">
                      <div
                        className="custSubHeading"
                        // onClick={handleShowCustomerNotes}
                      >
                        {t("Booking Notes")}
                      </div>
                      <div
                        className="custSubVal"
                        onClick={handleShowCustomer}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}

                        // sx={{
                        //   "&:hover": {
                        //     backgroundColor: "#B5B5C3",
                        //     borderRadius: "50px",
                        //   },
                        // }}
                      >
                        {`${t("Add / View booking notes")} (${
                          bookingNotes?.length
                        })`}
                      </div>
                    </Stack>
                  </div>
                  {invoiceData?.invoice?.special_requests !== "" && (
                    <div className="detail">
                      <Stack spacing={2.5} direction="row">
                        <div className="custSubHeading">
                          {t("Special requests")}
                        </div>
                        {invoiceData && (
                          <div className="custSubVal">
                            {invoiceData?.invoice?.special_requests}
                          </div>
                        )}
                      </Stack>
                    </div>
                  )}
                </Stack>
              </div>
              <div>
                {invoiceData?.invoice?.customer_signature_base64.length > 0 && (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: 5,
                      border: "none",
                      borderRadius: "15px",
                    }}
                  >
                    {t("Guest signature :")}{" "}
                    <img
                      src={getExtension(
                        invoiceData?.invoice?.customer_signature_base64
                      )}
                      alt=""
                      style={{
                        width: "65px",
                        height: "auto",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* )} */}
        {/* <div
          style={{
            width: "97.5%",
            position: "relative",
            marginTop: "20px",
            marginBottom: "20px",
            borderTop: "1px solid #DDDDDD",
          }}
        /> */}

        {invoiceData && (
          <div className="invoiceTableActions">
            <div className="invoiceSummTableWrapper">
              <>
                <div className="servicesTable">
                  <div className="invoiceSummaryTable">
                    {checked === "-1" &&
                      (invoiceData ? (
                        <InvoiceSummaryTable
                          invoiceData={invoiceData}
                          summaryData={summaryData}
                          hotelId={hotelId}
                          hotelLogo={hotelLogo}
                          bookingId={bookingId}
                          updateInvoice={() => updateInvoice()}
                          updateSummaryData={() => updateSummaryData()}
                          currency={currency}
                          loggedUserEmail={loggedUserEmail}
                          handleTrueWrapperMockLoader={() =>
                            handleTrueWrapperMockLoader()
                          }
                          handleFalseWrapperMockLoader={() =>
                            handleFalseWrapperMockLoader()
                          }
                          handleTrueSummaryMockLoader={() =>
                            handleTrueSummaryMockLoader()
                          }
                          handleFalseSummaryMockLoader={() =>
                            handleFalseSummaryMockLoader()
                          }
                          ishaEreceiptGenerated={
                            invoiceData?.invoice?.eReceipt_map !== "NA" &&
                            invoiceData?.invoice?.eReceipt_map !== undefined &&
                            invoiceData?.invoice?.eReceipt_map !== null &&
                            Object.entries(invoiceData?.invoice?.eReceipt_map)
                              .length > 0
                          }
                          reservationId={reservationIds}
                          isNightAuditOn={isNightAuditOn}
                          selectedLanguage={selectedLanguage}
                        />
                      ) : (
                        <MockInvoiceTable />
                      ))}
                    {checked === "-2" &&
                      (advancedInvoiceData && !mockTable ? (
                        <AdvInvoiceSummaryTable
                          invoiceData={invoiceData}
                          advInvoiceData={advancedInvoiceData?.invoice}
                          hotelId={hotelId}
                          bookingId={bookingId}
                          updateAdvInvoice={() => getAdvancedData()}
                          updateInvoice={() => updateInvoice()}
                          currency={currency}
                          loggedUserEmail={loggedUserEmail}
                          updateSummaryData={() => updateSummaryData()}
                          handleTrueWrapperMockLoader={() =>
                            handleTrueWrapperMockLoader()
                          }
                          handleFalseWrapperMockLoader={() =>
                            handleFalseWrapperMockLoader()
                          }
                          handleTrueSummaryMockLoader={() =>
                            handleTrueSummaryMockLoader()
                          }
                          handleFalseSummaryMockLoader={() =>
                            handleFalseSummaryMockLoader()
                          }
                          mockTableTrue={() => mockTableTrue()}
                          mockTableFalse={() => mockTableFalse()}
                          handleGetAllCustomFolios={handleGetAllCustomFolios}
                          isNightAuditOn={isNightAuditOn}
                          subUser={subUser}
                          selectedLanguage={selectedLanguage}
                        />
                      ) : (
                        <MockInvoiceTable />
                      ))}
                    {checked === "-3" &&
                      (detailedInvData ? (
                        <DetailedInvSummTable
                          detailedInvData={detailedInvData}
                          hotelId={hotelId}
                          bookingId={bookingId}
                          currency={currency}
                          loggedUserEmail={loggedUserEmail}
                          isNightAuditOn={isNightAuditOn}
                          subUser={subUser}
                          selectedLanguage={selectedLanguage}
                          // updateSummaryData={() => updateSummaryData()}
                          // mockTableTrue={() => mockTableTrue()}
                          // mockTableFalse={() => mockTableFalse()}
                        />
                      ) : (
                        <MockInvoiceTable />
                      ))}
                    {/* Setting up the table for the custom invoice data */}
                    {!["-1", "-2", "-3"].includes(checked) &&
                      (customInvoiceData && !mockTable ? (
                        <CustomInvoiceTables
                          invoiceData={invoiceData}
                          advInvoiceData={advancedInvoiceData?.invoice}
                          hotelId={hotelId}
                          bookingId={bookingId}
                          updateAdvInvoice={() => getAdvancedData()}
                          updateInvoice={() => updateInvoice()}
                          currency={currency}
                          loggedUserEmail={loggedUserEmail}
                          updateSummaryData={() => updateSummaryData()}
                          handleTrueWrapperMockLoader={() =>
                            handleTrueWrapperMockLoader()
                          }
                          handleFalseWrapperMockLoader={() =>
                            handleFalseWrapperMockLoader()
                          }
                          handleTrueSummaryMockLoader={() =>
                            handleTrueSummaryMockLoader()
                          }
                          handleFalseSummaryMockLoader={() =>
                            handleFalseSummaryMockLoader()
                          }
                          mockTableTrue={() => mockTableTrue()}
                          mockTableFalse={() => mockTableFalse()}
                          handleGetAllCustomFolios={handleGetAllCustomFolios}
                          customInvoiceData={customInvoiceData}
                          checked={checked}
                          isNightAuditOn={isNightAuditOn}
                          subUser={subUser}
                          selectedLanguage={selectedLanguage}
                        />
                      ) : (
                        <MockInvoiceTable />
                      ))}
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>

      {/* editing the customer edit modal */}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {list("right")}
      </Drawer>

      {/* editing the guest information edit modal */}
      <Drawer
        anchor={"right"}
        open={gueststate["right"]}
        onClose={toggleGuestDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {guestList("right")}
      </Drawer>

      {/* checkin modal */}
      <Drawer
        anchor={"right"}
        open={checkInState["right"]}
        onClose={toggleCheckInDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {modifyCheckin("right")}
      </Drawer>

      {/* Copy reservation */}
      <Drawer
        anchor={"right"}
        open={copyReservationDrawerState["right"]}
        onClose={toggleCopyReservationDrawerState("right", false)}
        PaperProps={{
          sx: { width: 1200 },
        }}
      >
        {copyReservation("right")}
      </Drawer>

      {/* checkout modal */}
      <Drawer
        anchor={"right"}
        open={checkOutState["right"]}
        onClose={toggleCheckOutDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {modifyCheckout("right")}
      </Drawer>

      {/* upgrade room modal */}
      <Drawer
        anchor={"right"}
        open={upgradeRoomState["right"]}
        onClose={toggleUpgradeRoomDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {moveAndUpgradeRoom("right")}
      </Drawer>

      {/* split rooms modal */}
      <Drawer
        anchor={"right"}
        open={splitRoomState["right"]}
        onClose={toggleSplitRoomDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {splitRoomss("right")}
      </Drawer>

      {/* split rooms modal */}
      <Drawer
        anchor={"right"}
        open={AddRoomToBookingState["right"]}
        onClose={toggleAddRoomToBookingDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {addRoomToBooking("right")}
      </Drawer>

      {/* lock  booking modal */}
      <Drawer
        anchor={"right"}
        open={lockBookingState["right"]}
        onClose={toggleLockBookingDrawer("right", false)}
        PaperProps={{
          sx: { width: "33vw" },
        }}
      >
        {lockBooking("right")}
      </Drawer>

      {/* edit booking modal */}
      <Drawer
        anchor={"right"}
        open={editBookingState["right"]}
        onClose={toggleEditBookingDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {editBooking("right")}
      </Drawer>

      {/* unlock  booking modal */}
      <Drawer
        anchor={"right"}
        open={unlockBookingState["right"]}
        onClose={toggleUnLockBookingDrawer("right", false)}
        PaperProps={{
          sx: { width: "33vw" },
        }}
      >
        {unlockRoom("right")}
      </Drawer>

      {/* assign  room modal */}
      <Drawer
        anchor={"right"}
        open={assignRoomState["right"]}
        onClose={toggleAssignRoomDrawer("right", false)}
        PaperProps={{
          sx: { width: "52vw" },
        }}
      >
        {assignRoom("right")}
      </Drawer>

      {/* unassign  room modal */}
      <Drawer
        anchor={"right"}
        open={unassignRoomState["right"]}
        onClose={toggleunAssignRoomDrawer("right", false)}
        PaperProps={{
          sx: { width: "52vw" },
        }}
      >
        {unAssignRoom("right")}
      </Drawer>
      {/* editing the adult guest information edit modal */}
      <Drawer
        anchor={"right"}
        open={adultChidrenState["right"]}
        onClose={toggleAdultChildrenDrawer("right", false)}
        PaperProps={{
          sx: { width: 825 },
        }}
      >
        {editAdultChildren("right")}
      </Drawer>

      <SearchModal
        show={showCompanyModal}
        hide={hideShowCompanyModal}
        allCompanyDetails={allCompanyDetails}
        searchableValue={searchableValue}
        setsearchableValue={setsearchableValue}
        isFolio={"folio"}
        bookingId={bookingId}
        invoiceData={invoiceData?.invoice}
        getUpdatedData={getUpdatedData}
        selectedLanguage={selectedLanguage}
        getCompanyDetails={getCompanyDetails}
      ></SearchModal>

      <Modal
        open={customerShow}
        onClose={handleCloseCustomer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              <CloseIcon
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginBottom: "2rem",
                }}
                onClick={handleCloseCustomer}
              />
              <h4>{t("Add booking notes")}</h4>
              <TextField
                required
                id="outlined-required"
                label={t("Booking notes")}
                placeholder={t("Enter a note")}
                className="w-100"
                onChange={(e) => setbookingNoteData(e.target.value)}
              />
            </div>
          </div>
          <div className="folioConfButtons">
            {/* <Button variant="secondary" onClick={handleCloseCustomer}>
              Close
            </Button> */}
            <Button
              variant="custom-button"
              onClick={createBookingNote}
              disabled={bookingNoteData === "" ? true : loading}
              style={{ float: "right", marginRight: "10px" }}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Save")}`
              )}
            </Button>
          </div>
          <div style={{ maxHeight: "70vh", overflow: "scroll" }}>
            <h4 style={{ marginLeft: "1.2rem", marginTop: "1rem" }}>
              {t("All booking notes")}
            </h4>
            {bookingNotes.length <= 0 ? (
              <h6 style={{ marginLeft: "15px" }} className="text-muted">
                {t("No booking notes")}{" "}
              </h6>
            ) : (
              bookingNotes?.map((e) => (
                <div className="d-flex align-items-center">
                  <Card className="mb-2" style={{ marginLeft: "16px" }}>
                    <Card.Body
                      style={{
                        width: "27rem",
                        paddingRight: "1rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                      }}
                    >
                      {e.notes}
                    </Card.Body>
                  </Card>
                  <DeleteIcon
                    onClick={() => deleteBookingNote(e.id, e?.notes)}
                    className="ml-2"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              <CloseIcon
                style={{
                  float: "right",
                  cursor: "pointer",
                  marginBottom: "2rem",
                }}
                onClick={handleClose}
              />
              <h4>{t("Enter note for this customer")}</h4>
              <TextField
                required
                id="outlined-required"
                label={t("Customer notes")}
                placeholder={t("Enter a note")}
                className="w-100"
                onChange={(e) => setcustomerNoteData(e.target.value)}
              />
            </div>
          </div>
          <div className="folioConfButtons">
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            <Button
              variant="custom-button"
              onClick={createCustomerNote}
              disabled={customerNoteData === "" ? true : loading}
              style={{ float: "right", marginRight: "10px" }}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Save")}`
              )}
            </Button>
          </div>
          <div style={{ maxHeight: "70vh", overflow: "scroll" }}>
            <h4 style={{ marginLeft: "1.2rem", marginTop: "1rem" }}>
              {t("All Customer notes")}
            </h4>
            {customerNotes.length <= 0 ? (
              <h6 style={{ marginLeft: "16px" }} className="text-muted">
                {t("No customer notes")}{" "}
              </h6>
            ) : (
              customerNotes?.map((e) => (
                <div className="d-flex align-items-center">
                  <Card className="mb-2" style={{ marginLeft: "16px" }}>
                    <Card.Body style={{ width: "24rem", padding: "1rem" }}>
                      {e.notes}
                    </Card.Body>
                  </Card>
                  {e.do_not_rent === "YES" && (
                    <p
                      className="mb-0 badge badge-danger ml-2"
                      style={{ fontSize: "13px" }}
                    >
                      <DoNotDisturbIcon
                        className="mb-0"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleNoteShow()}
                      />
                    </p>
                  )}

                  <DeleteIcon
                    onClick={() => deleteCustomerNote(e.id, e?.notes)}
                    className="ml-2"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={showBookingNotes}
        onClose={handleCloseBookingNotes}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              <h4>{t("All booking notes")}</h4>
              {bookingNotes.length <= 0 ? (
                <h6 className="text-muted">{t("No booking notes")} </h6>
              ) : (
                bookingNotes?.map((e) => (
                  <div className="d-flex align-items-center">
                    <Card className="mb-2" style={{ marginLeft: "16px" }}>
                      <Card.Body
                        style={{
                          width: "27rem",
                          paddingRight: "1rem",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                        }}
                      >
                        {e.notes}
                      </Card.Body>
                    </Card>
                    <DeleteIcon
                      onClick={() => deleteBookingNote(e.id, e?.notes)}
                      className="ml-2"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="folioConfButtons">
            <Button variant="secondary" onClick={handleCloseBookingNotes}>
              {t("Close")}
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={showCustomerNotes}
        onClose={handleCloseCustomerNotes}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              <h4>{t("All Customer notes")}</h4>
              {customerNotes.length <= 0 ? (
                <h6 style={{ marginLeft: "16px" }} className="text-muted">
                  {t("No customer notes")}{" "}
                </h6>
              ) : (
                customerNotes?.map((e) => (
                  <div className="d-flex align-items-center">
                    <Card className="mb-2" style={{ marginLeft: "16px" }}>
                      <Card.Body style={{ width: "24rem", padding: "1rem" }}>
                        {e.notes}
                      </Card.Body>
                    </Card>
                    {e.do_not_rent === "YES" && (
                      <p
                        className="mb-0 badge badge-danger ml-2"
                        style={{ fontSize: "13px" }}
                      >
                        <DoNotDisturbIcon
                          className="mb-0"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleNoteShow()}
                        />
                      </p>
                    )}

                    <DeleteIcon
                      onClick={() => deleteCustomerNote(e.id, e?.notes)}
                      className="ml-2"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="folioConfButtons">
            <Button variant="secondary" onClick={handleCloseCustomerNotes}>
              {t("Close")}
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Currency convertor modal */}
      <Modal
        open={openCurrencyModalDefault}
        onClose={handleCloseCurrencyModalDefault}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              <h4>{t("Convert currency")}</h4>
              <p className="text-muted">
                {t(
                  "For manual price calculation, kindly edit the price in the amount field"
                )}
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <TextField
                  id="outlined-name"
                  label={t("Hotel Currency")}
                  name="Hotel Currency"
                  disabled={true}
                  value={localStorage.getItem("hotelCurrency")}
                  // onChange={(e) => setcurrencyRate(e.target.value)}
                  sx={{ width: "8rem", marginRight: "2rem" }}
                  type={"text"}
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <FormControl
                  fullWidth
                  style={{ width: "12rem", marginRight: "2rem" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Convert currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      sessionStorage.setItem(
                        "convertedHotelCurrency",
                        e.target.value
                      );
                      setselectedCurrency(e.target.value);
                      if (
                        hotelCurrency !== "" &&
                        hotelCurrency !== undefined &&
                        hotelCurrency !== null &&
                        sessionStorage.getItem("convertedHotelCurrency") !==
                          "" &&
                        sessionStorage.getItem("convertedHotelCurrency") !==
                          undefined &&
                        sessionStorage.getItem("convertedHotelCurrency") !==
                          null
                      ) {
                        getConvertedvalue();
                      }
                    }}
                    style={{
                      marginLeft: "0rem",
                      width: "12rem",
                      marginRight: "2rem",
                    }}
                    className="mr-4"
                    label={t("Convert currency")}
                    name="Convert currency"
                    value={selectedCurrency}
                  >
                    {Currencies?.map((hours) => (
                      <MenuItem value={hours.code}>{hours.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-name"
                  label={`${t("Amount")} ${selectedCurrency}`}
                  name={`Amount ${selectedCurrency}`}
                  value={currencyRate}
                  onChange={(e) => setcurrencyRate(e.target.value)}
                  sx={{ width: "8rem" }}
                  type={"text"}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
              {/* <Button variant="custom-button" onClick={fetchRates}>
                  Fetch Currency rates
                </Button> */}
            </div>
            <div className="folioConfButtons">
              <button
                className="cancelBtn"
                onClick={() => handleCloseCurrencyModalDefault()}
              >
                {t("Cancel")}
              </button>
              <Button
                className="submit"
                variant="custom-button"
                onClick={() => updateDefaultRate()}
                disabled={currencyLoader}
              >
                {currencyLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Fetch rates")}`
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Confirmation modal for putting the booking on hold */}
      <Modal
        open={openHoldBooking}
        onClose={handleCloseHoldBooking}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              {t("Are you sure you want to put the booking on hold ?")}
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => handleCloseHoldBooking()}
              >
                {t("Cancel")}
              </button>
              {holdBookingLoader === false ? (
                <button
                  className="approved"
                  onClick={() => handleHoldBooking()}
                >
                  {t("Hold booking")}
                </button>
              ) : (
                <button className="approved">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Delete group room  modal  */}
      <Modal
        open={deleteGroupRoomBooking}
        onClose={handleCloseDeleteGroupRoomBooking}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              <div>{t("Select the rooms to cancel the booking")}</div>{" "}
              {reservationIds && (
                <FormControl
                  fullWidth
                  style={{ width: "17rem", marginTop: "2rem" }}
                >
                  <InputLabel
                    style={{ width: "6rem" }}
                    id="demo-simple-select-label"
                  >
                    {t("Select rooms")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleRate}
                    style={{
                      marginLeft: "0rem",
                      width: "17rem",
                    }}
                    className="mr-4"
                    multiple
                    label="Select rooms"
                    value={selectedRooms}
                  >
                    {Object.keys(reservationIds)?.map((name) => (
                      <MenuItem value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => handleCloseDeleteGroupRoomBooking()}
              >
                {t("Cancel")}
              </button>

              <Button
                variant="custom-button"
                // className="approved"
                onClick={() => handleGroupCancelBooking()}
                disabled={!selectedRooms || holdBookingLoader}
              >
                {t("Cancel booking")}{" "}
                {holdBookingLoader && <CircularProgress size="15px" />}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Confirmation modal for putting the booking on no show */}
      <Modal
        open={openNoShow}
        onClose={handleCloseNoShow}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div
            className="folioConfirmationWrapper"
            style={{
              position: "relative",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="folioHeader"
              style={{ position: "relative", width: "100%" }}
            >
              <div className="headerName" style={{ fontWeight: 500 }}>
                {t("Set booking to no show")}
              </div>
              <div
                className="cancelText"
                style={{ fontWeight: 500, cursor: "pointer" }}
                onClick={() => handleCloseNoShow()}
              >
                {"X"}
              </div>
            </div>
            <div className="horizontalSeparator" />
            {/* <div className="folioConfContent">Are you sure you want to cancel?</div> */}
            <div
              className="folioContent"
              style={{ marginTop: "15px", width: "100%", position: "relative" }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  {t("Please Select")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={voidTypeOption}
                  label={t("Void charge options")}
                  onChange={handleVoidTypeOption}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {voidTypeOptions.map((item) => (
                    <MenuItem value={item.name}>{item.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => handleCloseCancelBooking()}
              >
                {t("Cancel")}
              </button>

              <Button
                variant="custom-button"
                // className="approved"
                disabled={!voidTypeOption || noShowLoader}
                onClick={() =>
                  // invoiceData?.invoice?.booking_source === "BOOKING"
                  //   ? handleOpenBookingNoShow()
                  handleBookingNoShow()
                }
              >
                {t("Set to no show")}{" "}
                {noShowLoader && <CircularProgress size="15px" />}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Booking dot com modal for putting the booking on no show */}
      <Modal
        open={openBookingNoShow}
        onClose={handleCloseBookingNoShow}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div
            className="folioConfirmationWrapper"
            style={{
              position: "relative",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="folioHeader"
              style={{ position: "relative", width: "100%" }}
            >
              <div className="headerName" style={{ fontWeight: 500 }}>
                {t("Mark as a no-show")}
              </div>
              <div
                className="cancelText"
                style={{ fontWeight: 500, cursor: "pointer" }}
                onClick={() => handleCloseBookingNoShow()}
              >
                {"X"}
              </div>
            </div>
            <div className="horizontalSeparator" />
            {/* <div className="folioConfContent">Are you sure you want to cancel?</div> */}
            <div
              className="folioContent"
              style={{ marginTop: "15px", width: "100%", position: "relative" }}
            >
              <div>
                <h4>{invoiceData?.invoice?.roomtype_names}</h4>
                <FormControl style={{ marginTop: "10px" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    {t(
                      "Would you like to waive the no-show fee for this reservation?"
                    )}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={waiveNoShowFee}
                    onChange={(e) => setwaiveNoShowFee(e.target.value)}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={t("YES")}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={t("NO")}
                    />
                  </RadioGroup>
                </FormControl>
                <p>{t("We will inform the guest accordingly")}</p>
                <p>
                  {t(
                    "If you charge a cancellation or no-show fee for any  reservation. Booking.com will charge commission on this fee"
                  )}
                </p>
                <p>
                  {t(
                    "In case the guest leaves a review, it will be automatically removed after the no-show is confirmed and your review score will not be impacted."
                  )}
                </p>
              </div>
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => handleCloseBookingNoShow()}
              >
                {t("Cancel")}
              </button>
              {noShowLoader === false ? (
                <button
                  className="approved"
                  style={{ width: "14rem" }}
                  onClick={() => markBookingDotComNoShow()}
                >
                  {t("Yes mark as a no show")}
                </button>
              ) : (
                <button className="approved">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Confirmation modal for setting primary customer*/}
      <Modal
        open={primaryCustModal}
        onClose={closePrimaryCustModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div
            className="folioConfirmationWrapper"
            style={{
              position: "relative",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="folioHeader"
              style={{ position: "relative", width: "100%" }}
            >
              <div className="headerName" style={{ fontWeight: 500 }}>
                {t("Set primary customer for booking:")} {bookingId}
              </div>
              <div
                className="cancelText"
                style={{ fontWeight: 500, cursor: "pointer" }}
                onClick={() => closePrimaryCustModal()}
              >
                {"X"}
              </div>
            </div>
            <div className="horizontalSeparator" />
            {/* <div className="folioConfContent">Are you sure you want to cancel?</div> */}
            <div
              className="folioContent"
              style={{ marginTop: "15px", width: "100%", position: "relative" }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  {t("Select Customer")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPrimaryCust}
                  label={t("Customer list")}
                  onChange={(e) => {
                    setSelectedPrimaryCust(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {allGuestUsers.map((item) => (
                    <MenuItem value={item.email}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => closePrimaryCustModal()}
              >
                {t("Close")}
              </button>
              {noShowLoader === false ? (
                <button
                  className="approved"
                  onClick={() => handleBookingNoShow()}
                >
                  {t("Set as primary")}
                </button>
              ) : (
                <button className="approved">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      {/* Confirmation modal for putting the booking on no show */}
      <Modal
        open={openEreceiptModal}
        onClose={handleCloseEReceipt}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div
            className="folioConfirmationWrapper"
            style={{
              position: "relative",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="folioHeader"
              style={{ position: "relative", width: "100%" }}
            >
              <div className="headerName" style={{ fontWeight: 500 }}>
                {t("Click on E-Receipt to open")}
              </div>
              <div
                className="cancelText"
                style={{ fontWeight: 500, cursor: "pointer" }}
                onClick={() => handleCloseEReceipt()}
              >
                {"X"}
              </div>
            </div>
            <div className="horizontalSeparator" />
            {/* <div className="folioConfContent">Are you sure you want to cancel?</div> */}
            <div
              className="folioContent"
              style={{ marginTop: "15px", width: "100%", position: "relative" }}
            >
              {invoiceData?.invoice?.eReceipt_map !== "NA" &&
                invoiceData?.invoice?.eReceipt_map !== undefined &&
                invoiceData?.invoice?.eReceipt_map !== null &&
                Object.entries(invoiceData?.invoice?.eReceipt_map).length > 0 &&
                Object.entries(invoiceData?.invoice?.eReceipt_map).map((e) => (
                  <>
                    <a
                      href={e[1]}
                      style={{
                        textDecoration: "underline",
                        fontSize: "12px",
                        color: "black",
                        marginTop: "auto",
                        marginBottom: "auto",
                        fontWeight: 500,
                      }}
                      target="_blank"
                    >
                      <u>{e[0]}</u>
                    </a>
                    <br />
                  </>
                ))}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal to give the detailed breakup for the room */}
      <Modal
        open={roomBreakupModal}
        onClose={handleOpenRoomBreakupModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack>
            <Stack
              direction="row"
              className="position-relative d-flex flex w-100 p-5 justify-content-between"
              style={{
                backgroundImage: "linear-gradient(-150deg, #1ab394, #1a91ae)",
                color: "white",
              }}
            >
              <Typography>
                <strong>{t("Group booking master data")}</strong>
              </Typography>
              <Typography>
                <strong
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={handleCloseRoomBreakupModal}
                >
                  X
                </strong>
              </Typography>
            </Stack>
            <Divider />
            <Stack className="position-relative w-100 mt-5 p-5">
              <div
                style={{
                  maxHeight: "450px", // Adjust this value as needed
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      fontSize: "12px",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th style={{ width: "5%" }}>{t("Room ID")}</th>
                        <th style={{ width: "10%" }}>
                          {t("Room type")} <br />
                          {t("(rate plan)")}
                        </th>
                        <th style={{ width: "10%" }}>
                          {t("Guest name")} <br />
                          {t("room wise")}
                        </th>
                        <th style={{ width: "3%" }}>{t("Adults")}</th>
                        <th style={{ width: "3%" }}>{t("Child")}</th>
                        <th style={{ width: "3%" }}>{t("Infants")}</th>
                        <th style={{ width: "5%" }}>{t("Total nights")}</th>
                        <th style={{ width: "7%" }}>
                          {t("Room price with taxes")} ({currency})
                        </th>
                        <th style={{ width: "5%" }}>
                          {t("Addons price")} ({currency})
                        </th>
                        <th style={{ width: "5%" }}>
                          {t("Total amount")} ({currency})
                        </th>
                        <th style={{ width: "5%" }}>
                          {t("Amount paid")} ({currency})
                        </th>
                        <th style={{ width: "10%" }}>
                          {t("Redirect to room folio")}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <table
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    tableLayout: "fixed",
                  }}
                >
                  {/* <thead style={{ display: "none" }}>
                    <tr style={{ textAlign: "center" }}>
                      <th style={{ width: "5%" }}>Room ID</th>
                      <th style={{ width: "10%" }}>
                        Room type <br />
                        (rate plan)
                      </th>
                      <th style={{ width: "10%" }}>
                        Guest name <br />
                        room wise
                      </th>
                      <th style={{ width: "3%" }}>Adults</th>
                      <th style={{ width: "3%" }}>Child</th>
                      <th style={{ width: "3%" }}>Infants</th>
                      <th style={{ width: "5%" }}>Total nights</th>
                      <th style={{ width: "7%" }}>
                        Room price with taxes ({currency})
                      </th>
                      <th style={{ width: "5%" }}>Addons price ({currency})</th>
                      <th style={{ width: "5%" }}>Total amount ({currency})</th>
                      <th style={{ width: "5%" }}>Amount paid ({currency})</th>
                      <th style={{ width: "10%" }}>Redirect to room folio</th>
                    </tr>
                  </thead> */}
                  {roomBreakupData ? (
                    <>
                      <tbody>
                        {roomBreakupData?.map((item, index) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td style={{ width: "5%" }}>{item?.room_id}</td>
                            <td style={{ width: "10%" }}>
                              {item?.room_type_name +
                                "(" +
                                item?.rate_plan_name +
                                ")"}
                            </td>
                            <td style={{ width: "10%" }}>{item?.guest_name}</td>
                            <td style={{ width: "3%" }}>{item?.adults}</td>
                            <td style={{ width: "3%" }}>{item?.children}</td>
                            <td style={{ width: "3%" }}>{item?.infants}</td>
                            <td style={{ width: "5%" }}>
                              {item?.no_of_nights}
                            </td>
                            <td style={{ width: "7%" }}>{item?.room_amount}</td>
                            <td style={{ width: "5%" }}>
                              {item?.add_on_amount}
                            </td>
                            <td style={{ width: "5%" }}>
                              {item?.total_amount_with_services}
                            </td>
                            <td style={{ width: "5%" }}>{item?.amount_paid}</td>
                            <td style={{ width: "10%" }}>
                              <Link
                                to={{
                                  pathname: `/roomFolio/${item?.res_id}`,
                                  search: `&hotelId=${hotelId}&bookingId=${item?.booking_id}`,
                                }}
                                target="_blank"
                              >
                                <Button variant="custom-button">
                                  {t("View room folio")}
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <CircularProgress size="15px" />
                  )}
                </table>
              </div>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      {/* Confirmation modal for rolling back this booking */}
      <Modal
        open={openRollbackBooking}
        onClose={handleCloseRollbackBooking}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              {t("Confirm rollback action on this booking?")}
              {invoiceData?.invoice?.custom_invoice_id && (
                <div className="folioConfContent" style={{ fontSize: "12px" }}>
                  {t(
                    "Note: Custom folio Id will remain unchanged after rollback."
                  )}
                </div>
              )}
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => handleCloseRollbackBooking()}
              >
                {t("Cancel")}
              </button>
              {rollbackLoader === false ? (
                <button
                  className="approved"
                  onClick={() => handleRollbackBooking()}
                  style={{ width: "150px" }}
                >
                  {t("Rollback booking")}
                </button>
              ) : (
                <button className="approved" style={{ width: "150px" }}>
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Confirmation modal for cancelling the booking */}
      <Modal
        open={openCancelBooking}
        onClose={handleCloseCancelBooking}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div
            className="folioConfirmationWrapper"
            style={{
              position: "relative",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="folioHeader"
              style={{ position: "relative", width: "100%" }}
            >
              <div className="headerName" style={{ fontWeight: 500 }}>
                {t("Cancel booking")}
              </div>
              <div
                className="cancelText"
                style={{ fontWeight: 500, cursor: "pointer" }}
                onClick={() => handleCloseCancelBooking()}
              >
                {"X"}
              </div>
            </div>
            <div className="horizontalSeparator" />
            {/* <div className="folioConfContent">Are you sure you want to cancel?</div> */}
            <div
              className="folioContent"
              style={{ marginTop: "15px", width: "100%", position: "relative" }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  {t("Please select")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={voidTypeOption}
                  label={t("Void charge options")}
                  onChange={handleVoidTypeOption}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {voidTypeOptions.map((item) => (
                    <MenuItem value={item.name}>{item.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="folioConfButtons">
              <button
                className="cancel"
                onClick={() => handleCloseCancelBooking()}
              >
                {t("Cancel")}
              </button>

              <Button
                variant="custom-button"
                // className="approved"
                onClick={() => handleCancelBooking()}
                disabled={!voidTypeOption}
              >
                {t("Cancel booking")}{" "}
                {cancelBookingLoader && <CircularProgress size="15px" />}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Update Donot rent */}

      <Modal
        open={updateNotesModal}
        onClose={handleNoteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div
            className="folioConfirmationWrapper"
            style={{
              position: "relative",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="folioHeader"
              style={{ position: "relative", width: "100%" }}
            >
              <div
                className="cancelText"
                style={{ fontWeight: 500, cursor: "pointer" }}
                onClick={() => handleNoteClose()}
              >
                {"X"}
              </div>
            </div>
            <div className="horizontalSeparator" />
            <h4>{t("Are you sure you want to remove donot rent?")}</h4>
            <div className="folioConfContent">
              {donotRent?.length > 0 && `Reason : ${donotRent[0]?.notes}`}
            </div>

            <div className="folioConfButtons">
              <button className="cancel" onClick={() => handleNoteClose()}>
                {t("Cancel")}
              </button>
              {UpdateRentLoader === false ? (
                <button className="approved" onClick={() => updateDonotRent()}>
                  {t("Update")}
                </button>
              ) : (
                <button className="approved">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal to update toc to capture the guest signature */}
      <Modal
        open={openToc}
        onClose={handleCloseToc}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            maxWidth: "450px",
          }}
        >
          <div className="settleDuesWrapper">
            <div
              className="settleDuesHeader"
              style={{
                marginBottom: "1px solid #DDDDDD",
                paddingBottom: "5px",
              }}
            >
              <div className="settleDuesHeading">
                {" "}
                {t("Capture guest signature")}
              </div>
              <div className="close" onClick={() => handleCloseToc()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                {t(
                  "Below text will be shown on terminal. You can edit the terms."
                )}
              </div>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={5}
                label={t("Enter TOC")}
                name="guest_signature"
                value={toc}
                onChange={(e) => setToc(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            <div className="settleDuesSubmitBtns">
              <button className="cancelBtn" onClick={() => handleCloseToc()}>
                {t("Cancel")}
              </button>
              <div className="d-flex w-auto">
                {tocLoader === false ? (
                  <button
                    className="submit w-auto mr-2"
                    onClick={() => handleToc()}
                  >
                    {t("Capture guest signature")}
                  </button>
                ) : (
                  <button className="submit mr-2">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal to add the service */}
      <Modal
        open={openAddNewService}
        onClose={handleCloseAddNewService}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="addServiceModal">
          <div className="addServiceWrapper">
            <div className="serviceHeaderWrapper">
              <div className="serviceHeader">{t("Add new service")}</div>
              <div className="cross" onClick={() => handleCloseAddNewService()}>
                X
              </div>
            </div>

            <div className="horizontal" />

            <div className="invoiceInfoWrapper">
              <Stack direction="row" spacing={2}>
                <div className="infoField">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label={t("Service date")}
                      value={serviceDate}
                      onChange={(newValue) => {
                        setServiceDate(newValue);
                      }}
                      name="service_date"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "170px" }} />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className="infoField">
                  <FormControl sx={{ width: "170px" }}>
                    <InputLabel id="demo-simple-select-label">
                      {t("Service amount type")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={serviceAmtType}
                      label={t("Service amount type")}
                      onChange={(e) => setServiceAmtType(e.target.value)}
                      name="service_amt_type"
                    >
                      <MenuItem value="DEBIT">{t("DEBIT")}</MenuItem>
                      <MenuItem value="CREDIT">{t("CREDIT")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Stack>
              {renderAddNewService()}
            </div>
          </div>
          <Divider variant="middle" />
          <div className="addServiceBtns">
            <Button
              // className="cancel"
              onClick={() => handleCloseAddNewService()}
            >
              {t("Cancel")}
            </Button>
            {addNewServiceLoader === false ? (
              <Button
                onClick={() => handleAddNewService()}
                variant="custom-button"
                disabled={serviceDesc === "" ? true : false}
              >
                {t("Add new service")}
              </Button>
            ) : (
              <button className="approved">
                <CircularProgress size="15px" />
              </button>
            )}
          </div>
        </Box>
      </Modal>

      {/* Hotel addons */}

      <Modal
        open={openHotelAddon}
        onClose={handleCloseHotelAddon}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="addServiceModal" style={{ width: "50rem" }}>
          <div
            className="addServiceWrapper"
            style={{ width: "50rem", padding: "2rem" }}
          >
            <div className="serviceHeaderWrapper">
              <div className="serviceHeader">
                {t("Add hotel addons")}
                <div className="roomIdHeader">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("Room Id")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => handleRoomIdChange(e.target.value)}
                      style={{ marginLeft: "0rem" }}
                      label={t("Room Id")}
                      value={selectedRoomId}
                    >
                      {roomIdMapping?.map((resItem, index) => {
                        return (
                          <MenuItem
                            value={resItem?.roomId}
                            key={resItem?.resId}
                          >
                            {resItem?.roomId}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="cross" onClick={() => handleCloseHotelAddon()}>
                X
              </div>
            </div>

            <div className="horizontal" />

            <div className="invoiceInfoWrapper">
              <div className="d-flex mt-4 align-items-center">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("Addons")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setshowHotelAddons(e.target.value)}
                    style={{ marginLeft: "0rem" }}
                    className="mr-4"
                    label={t("Addons")}
                    value={showHotelAddons}
                  >
                    <MenuItem value={"Add new service"}>
                      {t("Add new service")}
                    </MenuItem>
                    <MenuItem value={"Predefined addons"}>
                      {t("Predefined addons")}
                    </MenuItem>
                  </Select>
                </FormControl>
                {showHotelAddons === "Predefined addons" && (
                  <FormControl
                    // sx={{
                    //   // marginLeft: "2rem",
                    //   // width: "33rem",
                    //   // marginBottom: "2rem",
                    // }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      {t("Select addons")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select addons"
                      value={selectedAddons}
                      onChange={handleChanges}
                      required="true"
                      multiple
                      // MenuProps={MenuProps}
                      placeholder={t("Select addons")}
                    >
                      {hotelAddons.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              {selectedAddons?.length > 0 &&
                showHotelAddons === "Predefined addons" && (
                  <div className="mt-3" style={{ marginLeft: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label={t("Addon date")}
                        value={applyDate}
                        onChange={(newValue) => {
                          setapplyDate(newValue);
                        }}
                        minDate={new Date(invoiceData?.invoice?.checkin)}
                        maxDate={new Date(invoiceData?.invoice?.checkout)}
                        name="service_date"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <BootstrapTable
                      bootstrap4
                      keyField="id"
                      data={addonsProductArray}
                      columns={columns}
                      // defaultSorted={defaultSorted}
                      cellEdit={cellEditFactory({
                        mode: "click",
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                          row.priceWithTax =
                            parseInt(row.quantity) *
                            (parseFloat(row.priceWithoutTax) +
                              parseFloat(row.priceWithoutTax) *
                                (parseFloat(row.taxPercentage) / 100));
                        },
                      })}
                    />
                  </div>
                )}
              {showHotelAddons === "Add new service" && (
                <div className="invoiceInfoWrapper">
                  <Stack direction="row" spacing={2}>
                    <div className="infoField">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label={t("Service date")}
                          value={serviceDate}
                          onChange={(newValue) => {
                            setServiceDate(newValue);
                          }}
                          name="service_date"
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>

                    <div className="infoField" style={{ width: "32rem" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          {t("Service amount type")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={serviceAmtType}
                          style={{ width: "32rem" }}
                          label={t("Service amount type")}
                          onChange={(e) => setServiceAmtType(e.target.value)}
                          name="service_amt_type"
                        >
                          <MenuItem value="DEBIT">{t("DEBIT")}</MenuItem>
                          <MenuItem value="CREDIT">{t("CREDIT")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Stack>
                  {renderAddNewService()}
                </div>
              )}
            </div>
          </div>
          <Divider variant="middle" />
          <div className="addServiceBtns">
            <Button
              // className="cancel"
              onClick={() => handleCloseHotelAddon()}
            >
              {t("Cancel")}
            </Button>
            {addNewServiceLoader === false ? (
              <Button
                onClick={() =>
                  showHotelAddons === "Predefined addons"
                    ? handleAddons()
                    : handleAddNewService()
                }
                variant="custom-button"
                disabled={
                  showHotelAddons === "Predefined addons"
                    ? selectedAddons.length === 0
                      ? true
                      : false
                    : serviceDesc.length === 0
                    ? true
                    : false
                }
              >
                {t("Add new hotel addon")}
              </Button>
            ) : (
              <button className="approved">
                <CircularProgress size="15px" />
              </button>
            )}
          </div>
        </Box>
      </Modal>

      {/* Modal to apply discount */}

      <Modal
        open={openDiscount}
        onClose={handleCloseDiscount}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="addServiceModal" style={{ width: "50rem" }}>
          <div
            className="addServiceWrapper"
            style={{ width: "50rem", padding: "2rem" }}
          >
            <div className="serviceHeaderWrapper">
              <div className="serviceHeader">
                {t("Apply special code / discount")}
                <div className="roomIdHeader"></div>
              </div>
              <div className="cross" onClick={() => handleCloseDiscount()}>
                X
              </div>
            </div>

            <div className="horizontal" />

            <div className="invoiceInfoWrapper">
              <div className="d-flex mt-4 align-items-center">
                <FormControl sx={{ m: 1, width: "250px" }}>
                  <InputLabel id="demo-simple-select-label">
                    {t("Coupon code / Offer")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("Coupon code / Offer")}
                    value={code}
                    onChange={(e) => setcode(e.target.value)}
                    required="true"
                    // MenuProps={MenuProps}
                    placeholder={t("Coupon code / Offer")}
                  >
                    <MenuItem value={"secretcode"}>{t("Coupon code")}</MenuItem>
                    <MenuItem value={"promocode"}>{t("Offer")}</MenuItem>
                    <MenuItem value={"discount"}>{t("Discount")}</MenuItem>
                  </Select>
                </FormControl>
                {code === "secretcode" && (
                  <div className="d-flex align-items-center">
                    <TextField
                      label={t("Enter secret coupon code")}
                      value={promoValue}
                      onChange={(e) => setpromoValue(e.target.value)}
                      fullWidth
                    ></TextField>
                  </div>
                )}

                {code === "promocode" && (
                  <FormControl sx={{ m: 1, width: "150px" }}>
                    <InputLabel id="demo-simple-select-label">
                      {t("Select offer")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select rates"
                      value={promoValue}
                      onChange={(e) => {
                        setpromoValue(e.target.value);
                      }}
                      required="true"
                      // MenuProps={MenuProps}
                      placeholder={t("Select offer")}
                    >
                      {PromocodeData?.map((e) => (
                        <MenuItem value={e.promoCode}>{e.promoCode}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {code === "discount" && (
                  <div className="d-flex">
                    <FormControl style={{ width: "8rem" }}>
                      <InputLabel id="demo-simple-select-label">
                        {t("Select discount type")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("Select discount type")}
                        value={dicountType}
                        onChange={(e) => setdicountType(e.target.value)}
                        required="true"
                        // MenuProps={MenuProps}
                        placeholder={t("Select discount type")}
                      >
                        <MenuItem value={"percent"}>{t("Percent")}</MenuItem>
                        <MenuItem value={"flat"}>{t("Flat")}</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label={t("Enter discount value")}
                      value={discountAmount}
                      style={{ width: "6rem", marginLeft: "1rem" }}
                      onChange={(e) => {
                        // if (dicountType === "percent") {
                        //   if (e.target.value >= 0 && e.target.value <= 100)
                        setdiscountAmount(e.target.value);
                        // } else {
                        //   if (
                        //     e.target.value >= 0 &&
                        //     e.target.value <=
                        //       parseFloat(
                        //         // folioSummary.invoice.invoice_total_amount
                        //         summaryData?.total_amount_with_services
                        //       ).toFixed(2)
                        //   )
                        //     setdiscountAmount(e.target.value);
                        // }
                      }}
                    ></TextField>
                  </div>
                )}
                {showPromoButton && code !== "discount" && (
                  <Button
                    style={{ marginLeft: "0.5rem", height: "3rem" }}
                    variant="custom-button"
                    onClick={() => applyingPromo()}
                    disabled={!promoValue}
                  >
                    {t("Apply")}
                  </Button>
                )}
                {showDiscountButton && code === "discount" && (
                  <Button
                    style={{ marginLeft: "0.5rem", height: "3rem" }}
                    variant="custom-button"
                    onClick={() => applyDiscount()}
                    disabled={!dicountType || !discountAmount}
                  >
                    {t("Apply")}
                  </Button>
                )}
                {!showPromoButton && code !== "discount" && (
                  <Button
                    style={{ marginLeft: "0.5rem", height: "3rem" }}
                    variant="custom-button"
                    onClick={() => removePromo()}
                    disabled={!promoValue}
                  >
                    {t("Remove")}
                  </Button>
                )}
                {!showDiscountButton && code === "discount" && (
                  <Button
                    style={{ marginLeft: "0.5rem", height: "3rem" }}
                    variant="custom-button"
                    onClick={() => removeDiscount()}
                    disabled={!dicountType || !discountAmount}
                  >
                    {t("Remove")}
                  </Button>
                )}
              </div>
              {PromoValidation?.validPromo === false && (
                <p className="text-danger" style={{ marginLeft: "1rem" }}>
                  {t("Enter a valid coupon code or offer")}
                </p>
              )}{" "}
              {PromoValidation?.validPromo === true && (
                <p className="text-success" style={{ marginLeft: "1rem" }}>
                  {t("Successfully applied coupon code or offer")}
                </p>
              )}
            </div>
          </div>
          <Divider variant="middle" />
          <div className="addServiceBtns">
            <Button
              // className="cancel"
              onClick={() => handleCloseDiscount()}
            >
              {t("Cancel")}
            </Button>
            {addNewServiceLoader === false ? (
              <Button
                onClick={() => applyDiscountedAmount()}
                variant="custom-button"
                disabled={
                  !showPromoButton ? showPromoButton : showDiscountButton
                }
              >
                {t("Apply price")}
              </Button>
            ) : (
              <button className="approved">
                <CircularProgress size="15px" />
              </button>
            )}
          </div>
        </Box>
      </Modal>

      {/* Modal to toggle the folio log */}

      <Modal
        open={openFolioLog}
        onClose={handleCloseFolioLog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="folioLogModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            width: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <div className="folioLogHeader">
            <div className="folioLogHeading">{t("Folio logs")}</div>
            <div className="close" onClick={() => handleCloseFolioLog()}>
              X
            </div>
          </div>
          <div className="folioLogTableWrapper">
            {folioLogs ? (
              <FolioLogTable
                folioLogData={folioLogs}
                bookingId={bookingId}
                invoiceData={invoiceData}
                hotelLogo={hotelLogo}
                selectedLanguage={selectedLanguage}
              />
            ) : (
              `${t("Loading")}`
            )}
          </div>
        </Box>
      </Modal>

      {/* Modal for setting the tax exemption status */}

      <Modal
        open={openTaxExempt}
        onClose={handleCloseTaxExempt}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="addServiceModal">
          <div className="addServiceWrapper">
            <div className="serviceHeaderWrapper">
              <div className="serviceHeader">
                {t("Change tax exemption status")}
              </div>
              <div className="cross" onClick={() => handleCloseTaxExempt()}>
                X
              </div>
            </div>

            <div className="horizontal" />

            <div className="invoiceInfoWrapper">
              <div>
                {parseInt(
                  Object.values(summaryData?.tax_breakdown)[0]
                    ? Object.values(summaryData?.tax_breakdown)[0]
                    : 0.0
                ) === 0 && (
                  <div
                    style={{ backgroundColor: "#C8E5F3" }}
                    className="position-relative w-100 p-2 border rounded"
                  >
                    {t("This booking is currently exempted from tax!")}
                  </div>
                )}
              </div>
              <div className="infoField">
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={isTaxExempted}
                      onChange={handleChangeTaxExemptStatus}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <div className="labelText">
                      {t("Check to exempt tax and uncheck to undo it!")}
                    </div>
                  }
                  fullWidth
                />
              </div>

              <div className="infoField">
                <TextField
                  id="outlined-name"
                  label={t("Tax exemption ID")}
                  value={taxExemptionId}
                  onChange={(e) => handleTaxExemptionId(e)}
                  fullWidth
                  disabled={!isTaxExempted}
                />
              </div>
              <div
                className="errorMsg mt-2"
                style={{ display: idErrDisplay, color: "red" }}
              >
                {t("Tax-exempt id is required. Please enter any valid value.")}
              </div>

              <div className="infoField">
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-multiple-name-label">
                    {t("Taxes to exempt")}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={taxExemptName}
                    onChange={handleTaxExemptName}
                    input={<OutlinedInput label={t("Taxes to exempt")} />}
                    MenuProps={MenuProps}
                    disabled={isTaxExempted === false && `disabled`}
                  >
                   
                    {Object.keys(summaryData.tax_breakdown).map((tax) => (
                      <MenuItem key={tax} value={tax}>
                        {tax}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl sx={{ width: 410 }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    {" "}
                    {t("Taxes to exempt")}
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={taxExemptName}
                    onChange={(event) => {
                      const selectedValues = event.target.value;

                      // Check if "Select all" is included
                      if (selectedValues.includes("all")) {
                        // If "Select all" is checked, either select all or deselect all
                        if (
                          taxExemptName.length === taxBreakdownEntries.length
                        ) {
                          setTaxExemptName([]); // Deselect all
                        } else {
                          setTaxExemptName(
                            taxBreakdownEntries.map(([taxName]) => taxName)
                          ); // Select all
                        }
                      } else {
                        // Otherwise, set the selected values
                        setTaxExemptName(selectedValues);
                      }
                    }}
                    input={<OutlinedInput label={t("Taxes to exempt")} />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    disabled={!isTaxExempted}
                  >
                    <MenuItem value="all">
                      <Checkbox
                        checked={
                          taxBreakdownEntries.length > 0 &&
                          taxExemptName.length === taxBreakdownEntries.length
                        }
                      />
                      <ListItemText primary="Select all" />
                    </MenuItem>
                    {taxBreakdownEntries.map(([taxName, taxValue]) => (
                      <MenuItem key={taxName} value={taxName}>
                        <Checkbox
                          checked={taxExemptName.indexOf(taxName) > -1}
                        />
                        <ListItemText primary={taxName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div
                className="errorMsg mt-2"
                style={{ display: errDisplay, color: "red" }}
              >
                {t(
                  "Choose one or more taxes to be exempt from the dropdown menu."
                )}
              </div>
            </div>
          </div>
          <div className="addServiceBtns">
            <button className="cancel" onClick={() => handleCloseTaxExempt()}>
              {t("Cancel")}
            </button>
            <Button
              variant="custom-button"
              // className="approved"
              disabled={
                taxExemptionId !== 0 &&
                (!taxExemptionId.trim() || String(taxExemptName).length === 0)
              }
              onClick={() => handleTaxExemption()}
            >
              {t("Confirm")}{" "}
              {taxExemptLoader && <CircularProgress size="15px" />}
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Sending booking confirmation modal */}
      <Modal
        open={openBookingConf}
        onClose={handleCloseBookingConf}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                {invoiceData?.invoice?.is_enquiry === "true" &&
                hotelType !== "ISHA"
                  ? `${t("Send enquiry email")}`
                  : `${t("Send booking confirmation")}`}
              </div>
              <div className="close" onClick={() => handleCloseBookingConf()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Email")}
                  name="email"
                  helperText={t(
                    "By default customer's email has been populated. Override if necessary."
                  )}
                  value={bookingConfMail}
                  onChange={(e) => setBookingConfMail(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseBookingConf()}
              >
                {t("Cancel")}
              </button>
              {bookingConfLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => handleSendBookingConfirmation()}
                  disabled={!bookingConfMail}
                >
                  {/* {invoiceData?.invoice?.is_enquiry === "true"
                    ? "Send email"
                    : "Send link"} */}
                  {t("Send email")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Sending folio modal */}
      <Modal
        open={openMailFolio}
        onClose={handleCloseMailFolio}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">{t("Send folio")}</div>
              <div className="close" onClick={() => handleCloseMailFolio()}>
                X
              </div>
            </div>
            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Email")}
                  name="email"
                  helperText={t(
                    "By default customer's email has been populated. Override if necessary."
                  )}
                  value={mailFolioId}
                  onChange={(e) => setMailFolioId(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseMailFolio()}
              >
                {t("Cancel")}
              </button>
              {sendFolioLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => handleSendEmailFolio()}
                  disabled={!mailFolioId}
                >
                  {t("Send email")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Sending magic link modal */}
      <Modal
        open={openMagicLinkModal}
        onClose={handleCloseMagicLinkModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">{t("Send magic link")}</div>

              <div
                className="close"
                onClick={() => handleCloseMagicLinkModal()}
              >
                X
              </div>

              <p className="text-muted mb-0">
                {t(
                  "Magic Link connects your guests to your property in real time. They can view the itinerary, perform self check-ins, room upgrades, stay extensions, and add-on purchases."
                )}
              </p>
            </div>
            <div className="amountWrapper">
              <div
                className="fieldWrapper"
                style={{
                  position: "relative",
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: 500,
                  backgroundColor: "#C8E5F3",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "black",
                }}
              >
                {envType.toLowerCase() === "prod"
                  ? `https://selfservice.stayflexi.com/booking/${bookingId}`
                  : `https://beta.selfservice.stayflexi.com/booking/${bookingId}`}
              </div>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Email")}
                  name="email"
                  helperText={t(
                    "By default customer's email has been populated. Override if necessary."
                  )}
                  value={magicLinkMail}
                  onChange={(e) => setMagicLinkMail(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Phone number")}
                  name="phone"
                  helperText={t(
                    "By default customer's contact number has been populated. Override if necessary."
                  )}
                  value={magicLinkPhone}
                  onChange={(e) => {
                    /^[\+0-9]*$/.test(e.target.value) &&
                      setMagicLinkPhone(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseMagicLinkModal()}
              >
                {t("Cancel")}
              </button>
              {magicLinkLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => handleSendMagicLink()}
                  disabled={!magicLinkMail || !magicLinkPhone}
                >
                  {t("Send Magic Link")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Send link to collect guest info */}
      <Modal
        open={openCollectGuestInfo}
        onClose={handleCloseCollectGuestInfo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                {t("Send link to collect guest info")}
              </div>

              <div
                className="close"
                onClick={() => handleCloseCollectGuestInfo()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper" style={{ marginTop: "2rem" }}>
              {t("Do you want to send email to collect guest info?")}
              {/* <FormControl
                fullWidth
                style={{ width: "34rem", marginTop: "1rem" }}
              >
                <InputLabel
                  style={{ width: "34rem" }}
                  id="demo-simple-select-label"
                >
                  Select Guest Email
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Guest Email"
                  value={selectedUserEmail}
                  style={{ width: "34rem" }}
                  onChange={handleChangetoCOllectInfo}
                  multiple
                  required="true"
                  placeholder="Select Guest Email"
                >
                  <MenuItem key={"ALL"} value={"ALL"}>
                    {" "}
                    ALL{" "}
                  </MenuItem>
                  {allGuestUsers?.map((item) => (
                    <MenuItem key={item.email} value={item.email}>
                      {item.email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseCollectGuestInfo()}
              >
                {t("Cancel")}
              </button>
              {magicLinkLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => handleSendLinkToCollectGuestInfo()}
                >
                  {t("Send Link")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Copy reservation */}
      <Modal
        open={openCopyReservation}
        onClose={handleCloseCopyReservation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">{t("Copy Reservation")}</div>

              <div
                className="close"
                onClick={() => handleCloseCopyReservation()}
              >
                X
              </div>
            </div>
            <div className="amountWrapper" style={{ marginTop: "2rem" }}>
              {copyBookingId === ""
                ? `${t(" Do you want to copy the reservation?")}`
                : `${t(
                    "Copied Reservation booking id successfully created :- "
                  )} ${copyBookingId}`}
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseCopyReservation()}
              >
                {t("Cancel")}
              </button>
              {magicLinkLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => handleCopyReservationFlow()}
                >
                  {t("Copy Reservation")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Sending Currency modal */}

      {/* <Modal
        open={openCurrencyModal}
        onClose={handleCloseCurrencyModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{
            boxShadow: "0px 0px 25px 10px #0000001a",
            width: 750,
            height: 500,
            padding: 5,
          }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">Convert currency</div>

              <div className="close" onClick={() => handleCloseCurrencyModal()}>
                X
              </div>
            </div>
            <div
              style={{
                overflow: "hidden",
                position: "relative",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                marginLeft: "10px",
              }}
            >
              {Currencies.map((e) => (
                <Card
                  id={e.code}
                  name={e.name}
                  description={e.code}
                  symbol={e.symbol}
                />
              ))}
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseCurrencyModal()}
              >
                Cancel
              </button>
              {/* {magicLinkLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => handleSendMagicLink()}
                >
                  Set currency
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )} */}

      {/* </div>
          </div>
        </Box> */}
      {/* </Modal>  */}

      {/* Edit meal plan modal */}
      <Modal
        open={openMealPlanModal}
        onClose={handleCloseMealPlanModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading"> {t("Edit rate plan")}</div>

              <div className="close" onClick={() => handleCloseMealPlanModal()}>
                X
              </div>
            </div>
            <div className="d-flex mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("Rate plan")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => setselectedRatePlan(e.target.value)}
                  className="mr-4"
                  label={t("Rate plan")}
                  value={selectedRatePlan}
                >
                  {allRatePlans?.map((item, index) => (
                    <MenuItem value={item[0]}>{item[1].ratePlanName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-name"
                label={t("Amount")}
                name="Amount"
                value={UpdateRateplanAMount}
                onChange={(e) => setUpdateRateplanAMount(e.target.value)}
                sx={{ width: "100%" }}
                disabled={
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.EditBookingPrice
                  ).read === "YES"
                    ? false
                    : true
                }
              />
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseMealPlanModal()}
              >
                {t("Cancel")}
              </button>
              {ratePlanLoader === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => saveRatePlanPrice()}
                  disabled={!selectedRatePlan || !UpdateRateplanAMount}
                >
                  {t("Save")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Scanty Baggage modal */}
      <Modal
        open={openScantyModal}
        onClose={handleCloseScantyModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">{t("Scanty Baggage")}</div>

              <div className="close" onClick={() => handleCloseScantyModal()}>
                X
              </div>
            </div>
            <div className="mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("Scanty Baggage")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => setisScantybaggae(e.target.value)}
                  className="mr-4"
                  label={t("Scanty Baggage")}
                  value={isScantybaggae}
                >
                  <MenuItem value={"YES"}>{t("YES")}</MenuItem>
                  <MenuItem value={"NO"}>{t("NO")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => handleCloseScantyModal()}
              >
                {t("Cancel")}
              </button>
              {magicLinkLoading === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => markScantyBaggage()}
                >
                  {t("Save")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* confirming split booking   modal */}
      <Modal
        open={openSplitbookingModal}
        onClose={closeOpenSplitBookingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">{t("Split booking")}</div>
              <div
                className="close"
                onClick={() => closeOpenSplitBookingModal()}
              >
                X
              </div>
            </div>

            <h5 style={{ marginLeft: "1rem", marginTop: "1rem" }}>
              {t("Do you want to split booking ?")}
            </h5>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => closeOpenSplitBookingModal()}
              >
                {t("Cancel")}
              </button>
              {splitBookingloader === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => splitBooking(bookingId)}
                >
                  {t("Yes, Split booking")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openRepushModal}
        onClose={closeOpenRepushModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                {t("Re-push guest information")}
              </div>
              <div className="close" onClick={() => closeOpenRepushModal()}>
                X
              </div>
            </div>

            <h5 style={{ marginLeft: "1rem", marginTop: "1rem" }}>
              {t("Do you want repush guest info to vms ?")}
            </h5>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => closeOpenRepushModal()}
              >
                {t("Cancel")}
              </button>
              {splitBookingloader === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => rePushEreceipt(bookingId)}
                >
                  {t("Yes, repush booking")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openPreviewModal}
        onClose={closeOpenPreviewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">{t("Preivew E-Receipt")}</div>
              <div className="close" onClick={() => closeOpenPreviewModal()}>
                X
              </div>
            </div>

            {previewImageIsha !== "" && (
              <img src={`data:image/png;base64,${previewImageIsha}`} />
            )}
            {previewImageIsha === "" && (
              <h5 style={{ marginLeft: "1rem", marginTop: "1rem" }}>
                {t("Do you want to preivew E-Receipt ?")}
              </h5>
            )}

            {previewImageIsha === "" && (
              <div className="settleDuesSubmitBtns">
                <button
                  className="cancelBtn"
                  onClick={() => closeOpenPreviewModal()}
                >
                  {t("Cancel")}
                </button>
                {splitBookingloader === false ? (
                  <button
                    className="submit w-auto"
                    onClick={() => previewReceipt()}
                  >
                    {t("Preivew E-Receipt")}
                  </button>
                ) : (
                  <button className="submit">
                    <CircularProgress size="15px" />
                  </button>
                )}
              </div>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        open={complimentaryModal}
        onClose={closeComplimentaryModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
        >
          <div className="settleDuesWrapper">
            <div className="settleDuesHeader">
              <div className="settleDuesHeading">
                {t("Add/View complimentary booking")}
              </div>
              <div className="close" onClick={() => closeComplimentaryModal()}>
                X
              </div>
            </div>

            <div className="amountWrapper">
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Requestor Name")}
                  name="requestorName"
                  value={requestName}
                  onChange={(e) => setRequestName(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Requestor Contact")}
                  name="requestorContact"
                  value={requestContact}
                  onChange={(e) => {
                    /^[\+0-9]*$/.test(e.target.value) &&
                      setRequestContact(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Requestor Department")}
                  name="requestorDept"
                  value={requestDept}
                  onChange={(e) => setRequestDept(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="fieldWrapper">
                <TextField
                  id="outlined-name"
                  label={t("Requestor Approver")}
                  name="requestorApprover"
                  value={requestApprover}
                  onChange={(e) => {
                    setRequestApprover(e.target.value);
                  }}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="settleDuesSubmitBtns">
              <button
                className="cancelBtn"
                onClick={() => closeComplimentaryModal()}
              >
                {t("Cancel")}
              </button>
              {compBooking !== null ? (
                <button
                  className="submit w-auto"
                  onClick={() => addUpdateComplimentaryBooking()}
                  disabled="true"
                >
                  {t("Add Complimentary Info")}
                </button>
              ) : splitBookingloader === false ? (
                <button
                  className="submit w-auto"
                  onClick={() => addUpdateComplimentaryBooking()}
                >
                  {t("Add Complimentary Info")}
                </button>
              ) : (
                <button className="submit">
                  <CircularProgress size="15px" />
                </button>
              )}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Confirmation modal for editing the bulk price */}
      <Modal
        open={openBulkEdit}
        onClose={handleCloseBulkEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="updateHeadeWrapper">
            <div className="bulkUpdateHeader">{t("Update bulk price")}</div>
            <div className="close" onClick={() => handleCloseBulkEdit()}>
              X
            </div>
          </div>
          <div className="folioConfirmationWrapper">
            <Stack spacing={3}>
              <div className="folioConfContent" style={{ zIndex: "999" }}>
                <ClickAwayListenerHelper
                  clickAwayButtonName={
                    <>
                      <div className="calIcon">
                        <AiIcons.AiOutlineCalendar size="20px" />
                      </div>
                      <div className="calDate" style={{ marginLeft: "15px" }}>
                        {moment(datePicker[0].startDate).format("DD MMM YYYY") +
                          " - " +
                          moment(datePicker[0].endDate).format("DD MMM YYYY")}
                      </div>
                    </>
                  }
                  // clickAwayButtonName = "Display calendar"
                  btnClassName="displayCalendarBtn"
                  content={
                    <div className="cal">
                      <DateRangePicker
                        editableDateInputs={true}
                        onChange={(item) => setDatePicker([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={datePicker}
                        staticRanges={defaultStaticRanges}
                      />
                    </div>
                  }
                />
              </div>
              <div className="rowField">
                <div className="roomIds">
                  <FormControl sx={{ m: 1, width: "150px" }}>
                    <InputLabel id="demo-multiple-name-label">
                      {t("Room No.(s)")}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={roomId}
                      onChange={handleChangeRoomId}
                      input={<OutlinedInput label={t("Room No.(s)")} />}
                      MenuProps={MenuProps}
                    >
                      {invoiceData?.invoice.room_ids_list.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* <FormControl fullWidth sx={{ width: "150px" }}> */}
                  {/* <InputLabel id="demo-simple-select-label">
                      Room No.(s)
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={roomId}
                      label="Room No.(s)"
                      onChange={handleChangeRoomId}
                    >
                      {invoiceData?.invoice.room_ids_list.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </div>
                <div className="priceType">
                  <FormControl fullWidth sx={{ width: "225px" }}>
                    <InputLabel id="demo-simple-select-label">
                      {t("Price type")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={priceType}
                      label={t("Price type")}
                      onChange={handlePriceType}
                    >
                      <MenuItem value={"PRETAX"}>{t("Pre-tax")}</MenuItem>
                      <MenuItem value={"TOTAL"}>{t("Total with tax")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="priceValue">
                <TextField
                  id="outlined-required"
                  label={t("Update price")}
                  defaultValue=""
                  value={updatedPrice}
                  onChange={handleUpdatedPrice}
                  fullWidth
                />
              </div>
              <div className="updateBtn">
                <button className="closeupdatePrice">{t("Cancel")}</button>

                <Button
                  variant="custom-button"
                  // className="updatePrice"
                  onClick={() => handleBulkUpdatePrice()}
                  disabled={!roomId || !priceType || !updatedPrice}
                >
                  {t("Update price")}
                  {bulkEditLoading && <CircularProgress size="15px" />}
                </Button>
              </div>
            </Stack>
          </div>
        </Box>
      </Modal>

      {/* Modal to provide the type of res card that needs to be printed */}
      <Modal
        open={openResCard}
        onClose={handleCloseResCard}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="updateHeadeWrapper">
            <div className="bulkUpdateHeader">
              {t("Print Registration card")}
            </div>
            <div className="close" onClick={() => handleCloseResCard()}>
              X
            </div>
          </div>
          <div className="folioConfirmationWrapper">
            <div
              className="folioContent"
              style={{
                borderBottom: "1px solid #DDDDDD",
                paddingBottom: "15px",
                marginBottom: "15px",
              }}
            >
              <Stack direction="row" spacing={3}>
                <Checkbox
                  checked={manualResCard}
                  onChange={handleChangeManualResCard}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <div>
                  {t(
                    "I'll fill up the details manually into the Registration card!"
                  )}
                </div>
              </Stack>
            </div>
            <Stack direction="row">
              <div className="updateBtn">
                <button
                  className="closeupdatePrice"
                  onClick={() => handleCloseResCard()}
                >
                  {t("Cancel")}
                </button>
                {manualResCard === true ? (
                  <div className="item" style={{ paddingTop: "10px" }}>
                    <PDFDownloadLink
                      document={
                        <Document title={bookingId}>
                          <Page size="A4" style={styles.page} wrap>
                            {customFolioConfig?.reservation_card_version ===
                            "rescardV2" ? (
                              <PrintEmptyVehicleResCard
                                invoice={invoiceData?.invoice}
                                symbol={currency === "₹" ? "Rs." : currency}
                                hotelData={invoiceData?.hotel}
                                customDetails={customFolioConfig}
                                hotelLogo={hotelLogo}
                                summaryData={summaryData}
                                hotelType={hotelType}
                                selectedLanguage={selectedLanguage}
                              />
                            ) : (
                              <PrintEmptyResCard
                                invoice={invoiceData?.invoice}
                                symbol={currency === "₹" ? "Rs." : currency}
                                hotelData={invoiceData?.hotel}
                                customDetails={customFolioConfig}
                                hotelLogo={hotelLogo}
                                summaryData={summaryData}
                                hotelType={hotelType}
                                selectedLanguage={selectedLanguage}
                              />
                            )}
                          </Page>
                        </Document>
                      }
                      fileName={`${bookingId}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <p style={{ fontSize: "0.8rem" }}>
                            {t("Loading document...")}
                          </p>
                        ) : (
                          <a
                            href={url}
                            style={{ fontSize: "0.8rem" }}
                            rel={"noreferrer"}
                            target="_blank"
                          >
                            {window.screen.width > 416 && (
                              <Button
                                variant="custom-button"
                                onClick={() =>
                                  setTimeout(() => {
                                    handleCloseResCard();
                                  }, 1500)
                                }
                              >
                                {t("Print Registration card")}
                              </Button>
                            )}
                          </a>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                ) : (
                  <div className="item" style={{ paddingTop: "10px" }}>
                    <PDFDownloadLink
                      document={
                        <Document title={bookingId}>
                          <Page
                            size="A4"
                            style={[
                              styles.page,
                              {
                                paddingTop:
                                  customFolioConfig?.reservation_card_version ===
                                    "rescardV2" && 10,
                                paddingBottom:
                                  customFolioConfig?.reservation_card_version ===
                                    "rescardV2" && 10,
                              },
                            ]}
                            wrap
                          >
                            {customFolioConfig?.reservation_card_version ===
                            "rescardV2" ? (
                              <PrintVehicleResCard
                                allCompanyDetails={allCompanyDetails}
                                invoice={invoiceData?.invoice}
                                symbol={currency === "₹" ? "Rs." : currency}
                                hotelData={invoiceData?.hotel}
                                customDetails={customFolioConfig}
                                hotelLogo={hotelLogo}
                                summaryData={summaryData}
                                hotelType={hotelType}
                                ctaDetails={
                                  defaultCtadropdown?.filter(
                                    (cta) =>
                                      cta?.cta_name ===
                                      invoiceData?.invoice?.booking_source
                                  )[0]
                                }
                                selectedLanguage={selectedLanguage}
                              />
                            ) : (
                              <PrintResCard
                                allCompanyDetails={allCompanyDetails}
                                invoice={invoiceData?.invoice}
                                symbol={currency === "₹" ? "Rs." : currency}
                                hotelData={invoiceData?.hotel}
                                customDetails={customFolioConfig}
                                hotelLogo={hotelLogo}
                                summaryData={summaryData}
                                hotelType={hotelType}
                                ctaDetails={
                                  defaultCtadropdown?.filter(
                                    (cta) =>
                                      cta?.cta_name ===
                                      invoiceData?.invoice?.booking_source
                                  )[0]
                                }
                                selectedLanguage={selectedLanguage}
                              />
                            )}
                          </Page>
                        </Document>
                      }
                      fileName={`${bookingId}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <p style={{ fontSize: "0.8rem" }}>
                            {t("Loading document...")}
                          </p>
                        ) : (
                          <a
                            href={url}
                            style={{ fontSize: "0.8rem" }}
                            rel={"noreferrer"}
                            target="_blank"
                          >
                            {window.screen.width > 416 && (
                              <Button
                                variant="custom-button"
                                onClick={() =>
                                  setTimeout(() => {
                                    handleCloseResCard();
                                  }, 1500)
                                }
                              >
                                {t("Print Registration card")}
                              </Button>
                            )}
                          </a>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                )}
              </div>
            </Stack>
          </div>
        </Box>
      </Modal>

      {/* Drawer for Transfer Reservations */}
      <Drawer
        anchor={"right"}
        open={openTransferReservation}
        onClose={() => setOpenTransferReservation(false)}
      >
        <Box>
          <TransferReservation
            bookingId={bookingId}
            setOpenTransferReservation={setOpenTransferReservation}
          />
        </Box>
      </Drawer>

      {/* Modal to ask for separate bill or the combined bill */}
      <Modal
        open={invoiceDownload}
        onClose={handleCloseSetInvoiceDownload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="updateHeadeWrapper">
            <div className="bulkUpdateHeader">{t("Download invoice")}</div>
            <div
              className="close"
              onClick={() => handleCloseSetInvoiceDownload()}
            >
              X
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              position: "relative",
              padding: 4,
              flexDirection: "row",
            }}
          >
            <Checkbox
              checked={checkPosBill}
              onChange={handleCheckPosBill}
              inputProps={{ "aria-label": "controlled" }}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            />
            <p
              style={{
                marginLeft: "10px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {t("Keep POS orders separate from the Invoice")}
            </p>
          </div>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <SecondaryButton
              text="Cancel"
              onClick={() => handleCloseSetInvoiceDownload()}
            />
            {/* <Button variant="custom-button">Confirm download</Button> */}
            <PDFDownloadLink
              document={
                <Document title={bookingId}>
                  <Page size="A4" style={styles.page} wrap>
                    {!checkPosBill ? (
                      <InvoiceDoc
                        hotelTax={hotelTax}
                        invoice={
                          taxExemptAccessControl &&
                          discountTaxExemptInvoiceData?.invoice
                            ? discountTaxExemptInvoiceData?.invoice
                            : invoiceData?.invoice
                        }
                        symbol={currency === "₹" ? "Rs." : currency}
                        hotelLogo={hotelLogo}
                        hotelData={invoiceData?.hotel}
                        summaryData={
                          taxExemptAccessControl && discountTaxExemptSummaryData
                            ? discountTaxExemptSummaryData
                            : summaryData
                        }
                        customDetails={customFolioConfig}
                        customerIdentification={
                          invoiceData?.invoice.identification
                        }
                        selfCheckin={selfCheckins}
                        ctaDetails={
                          defaultCtadropdown?.filter(
                            (cta) =>
                              cta?.cta_name ===
                              invoiceData?.invoice?.booking_source
                          )[0]
                        }
                        utgst={utgst}
                        selectedLanguage={selectedLanguage}
                        isTTDCProperty={checkIfTTDC(data?.emailId)}
                        paymentRefId={paymentRefId}
                        folioLogs={folioLogs}
                        hotelEmail={groupHotelEmail}
                      />
                    ) : (
                      <InvoiceDocWOPos
                        invoice={invoiceData?.invoice}
                        symbol={currency}
                        hotelLogo={hotelLogo}
                        hotelData={invoiceData?.hotel}
                        summaryData={summaryData}
                        customDetails={customFolioConfig}
                        customerIdentification={
                          invoiceData?.invoice.identification
                        }
                        posSubTotal={posSubTotal}
                        posTotal={posTotal}
                        posTotalTax={posTotalTax}
                        selfCheckin={selfCheckins}
                        ctaDetails={
                          defaultCtadropdown?.filter(
                            (cta) =>
                              cta?.cta_name ===
                              invoiceData?.invoice?.booking_source
                          )[0]
                        }
                        selectedLanguage={selectedLanguage}
                      />
                    )}
                  </Page>
                </Document>
              }
              fileName={`${bookingId}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <p style={{ fontSize: "0.8rem" }}>
                    {t("Loading document...")}
                  </p>
                ) : (
                  <a
                    href={url}
                    rel={"noreferrer"}
                    target="_blank"
                    // download={`Folio_${bookingId}`}
                  >
                    {window.screen.width > 416 && (
                      <Tooltip title="Download invoice">
                        <IconButton>
                          <AiIcons.AiFillPrinter size="27.5px" color="black" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </a>
                )
              }
            </PDFDownloadLink>
          </div>
        </Box>
      </Modal>

      {/* Modal to ask the user whether to bill to company or to the user */}
      <Modal
        open={billPreferenceModal}
        onClose={handleCloseBillPreferenceModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="updateHeadeWrapper">
            <div className="bulkUpdateHeader">
              {t("Choose Bill Preference")}
            </div>
            <div
              className="close"
              onClick={() => handleCloseBillPreferenceModal()}
            >
              X
            </div>
          </div>
          <FormControl sx={{ marginTop: "10px" }}>
            <FormLabel id="demo-controlled-radio-buttons-group">
              {t("Choose the entity you want to bill")}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={billToPref}
              onChange={handleChangeBillToPref}
            >
              <Stack direction={"row"} spacing={2}>
                <FormControlLabel
                  value="GUEST"
                  control={<Radio />}
                  label={t("Bill to guest")}
                />
                <FormControlLabel
                  value="COMPANY"
                  control={<Radio />}
                  label={t("Bill to company")}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
          {billToPref === "COMPANY" &&
            invoiceData?.invoice?.company_gst_number?.trim()?.length === 0 && (
              <Typography sx={{ fontSize: "10px", color: "red" }}>
                {t(
                  "Please enter company details in order to bill to the company"
                )}
              </Typography>
            )}
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <SecondaryButton
              text={t("Cancel")}
              onClick={() => handleCloseBillPreferenceModal()}
            />
            {billToPref === "COMPANY" ? (
              invoiceData?.invoice?.company_gst_number?.trim()?.length === 0 ? (
                " "
              ) : custCompanyDetails === null ? (
                <Typography sx={{ color: "grey", size: "12.5px" }}>
                  {t("Fetching company details...")}
                </Typography>
              ) : (
                renderFolioPdfComponents()
              )
            ) : (
              renderFolioPdfComponents()
            )}
          </div>
        </Box>
      </Modal>

      {/* Modal to ask the user to delete the folio*/}
      <Modal
        open={openDeleteFolio}
        onClose={() => setOpenDeleteFolio(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="updateHeadeWrapper">
            <div className="bulkUpdateHeader">Delete folio</div>
            <div className="close" onClick={() => setOpenDeleteFolio(false)}>
              X
            </div>
          </div>

          <Stack sx={{ mt: 3 }}>
            <Typography gutterBottom>
              Are you sure you want to delete folio of booking id {bookingId}?
            </Typography>
            <Typography gutterBottom>
              Note: Once Folio is deleted, it cannot be rolled back or
              retrieved.
            </Typography>
            <Stack
              direction={"row"}
              sx={{ marginTop: "40px", justifyContent: "space-between" }}
            >
              <Button onClick={() => setOpenDeleteFolio(false)}>Cancel</Button>
              <Button
                variant="custom-button"
                disabled={deleteFolioLoader}
                onClick={deleteFolio}
              >
                {deleteFolioLoader ? (
                  <CircularProgress size={"15px"} />
                ) : (
                  "Confirm"
                )}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    hotelType: state.auth.hotelType,
    groupHotelEmail: state.auth.email,
    folioConfigs: state.auth.folioConfig,
  };
}
export default connect(mapStateToProps)(InvoiceWrapper);
