import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Select,
  MenuItem,
  IconButton,
  CardHeader,
  Avatar,
  CardActions,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Email, Phone } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect } from "react";
import { getHotel } from "../../../../modules/Auth/_redux/authCrud";
import { useState } from "react";
import { handleSpringDataRequest } from "../../../../api";
import { getRoomBookings } from "../../../CalendarPage/api/ReservationApi";
import moment from "moment";
import LeftSectionLoader from "./LeftSectionLoader";
import RightContentSectionLoader from "./RightContentSectionLoader";
import RightSectionLoader from "./RightSectionLoader";
import { BASE_SPRING_ENDPOINT } from "../../../../api/config";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#36454F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TransferReservation = ({
  bookingId,
  setOpenTransferReservation,
  data,
  hotelType,
  token,
}) => {
  console.log("data", data, hotelType);
  const invoiceDataRenderer = [
    { key: "bookingid", name: "Booking id" },
    { key: "booking_status_displayname", name: "Status" },
    { key: "checkin", name: "Arrival date" },
    { key: "checkout", name: "Departure date" },
    { key: "roomtype_names", name: "Room type name(s)" },
    { key: "roomids", name: "Room id(s)" },
    { key: "rate_plan_names", name: "Rate plan name(s)" },
    { key: "children", name: "Children" },
    { key: "adults", name: "Adults" },
  ];
  const [propertyList, setPropertyList] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [selectedHotel, setSelectedHotel] = useState({});
  const [availableRooms, setAvailableRooms] = useState({});
  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState("");
  const [selectedRatePlanId, setSelectedRatePlanId] = useState("");
  const [selectedAdults, setSelectedAdults] = useState(1);
  const [selectedChild, setSelectedChild] = useState(0);
  const [physicalRoomId, setPhysicalRoomId] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [loaderRight, setLoaderRight] = useState(false);
  const [loaderLeft, setLoaderLeft] = useState(true);
  const [totalDues, setTotalDues] = useState(0);

  useEffect(() => {
    getHotel(
      localStorage.getItem("groupEmail"),
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        setPropertyList(res?.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setLoaderLeft(true);
    handleSpringDataRequest(
      `core/api/v1/folio/invoice-data-render/?bookingId=${bookingId}&fromDB=true&exemptDiscountTax=true`
    )
      .then((res) => {
        setInvoiceData(res);
        setLoaderLeft(false);
      })

      .catch((err) => {
        console.log("Error in fetching discount tax exempted invoice");
        setLoaderLeft(false);
      });

    handleSpringDataRequest(
      `core/api/v1/folio/invoice-summary-data/?bookingId=${bookingId}&fromDB=true&exemptDiscountTax=true`
    )
      .then((res) => setSummaryData(res))

      .catch((err) =>
        console.log("Error in fetching discount tax exempted summary")
      );
  }, [bookingId]);

  useEffect(() => {
    const payload = {
      hotelId: selectedHotel?.hotelId,
      checkin: moment(invoiceData?.invoice?.checkin).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
      checkout: moment(invoiceData?.invoice?.checkout).format(
        "DD-MM-YYYY HH:mm:ss"
      ),
      isAdmin: true,
      ctaId: null,
    };
    if (selectedHotel?.hotelId) {
      setLoaderRight(true);
      getRoomBookings(payload)
        .then((resp) => {
          console.log("resp", resp);
          setAvailableRooms(resp);
          setSelectedRoomTypeId(Object.keys(resp?.roomTypeMap)?.[0]);
          setSelectedRatePlanId(
            resp?.roomTypeMap?.[Object.keys(resp?.roomTypeMap)?.[0]]
              ?.combos?.[0]?.rates?.[0]?.ratePlanId
          );
          setLoaderRight(false);
          setCartItems([]);
        })
        .catch(() => {
          setLoaderRight(false);
          setCartItems([]);
        });
    }
  }, [selectedHotel]);

  useEffect(() => {
    if (selectedRoomTypeId && availableRooms?.roomTypeMap) {
      setSelectedRatePlanId(
        availableRooms?.roomTypeMap?.[selectedRoomTypeId]?.combos?.[0]
          ?.rates?.[0]?.ratePlanId
      );
    }
  }, [selectedRoomTypeId]);

  const ratePlanData = availableRooms?.roomTypeMap?.[
    selectedRoomTypeId
  ]?.combos?.[0]?.rates?.filter(
    (item) => item?.ratePlanId === selectedRatePlanId
  )?.[0];

  const roomData = {
    numAdults: selectedAdults,
    numChildren: selectedChild,
    roomTypeId: selectedRoomTypeId,
    ratePlanId: selectedRatePlanId,
    roomTypeName:
      availableRooms?.roomTypeMap?.[selectedRoomTypeId]?.roomTypeName,
    ratePlanName: ratePlanData?.ratePlanName,
    roomCharges:
      ratePlanData?.priceMap?.[
        `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
      ],
    roomTaxes:
      ratePlanData?.taxes?.[
        `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
      ],
    roomChargesAfterDiscount: ratePlanData?.discountMap
      ? ratePlanData?.priceMap?.[
          `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
        ] -
        ratePlanData?.discountMap?.[
          `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
        ]
      : ratePlanData?.priceMap?.[
          `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
        ],
    roomTaxesAfterDiscount: ratePlanData?.taxAfterDiscountMap
      ? ratePlanData?.taxAfterDiscountMap?.[
          `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
        ]
      : ratePlanData?.taxes?.[
          `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
        ],
    promotionThere: !!ratePlanData?.discountMap,
    promotionalDiscount:
      ratePlanData?.discountMap?.[
        `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
      ] || 0,
    promotionalTaxDiscount: ratePlanData?.taxAfterDiscountMap
      ? ratePlanData?.taxes?.[
          `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
        ] -
        ratePlanData?.taxAfterDiscountMap?.[
          `${selectedAdults}${selectedChild ? "+" + selectedChild : ""}`
        ]
      : 0,
    payAtProperty: ratePlanData?.payAtProperty,
    dayLevelPriceList: ratePlanData?.dayLevelPriceList,
    discountMap: ratePlanData?.discountMap || {},
    bookingFeeArray:
      availableRooms?.roomTypeMap?.[selectedRoomTypeId]?.bookingFeeDetails ||
      [],
    roomTaxesMap:
      availableRooms?.roomTypeMap?.[selectedRoomTypeId]?.roomTaxesMap || {},
    bookingFee: availableRooms?.roomTypeMap?.[selectedRoomTypeId]?.bookingFee,
    bookingFeeName:
      availableRooms?.roomTypeMap?.[selectedRoomTypeId]?.bookingFeeName,
    physicalRoomId: physicalRoomId,
    uuid:
      `${selectedRoomTypeId}` +
      `${selectedRatePlanId}` +
      Math.round(Math.random() * 10000),
  };

  const handleAddItem = () => {
    setCartItems((prev) => [...prev, roomData]);
  };

  const handleRemoveItem = (uid) => {
    const arr = cartItems?.filter((elm) => elm?.uuid !== uid);
    setCartItems(arr);
  };
  useEffect(() => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item?.roomChargesAfterDiscount;
    });
    setTotalDues(total);
  }, [cartItems]);

  const handleperformMoveBookingFromProperty = async () => {
    const physicalRoomIdsMap = cartItems?.reduce((acc, cur) => {
      acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] =
        acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] || [];
      acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`].push(cur?.physicalRoomId);
      return acc;
    }, {});

    const rooms_requested = cartItems?.reduce((acc, cur) => {
      acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] =
        acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] || 0;
      acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] =
        acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] + 1;
      return acc;
    }, {});

    const all_reservation_room_info = cartItems?.reduce((acc, cur) => {
      acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] =
        acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`] || [];
      acc[`${cur?.roomTypeId}-${cur?.ratePlanId}`].push({
        adults: cur?.numAdults,
        children: cur?.numChildren,
        ratePlanName: cur?.ratePlanName,
      });
      return acc;
    }, {});
    const roomData = [];
    cartItems.forEach((item) => {
      const obj = {
        roomTypeName: item?.roomTypeName,
        roomTypeId: item?.roomTypeId,
        ratePlanName: item?.ratePlanName,
        ratePlanId: item?.ratePlanId,
        adults: item?.numAdults,
        children: item?.numChildren,
        primaryGuestData: {
          customerName: "",
          customerEmail: "",
          customerPhone: "",
          customerGender: "-",
          customerDOB: "",
          custGovtIDType: "Passport",
          custGovtIDValue: "",
          custAddress1: "",
          custAddress2: "",
          custZipcode: "",
          custCountry: "",
          custState: "",
          custCity: "",
          custNationality: "",
          custOccupation: "",
        },
        otherGuestData: [],
        childGuestData: [],
      };
      roomData.push(obj);
    });

    // setLoading(true);
    // setError(null);
    const payload = {
      userBookingInfo: {
        id: "",
        userEmail: invoiceData?.invoice?.customer_email,
        checkin: moment(invoiceData?.invoice?.checkin).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        checkout: moment(invoiceData?.invoice?.checkout).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        actualCheckin: moment(invoiceData?.invoice?.actual_checkin).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        actualCheckout: moment(invoiceData?.invoice?.actual_checkout).format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        bookingAmount: invoiceData?.invoice?.booking_amount,
        bookingDiscount: invoiceData?.invoice?.booking_discount_percent,
        bookingSavings: invoiceData?.invoice?.booking_savings,
        hotelId: selectedHotel?.hotelId,
        ratePlanId: invoiceData?.invoice?.rate_plan_ids,
        bookingSource: invoiceData?.invoice?.booking_source,
        refund: invoiceData?.invoice?.refund,
        taxPercentage: invoiceData?.invoice?.tax_percentage,
        externalPayment: invoiceData?.invoice?.external_payment || 0,
        externalPaymentCard: invoiceData?.invoice?.external_payment_card || 0,
        username: invoiceData?.invoice?.customer_name,
        userPhone: invoiceData?.invoice?.customer_phone,
        specialRequests: invoiceData?.invoice?.special_requests,
        appliedPromocode: invoiceData?.invoice?.applied_promocode,
        promoAmount: invoiceData?.invoice?.promo_amount,
        segment: invoiceData?.invoice?.segment,
        bookingReferenceId: invoiceData?.invoice?.booking_reference_id,
        companyGstNumber: invoiceData?.invoice?.company_gst_number,
        taxExemptId: invoiceData?.invoice?.tax_exempt_id,
        sendEmail: invoiceData?.invoice?.send_email,
      },
      admin_reservation_info: cartItems.every((item) => item?.physicalRoomId)
        ? {
            newReservationInfo: {
              booking_id: "",
              hotel_id: selectedHotel?.hotelId,
              timestamps: null,
              rooms_requested: rooms_requested,
              checkin: moment(invoiceData?.invoice?.checkin).format(
                "DD-MM-YYYY HH:mm:ss"
              ),
              checkout: moment(invoiceData?.invoice?.checkout).format(
                "DD-MM-YYYY HH:mm:ss"
              ),
              type: "Guest",
              all_reservation_room_info: all_reservation_room_info,
              message_source: invoiceData?.invoice?.booking_source,
              message_type: null,
            },
            physicalRoomIdsMap: physicalRoomIdsMap,
          }
        : undefined,
      newReservationInfo: cartItems.some((item) => !item?.physicalRoomId)
        ? {
            booking_id: "",
            hotel_id: selectedHotel?.hotelId,
            timestamps: null,
            rooms_requested: rooms_requested,
            checkin: moment(invoiceData?.invoice?.checkin).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            checkout: moment(invoiceData?.invoice?.checkout).format(
              "DD-MM-YYYY HH:mm:ss"
            ),
            type: "Guest",
            all_reservation_room_info: all_reservation_room_info,
            message_source: invoiceData?.invoice?.booking_source,
            message_type: null,
          }
        : undefined,
      customer: {
        userName: invoiceData?.invoice?.customer_name,
        phoneNumber: invoiceData?.invoice?.customer_phone,
        email: invoiceData?.invoice?.customer_email,
        zipCode: invoiceData?.invoice?.customer_address?.customer_address_zip,
        address: invoiceData?.invoice?.customer_address_full_str,
        city: invoiceData?.invoice?.customer_address?.customer_address_city,
        state: invoiceData?.invoice?.customer_address?.customer_address_state,
        country:
          invoiceData?.invoice?.customer_address?.customer_address_country,
        address1:
          invoiceData?.invoice?.customer_address?.customer_address_line1,
        govtIdType: invoiceData?.invoice?.govt_id_type,
        govtIdValue: invoiceData?.invoice?.govt_id_value,
        dateOfBirth: invoiceData?.invoice?.customer_dob,
        gender: invoiceData?.invoice?.customer_gender,
        nationality: invoiceData?.invoice?.customer_nationality,
        occupation: invoiceData?.invoice?.customer_occupation,
      },
      miscDetails: {
        isComplimentary: invoiceData?.invoice?.complimentary_booking,
        compRequestName: "",
        compRequestContact: "",
        compRequestDepartment: "",
        compRequestApprover: "",
        compRemarks: "",
      },
      userDetails: {
        userName: invoiceData?.invoice?.customer_name,
        phoneNumber: invoiceData?.invoice?.customer_phone,
        email: invoiceData?.invoice?.customer_email,
        zipCode: invoiceData?.invoice?.customer_address?.customer_address_zip,
        address: invoiceData?.invoice?.customer_address_full_str,
        city: invoiceData?.invoice?.customer_address?.customer_address_city,
        state: invoiceData?.invoice?.customer_address?.customer_address_state,
        country:
          invoiceData?.invoice?.customer_address?.customer_address_country,
        address1:
          invoiceData?.invoice?.customer_address?.customer_address_line1,
      },
      govt_id_type: invoiceData?.invoice?.govt_id_type,
      govt_id_value: invoiceData?.invoice?.govt_id_value,
      is_external: invoiceData?.invoice?.is_external,
      is_enquiry: invoiceData?.invoice?.is_enquiry,
      // expiry_time: invoiceData?.invoice?.enquiry_expiration_time,
      expiry_time: "",
      duration_type: "BEFORE",
      expiry_type: "HOURS",
      exempt_tax_names: [],
      taxId: "",
      addons: [],
      otherGuestDetails: [],
      roomData: roomData,
      prpList: null,
      oldHotelId: data?.hotelId,
      oldBookingId: bookingId,
    };

    try {
      const response = await fetch(
        `${BASE_SPRING_ENDPOINT}core/api/v1/reservation/performMoveBookingFromProperty/?hotel_id=${data?.hotelId}&hotelId=${data?.hotelId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const resp = await response.json();
      console.log(resp);
      toast.success("Transfer reservation successfully");
      // setResponseData(data);
    } catch (err) {
      console.log(err);
      toast.error("Somethin went wrong");
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Box sx={{ width: 1200, paddingX: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
        <IconButton onClick={() => setOpenTransferReservation(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
        {/* Left Panel */}
        {loaderLeft ? (
          <LeftSectionLoader />
        ) : (
          <Box sx={{ width: "35%" }}>
            <Card
              sx={{
                flex: 1,
                height: "94vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    {invoiceData?.invoice?.customer_name?.[0]?.toUpperCase()}
                  </Avatar>
                }
                title={
                  <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                    {invoiceData?.invoice?.customer_name}
                  </Typography>
                }
                subheader={
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      color="text.secondary"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        fontSize: 12,
                      }}
                    >
                      <Phone /> {invoiceData?.invoice?.customer_phone}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        fontSize: 12,
                      }}
                    >
                      <Email /> {invoiceData?.invoice?.customer_email}
                    </Typography>
                  </Box>
                }
              />

              <CardContent sx={{ overflowY: "auto", flex: 1 }}>
                <Grid container spacing={2}>
                  {invoiceDataRenderer?.map((item) => (
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 15 }} color="text.secondary">
                        {item?.name}
                      </Typography>
                      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                        {invoiceData?.invoice?.[item?.key]}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
              <CardActions sx={{ marginTop: "auto" }}>
                <Box
                  sx={{
                    borderTop: "1px solid #ccc",
                    width: "100%",
                    paddingTop: 1,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 15 }} color="text.secondary">
                        Total
                      </Typography>
                      <Typography sx={{ fontSize: 15 }} color="text.secondary">
                        Total paid
                      </Typography>
                      <Typography sx={{ fontSize: 15 }} color="text.secondary">
                        Total due
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                        {localStorage?.getItem("hotelCurrency")}{" "}
                        {summaryData?.invoice_total_amount}
                      </Typography>
                      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                        {localStorage?.getItem("hotelCurrency")}{" "}
                        {summaryData?.payment_made}
                      </Typography>
                      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                        {localStorage?.getItem("hotelCurrency")}{" "}
                        {summaryData?.balance_due}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </CardActions>
            </Card>
          </Box>
        )}

        {/* Right Panel */}
        {loaderLeft ? (
          <RightSectionLoader />
        ) : (
          <Box sx={{ width: "65%" }}>
            <Card
              sx={{
                flex: 1,
                height: "94vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardHeader
                title={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 4,
                    }}
                  >
                    <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                      Transfer Reservation
                    </Typography>
                    <FormControl sx={{ maxWidth: 500 }} fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select property
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select property"
                        size="small"
                        value={selectedHotel}
                        onChange={(e) => setSelectedHotel(e?.target?.value)}
                      >
                        {propertyList
                          ?.filter(
                            (item) =>
                              item?.hotelId !== data?.hotelId &&
                              item?.status === "ACTIVE"
                          )
                          .map((hotel) => (
                            <MenuItem value={hotel}>
                              {hotel?.hotelName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                }
              />
              {loaderRight ? (
                <RightContentSectionLoader />
              ) : (
                <CardContent sx={{ overflowY: "auto", flex: 1 }}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                      <FormControl sx={{ maxWidth: 500 }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select room type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select room type"
                          size="small"
                          value={selectedRoomTypeId}
                          onChange={(e) =>
                            setSelectedRoomTypeId(e?.target?.value)
                          }
                        >
                          {availableRooms?.roomTypeMap &&
                            Object.keys(availableRooms?.roomTypeMap)?.map(
                              (rooms) => (
                                <MenuItem value={rooms}>
                                  {
                                    availableRooms?.roomTypeMap?.[rooms]
                                      ?.roomTypeName
                                  }
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl sx={{ maxWidth: 500 }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select rate plan type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Select rate plan type"
                          size="small"
                          value={selectedRatePlanId}
                          onChange={(e) =>
                            setSelectedRatePlanId(e?.target?.value)
                          }
                        >
                          {availableRooms?.roomTypeMap &&
                            selectedRoomTypeId &&
                            availableRooms?.roomTypeMap?.[
                              selectedRoomTypeId
                            ]?.combos?.[0]?.rates?.map((rate) => (
                              <MenuItem value={rate?.ratePlanId}>
                                {rate?.ratePlanName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl sx={{ maxWidth: 500 }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Adult
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Adult"
                          size="small"
                          value={selectedAdults}
                          onChange={(e) => setSelectedAdults(e?.target?.value)}
                        >
                          {Array.from({
                            length:
                              availableRooms?.roomTypeMap?.[selectedRoomTypeId]
                                ?.maxOccupancy,
                          }).map((_blank, index) => (
                            <MenuItem value={index + 1}>{index + 1}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl sx={{ maxWidth: 500 }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Child
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Child"
                          size="small"
                          value={selectedChild}
                          onChange={(e) => setSelectedChild(e?.target?.value)}
                        >
                          {Array.from({
                            length:
                              availableRooms?.roomTypeMap?.[selectedRoomTypeId]
                                ?.maxChildren,
                          }).map((_blank, index) => (
                            <MenuItem value={index}>{index}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl sx={{ maxWidth: 500 }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Room id
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Room id"
                          size="small"
                          value={physicalRoomId}
                          onChange={(e) => setPhysicalRoomId(e?.target?.value)}
                        >
                          {availableRooms?.roomTypeMap?.[selectedRoomTypeId]
                            ?.combos?.[0]?.onlineRoomIdsWithStatus &&
                            Object.keys(
                              availableRooms.roomTypeMap[selectedRoomTypeId]
                                .combos[0].onlineRoomIdsWithStatus
                            )
                              ?.filter(
                                (item) =>
                                  cartItems?.filter(
                                    (elm) => elm?.physicalRoomId === item
                                  )?.length <= 0
                              )
                              .map((room) => (
                                <MenuItem key={room} value={room}>
                                  {room}({" "}
                                  {
                                    availableRooms?.roomTypeMap?.[
                                      selectedRoomTypeId
                                    ]?.combos?.[0]?.onlineRoomIdsWithStatus?.[
                                      room
                                    ]
                                  }
                                  )
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Price per night"
                    variant="outlined"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Total before tax"
                    variant="outlined"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Total tax"
                    variant="outlined"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Total with tax"
                    variant="outlined"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </Grid> */}
                    <Grid item xs={4}>
                      <Button
                        variant="custom-button"
                        fullWidth
                        onClick={handleAddItem}
                        disabled={
                          invoiceData?.invoice?.adults
                            ?.split(",")
                            ?.reduce((acc, cur) => acc + +cur, 0) <
                            cartItems?.reduce(
                              (acc, cur) => acc + +cur?.numAdults,
                              selectedAdults
                            ) ||
                          invoiceData?.invoice?.children
                            ?.split(",")
                            ?.reduce((acc, cur) => acc + +cur, 0) <
                            cartItems?.reduce(
                              (acc, cur) => acc + +cur?.numChildren,
                              selectedChild
                            )
                        }
                      >
                        Add room{" "}
                      </Button>
                    </Grid>
                  </Grid>
                  {cartItems?.length > 0 && (
                    <Box sx={{ mt: 4 }}>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Room type</StyledTableCell>
                              <StyledTableCell align="right">
                                Rate plan
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Adult Child
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Room id
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Price
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Remove
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cartItems.map((row) => (
                              <StyledTableRow key={row?.uuid}>
                                <StyledTableCell component="th" scope="row">
                                  {row?.roomTypeName}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row?.ratePlanName}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row?.numAdults}-{row?.numChildren}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row?.physicalRoomId}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {row?.roomChargesAfterDiscount}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <IconButton>
                                    <RemoveCircleOutlineIcon
                                      onClick={() =>
                                        handleRemoveItem(row?.uuid)
                                      }
                                    />
                                  </IconButton>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </CardContent>
              )}

              {/* Bottom Actions */}
              <CardActions sx={{ marginTop: "auto" }}>
                <Box
                  sx={{
                    borderTop: "1px solid #ccc",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                    paddingTop: 1,
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontSize: 15 }}
                          color="text.secondary"
                        >
                          Total
                        </Typography>
                        <Typography
                          sx={{ fontSize: 15 }}
                          color="text.secondary"
                        >
                          Total paid
                        </Typography>
                        <Typography
                          sx={{ fontSize: 15 }}
                          color="text.secondary"
                        >
                          Total due
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                          {localStorage?.getItem("hotelCurrency")} {totalDues}
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                          {localStorage?.getItem("hotelCurrency")} 0
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                          {localStorage?.getItem("hotelCurrency")} {totalDues}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: 4,
                      width: "50%",
                      height: "100%",
                    }}
                  >
                    <Button variant="contained" color="error">
                      Cancel
                    </Button>
                    <Button
                      variant="custom-button"
                      onClick={handleperformMoveBookingFromProperty}
                      disabled={
                        invoiceData?.invoice?.adults
                          ?.split(",")
                          ?.reduce((acc, cur) => acc + +cur, 0) !==
                          cartItems?.reduce(
                            (acc, cur) => acc + +cur?.numAdults,
                            0
                          ) ||
                        invoiceData?.invoice?.children
                          ?.split(",")
                          ?.reduce((acc, cur) => acc + +cur, 0) !==
                          cartItems?.reduce(
                            (acc, cur) => acc + +cur?.numChildren,
                            0
                          )
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </CardActions>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    hotelType: state.auth.hotelType,
    token: state.auth.token,
  };
}
export default connect(mapStateToProps)(TransferReservation);
