import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import MockLoaderTable from "../../Folios/ViewManagePayments/MockLoaderTable";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { toast } from "material-react-toastify";
import { handleSpringDataRequest } from "../../../api";

function OtaPrepaidPaymentLogs({
  otaData,
  selectedLanguage,
  bookingId,
  updateSummaryData,
  updateInvoice,
  hotelId,
  loggedUserEmail,
  upDateViewManage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [serviceId, setServiceId] = useState(0);

  const [openDeletePayment, setOpenDeletePayment] = useState(false);
  const [deletePaymentLoader, setDeletePaymentLoader] = useState(false);
  const products = otaData;

  const columns = [
    {
      dataField: "log_timestamp",
      text: `${t("Log Time")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_date",
      text: `${t("Service Date")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "user_email",
      text: `${t("Action By")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "service_description",
      text: `${t("Description")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "amount",
      text: `${t("Amount")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "refund_amount",
      text: `${t("Refund Amount")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
      hidden: true,
    },
    {
      dataField: "service_amount_type",
      text: `${t("Service Amount type")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
    {
      dataField: "approved",
      text: `${t("Approved")}`,
      sort: true,
      headerStyle: {
        width: "15%",
      },
      style: {
        wordBreak: "break-all",
        width: "15%",
      },
      editable: false,
      formatter: (cell) => {
        if (cell.toLowerCase() === "yes") {
          return "APPROVED";
        } else if (cell.toLowerCase() === "no") {
          return "REJECTED";
        } else {
          return cell;
        }
      },
      hidden: true,
    },

    {
      dataField: "service_id",
      text: "Service ID",
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
      hidden: true,
    },
    {
      isDummyField: true,
      text: " ",
      sort: false,
      hidden: false,
      headerStyle: {
        fontSize: "15px",
        width: "14%",
      },
      style: {
        fontSize: "15px",
        wordBreak: "break-all",
        width: "14%",
      },
      formatter: (cell, row) => {
        return (
          <Button
            variant="custom-button"
            onClick={() => {
              setOpenDeletePayment(true);
              setServiceId(row?.service_id);
            }}
          >
            {t("Delete  payment")}
          </Button>
        );
      },
    },
  ];

  // Delete payment
  const deletePayment = () => {
    setDeletePaymentLoader(true);
    handleSpringDataRequest(
      `core/api/v1/reservation/booking/delete-payment-record?bookingId=${bookingId}&serviceId=${serviceId}`
    )
      .then((msg) => {
        setDeletePaymentLoader(false);
        upDateViewManage();
        toast.success(msg);
        setOpenDeletePayment(false);
      })
      .catch((err) => {
        toast.error(err);
        setDeletePaymentLoader(false);
        setOpenDeletePayment(false);
      });
  };

  return (
    <div>
      {" "}
      {products ? (
        <div className="invoiceSummTableWrapper">
          <ToolkitProvider
            keyField="service_id"
            data={products}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <div className="folioTableWrapper">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
          {/* Delete payment modal */}
          <Modal
            open={openDeletePayment}
            onClose={() => setOpenDeletePayment(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              className="settleDuesModal"
              style={{
                boxShadow: "0px 0px 25px 10px #0000001a",
                maxWidth: "450px",
                overflowX: "hidden",
                overflowY: "visible",
              }}
            >
              <Stack
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
                direction={"row"}
              >
                <Typography style={{ fontWeight: 500 }}>
                  {t("Delete OTA prepaid payment")}
                </Typography>
                <Typography
                  style={{ cursor: "pointer", fontWeight: 500 }}
                  onClick={() => setOpenDeletePayment(false)}
                >
                  X
                </Typography>
              </Stack>
              <Divider />
              <Stack
                direction={"row"}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                <Typography>
                  Are you sure you want to delete the OTA prepaid payment for
                  service id {serviceId}?
                </Typography>
              </Stack>
              <Divider />
              <Stack
                direction={"row"}
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "15px",
                }}
              >
                <Typography onClick={() => setOpenDeletePayment(false)}>
                  {t("Cancel")}
                </Typography>
                <Button
                  variant="custom-button"
                  onClick={deletePayment}
                  disabled={deletePaymentLoader}
                >
                  {deletePaymentLoader ? (
                    <CircularProgress size="15px" />
                  ) : (
                    `${t("Confirm")}`
                  )}
                </Button>
              </Stack>
            </Box>
          </Modal>
        </div>
      ) : (
        <MockLoaderTable />
      )}
    </div>
  );
}

export default OtaPrepaidPaymentLogs;
